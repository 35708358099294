import React from 'react'
import '../Disc.css'
import {createTheme, Grid} from "@mui/material";
import {discs} from "../items";
import {DropdownIcon, NotSureResetBtn} from "../utils";
import {FacetModule} from "./FacetModule";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

const NB_SUB_VALUES = 7

class FacetArthropathy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursor: null,
            fontSize: 13
        }
    }

    handleDropdown = (curr_val, disc) => {
        if (this.state.cursor === disc) this.setState({cursor: null})
        else {
            const left_right = curr_val.replace('?', '').split(';')
            if (left_right.some(elt => elt !== '0' && elt !== '100')) this.setState({cursor: disc})
        }
    }

    /**
     *
     * @param curr_val ∈ {0,1,2,3}
     * @param new_val ∈ {0,1,2,3}
     * @returns {string|*}
     */
    getNewValue = (curr_val, new_val) => {
        if (new_val === "0") return "0"
        else if (new_val === "1") return "1"
        else if (new_val === "2") return "2"
        else {
            const sub_val = Array(NB_SUB_VALUES).fill(',0').join('')
            if (curr_val === "WARN" || curr_val === "100" || curr_val === "0" || curr_val === "1" || curr_val === "2") return new_val+sub_val
            else return new_val + curr_val.slice(1)
        }
    }

    handleValuesChange = (disc, position, value) => {
        if (value === "3") this.setState({cursor: disc})
        let val = this.props.values[disc]
        const not_sure = val.endsWith('?') ? '?' : ''
        val = val === 'WARN' ? ['100', '100'] : val.replace('?', '').split(';')
        val[position] = val.length >= 2 ? this.getNewValue(val[position], value) : this.getNewValue('100', value)
        this.props.handleCheck(disc, val.join(';') + not_sure)
    }

    handleSubValuesChange = (disc, position, sub_value) => {
        let val = this.props.values[disc]
        const not_sure = val.endsWith('?') ? '?' : ''
        val = val.replace('?', '').split(';')
        const sub_vals = val[position].split(',')
        const sub_val = sub_vals[sub_value+1]
        if (!![4,5,6,7].find(elt => elt === sub_value+1)) { //need details?
            if (sub_val.split('-').length === 1) sub_vals[sub_value+1] = "0-0-0-x"
            else sub_vals[sub_value+1] = '0'
        } else {
            if (sub_val[0] === '0') sub_vals[sub_value+1] = '1'
            else sub_vals[sub_value+1] = '0'
        }
        val[position] = sub_vals.join(',')
        this.props.handleCheck(disc, val.join(';') + not_sure)
    }

    handleSubSubValuesChange = (disc, main_position, position, sub_position, new_val) => {
        let val = this.props.values[disc]
        const not_sure = val.endsWith('?') ? '?' : ''
        val = val.replace('?', '').split(';')
        const sub_vals = val[main_position].split(',')
        const sub_sub_vals = sub_vals[position+1].split('-')
        sub_sub_vals[sub_position] = new_val
        sub_vals[position+1] = sub_sub_vals.join('-')
        val[main_position] = sub_vals.join(',')
        this.props.handleCheck(disc, val.join(';') + not_sure)
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    render() {
        const theme = createTheme({typography: {fontSize: this.state.fontSize}})
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) {
                const left_right = curr_val.replace('?', '').split(';')
                const is_sub_vals = left_right.some(elt => elt[0] === '3')
                return <Grid key={keyDisc} container justify="center" alignItems="center"  style={{margin: '5px auto', backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    <Grid item xs={1} className="dropdown-icon-grid">
                        {is_sub_vals && <DropdownIcon
                            up_down={this.state.cursor === keyDisc}
                            onClick={() => this.handleDropdown(curr_val, keyDisc)}
                        />}
                    </Grid>
                    <Grid item xs={true}>
                        {valuesNameTrad["facet-arth"]['left-right'][this.props.language].map((side, keySide) => {
                            return <FacetModule
                                id={keySide}
                                key={keySide}
                                side={side}
                                theme={theme}
                                curr_val={left_right.length >= keySide+1 ? left_right[keySide] : '100'}
                                display_sub={is_sub_vals && this.state.cursor === keyDisc}
                                handleValuesChange={(keyValue) => this.handleValuesChange(keyDisc, keySide, keyValue.toString())}
                                handleSubValuesChange={(keyValue) => this.handleSubValuesChange(keyDisc, keySide, keyValue)}
                                handleSubSubValuesChange={(keyPosition, sub_position, new_val) => this.handleSubSubValuesChange(keyDisc, keySide, keyPosition, sub_position, new_val)}
                            />
                        })}
                    </Grid>
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                </Grid>
            }
            else return null
        })
    }
}

export default FacetArthropathy