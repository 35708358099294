import React from 'react'
import {createTheme, Grid} from "@mui/material";
import {discs} from "../items";
import {DropdownIcon, getNewSubValues, handleValuesChange, NotSureResetBtn} from "../utils";
import {NeuralForaminalStenosisModule} from "./NeuralForaminalStenosisModule";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

const NB_SUB_VALUES = 8

export default class NeuralForaminalStenosis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursor: null
        }
    }

    /**
     * Called when user clicks on grade radio button
     *
     * @param curr_val {string}
     * @param disc {number} - 0 for T8/T9, 1 for T9/T10, ... 9 for L5/S1
     * @param left_right {number} - 0 if left, 1 if right
     * @param value {string} - new grade value ∈ {'0', '1', '2', '3'}
     */
    handleValuesChange = (curr_val, disc, left_right, value) => {
        this.setState({cursor: disc})
        let val = curr_val === 'WARN' ? ['100', '100'] : curr_val.split(';')
        val[left_right] = handleValuesChange(val[left_right], value, NB_SUB_VALUES)
        this.props.handleCheck(disc, val.join(';'))
    }

    /**
     * Called when user clicks on sub_value checkbox button
     *
     * @param curr_val {string}
     * @param disc {number} - 0 for T8/T9, 1 for T9/T10, ... 9 for L5/S1
     * @param left_right  {number} - 0 if left, 1 if right
     * @param position {number} - index of the sub_value to be modified ∈ {0,1,...,NB_SUB_VALUES-1}
     */
    handleSubValuesChange = (curr_val, disc, left_right, position) => {
        let left_right_vals = curr_val.split(';')
        const sub_vals = left_right_vals[left_right].split(',').slice(1)
        if (sub_vals.length > position) {
            left_right_vals[left_right] = getNewSubValues(left_right_vals[left_right], position)
            this.props.handleCheck(disc, left_right_vals.join(';'))
        }
        else alert('An error occured, please contact Caerus team')
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    handleDropdown = (curr_val, disc) => {
        if (this.state.cursor === disc) this.setState({cursor: null})
        else {
            const left_right = curr_val.replace('?', '').split(';')
            if (left_right.some(elt => elt !== '0' && elt !== '100')) this.setState({cursor: disc})
        }
    }

    render() {
        const theme = createTheme({typography: {fontSize: 13}})
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) {
                const left_right = curr_val.replace('?', '').split(';')
                const is_sub_vals = left_right.some(elt => elt !== '0' && elt !== '100' && elt !== 'WARN')
                return <Grid key={keyDisc} container justify="center" alignItems="center" style={{marginBottom: '10px', backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    <Grid item xs={1} className="dropdown-icon-grid">
                        {is_sub_vals && <DropdownIcon
                            up_down={this.state.cursor === keyDisc}
                            onClick={() => this.handleDropdown(curr_val, keyDisc)}
                        />}
                    </Grid>
                    <Grid item xs={true}>
                        {valuesNameTrad['nfs']['left-right'][this.props.language].map((side, keySide) => {
                            return <NeuralForaminalStenosisModule
                                id={keySide}
                                key={keySide}
                                side={side}
                                theme={theme}
                                curr_val={left_right.length >= keySide+1 ? left_right[keySide] : '100'}
                                display_sub={is_sub_vals && this.state.cursor === keyDisc}
                                handleValuesChange={(keyValue) => this.handleValuesChange(curr_val, keyDisc, keySide, keyValue.toString())}
                                handleSubValuesChange={(keyValue) => this.handleSubValuesChange(curr_val, keyDisc, keySide, keyValue)}
                            />
                        })}
                    </Grid>
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                </Grid>
            }
            else return null
        })
    }
}
