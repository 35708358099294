import {Base} from "../../Help/Base";
import React from "react";
import {helpTrad} from "../../../../../../../utils/traductions";

export const HerniationHelps = ({id, language}) => id === 0 ? null :
    id === 1
        ? <Base
            title={helpTrad['herniation']['trans_loc']['title'][language]}
            definition={helpTrad['herniation']['trans_loc']['definition'][language]}
            content={<img src={`/help/DiscHerniation/TransversalLocalization_${language}.png`} alt='test'/>}
            references={["¹Fardon, D. F. et al. Lumbar disc nomenclature: version 2.0: Recommendations of the combined task forces of the North American Spine Society, the American Society of Spine Radiology and the American Society of Neuroradiology. Spine J 14, 2525–2545 (2014)"
            ]}
        />
        : id === 2
            ? <Base
                title={helpTrad['herniation']['transligamentous']['title'][language]}
                definition={helpTrad['herniation']['transligamentous']['definition'][language]}
                content={<img src={`/help/DiscHerniation/Transligamentous_${language}.png`} alt='test'/>}
                references={["¹Fardon, D. F. et al. Lumbar disc nomenclature: version 2.0: Recommendations of the combined task forces of the North American Spine Society, the American Society of Spine Radiology and the American Society of Neuroradiology. Spine J 14, 2525–2545 (2014)",
                    "²Lin, W., Ma, W. & Xue, Y. Low Back Pain Induced by Posterior Longitudinal Ligament Incision in Percutaneous Transforaminal Endoscopic Lumbar Discectomy. Orthopaedic Surgery 12, 1230–1237 (2020)",
                    "³Cassar-Pullicino, V. N. MRI of the ageing and herniating intervertebral disc. European Journal of Radiology 27, 214–228 (1998)"
                ]}
            />
            : id === 3
                ? <Base
                    title={helpTrad['herniation']['sag_loc']['title'][language]}
                    definition={helpTrad['herniation']['sag_loc']['definition'][language]}
                    content={<img src={`/help/DiscHerniation/SagittalLocalization_${language}.png`} alt='test'/>}
                    references={["¹Fardon, D. F. et al. Lumbar disc nomenclature: version 2.0: Recommendations of the combined task forces of the North American Spine Society, the American Society of Spine Radiology and the American Society of Neuroradiology. Spine J 14, 2525–2545 (2014)"
                    ]}
                />
                : id === 4
                    ? <Base
                        title={helpTrad['herniation']['sequestrated']['title'][language]}
                        definition={helpTrad['herniation']['sequestrated']['definition'][language]}
                        content={<img src={`/help/DiscHerniation/Sequestrated_${language}.png`} alt='test'/>}
                        references={["¹Fardon, D. F. et al. Lumbar disc nomenclature: version 2.0: Recommendations of the combined task forces of the North American Spine Society, the American Society of Spine Radiology and the American Society of Neuroradiology. Spine J 14, 2525–2545 (2014)"
                        ]}
                    />
                    : <Base
                        title={helpTrad['herniation']['conflict']['title'][language]}
                        definition={''}
                        content={<img src={`/help/DiscHerniation/Conflict_${language}.png`} alt='test'/>}
                        references={[""]}
                    />


export const width = [2,3,3,2,2]