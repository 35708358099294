import {Divider} from "@mui/material";
import {GridSample} from "./GridSample";
import {
    medTrad,
    radiographieQuestions,
    standardXRay, titlesSurgery,
    treatmentVals,
    valuesOperation, valuesRadiographie,
    valuesSurgery
} from "./constants";
import React from "react";
import {useTranslation} from "react-i18next";
import {displayLine, Radiology, Treatment} from "./utils";


export const RadioSurgery = ({radiosigns, treatment, next_surg, prev_surg, radio_standard}) => {
    const { i18n } = useTranslation()
    return <>
        <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
        {radiosigns && <GridSample
            titles={[titlesSurgery[i18n.language][0]]}
            state={[medTrad[radiosigns] !== undefined ? medTrad[radiosigns][i18n.language] : radiosigns]}
            unities={[' ']}
        />}
        <br/><br/>
        {displayLine(prev_surg) && <Radiology
            title={titlesSurgery[i18n.language][1]}
            subtitles={valuesOperation[i18n.language]}
            keys_state={Object.keys(prev_surg)}
            state={prev_surg}
        />}
        {displayLine(next_surg) && <Radiology
            questions={radiographieQuestions[i18n.language]}
            title={titlesSurgery[i18n.language][2]}
            subtitles={valuesSurgery[i18n.language]}
            keys_state={Object.keys(next_surg)}
            state={next_surg}
        />}
        {displayLine(treatment) && <Treatment
            title={treatmentVals[i18n.language]}
            keys_state={Object.keys(treatment)}
            state={treatment}
        />}
        {displayLine(radio_standard) && <Radiology
            questions={radiographieQuestions[i18n.language]}
            title={standardXRay[i18n.language]}
            subtitles={valuesRadiographie[i18n.language]}
            keys_state={Object.keys(radio_standard)}
            state={radio_standard}
        />}
    </>
}