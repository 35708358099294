import {getColorDisease} from "../getDiseasesColor"
import {clear_values} from './items'

/**
 * Returns the clear value for the disease_id passed as parameter
 *
 * @param disease_id {number}
 * @returns {string}
 */
export const clearDisease = (disease_id) => {
    if (Object.keys(clear_values).some(elt => parseInt(elt) === disease_id)) return clear_values[disease_id]['clear']
    else return '0'
}

/**
 * Returns the reset value for the disease_id passed as parameter
 *
 * @param disease_id {number}
 * @returns {string}
 */
export const resetDisease = (disease_id) => {
    if (Object.keys(clear_values).some(elt => parseInt(elt) === disease_id)) return clear_values[disease_id]['reset']
    else return '0'
}

/**
 * Reset only one endplate for modic classification
 *
 * @param previous_val {string} - disc value to be reset, ex: '2;3'
 * @param label_disc {string} - whether we should reset up or down part of disc value, ex: 'T12 up'
 * @returns {string} - ex: '100;3'
 */
export const resetDiscModic = (previous_val, label_disc) => {
    return label_disc.split(' ')[1] === 'up' ? previous_val.split(';')[0].concat(';100') : '100;'.concat(previous_val.split(';')[1])
}

/**
 * Returns the disease status for all disease (except Modic)
 *
 * @param values {string[]} - disc values
 * @returns {number} - 0 if the disease is not annotated, 1 if it is, -1 is values is null
 */
export const getDiseaseStatus = (values) => {
    if (values) {
        if (values.some(elt => elt !== null)) {
            if (values.some(element => element === '100')) return 0
            else return 1
        } else return 0
    } else return -1
}

/**
 * Returns the disease status for Modic
 *
 * @param values {string[]} - disc values
 * @returns {number} - 0 if Modic is not annotated, 1 if it is, -1 is values is null
 */
export const getModicStatus = (values) => {
    if (values) {
        if (values.some(elt => elt !== null)) {
            if (values.some(element => element !== null && element.split(';').includes('100'))) return 0
            else return 1
        } else return 0
    } else return -1
}

/**
 *
 * @param disease {Object}
 * @param disease_id {number}
 * @param nbr_discs {number}
 */
export const getDiseaseStatusColor = (disease, disease_id, nbr_discs) => {
    //TODO: write tests
    let values = new Array(nbr_discs).fill(null)
    let color = '#F52A2A'
    let status = 0
    if (disease.disc) {
        values = Object.values(disease.disc).slice(0, nbr_discs)
        color = getColorDisease(disease_id, values)
        status = disease_id === 1 || disease_id === 11 ? getModicStatus(values) : getDiseaseStatus(values)
    }
    return {'values': values, 'color': color, 'status': status}
}

/**
 * Function used in AnnotateDisease component to check each pathologies'status (done/not done) and patient status
 *
 * @param diseases
 * @param nbr_diseases {number}
 * @param nbr_discs {number}
 * @returns {{current_values: any[], patient_colors: any[], disease_status: *[]}}
 */
export const handleDiseasesValues = (diseases, nbr_diseases, nbr_discs) => {
    //TODO: write tests
    const disease_status = []
    const current_values = Array(nbr_diseases).fill(null)
    const patient_colors = Array(nbr_diseases).fill(getColorDisease(null, null))
    diseases.forEach(disease => {
        const disease_id = disease.disease - 1
        const status_color = getDiseaseStatusColor(disease, disease_id, nbr_discs)
        //update current_values, patient_colors, disease_status
        current_values[disease_id] = status_color['values']
        patient_colors[disease_id] = status_color['color']
        disease_status.push(status_color['color'])
    })
    return {'current_values': current_values, 'patient_colors': patient_colors, 'disease_status': disease_status}
}