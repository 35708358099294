import React from 'react'
import './ClinicalData.css'
import {getPatientDetails} from "../../../../../../services/CollectorService";
import {Divider, Grid} from "@mui/material";
import {GridSample} from "./GridSample";
import {identities, medHistory, unities, workVals} from "./constants";
import {useTranslation} from "react-i18next";
import {RadioSurgery} from "./RadioSurgery";


export const ClinicalData = ({id}) => {
    const [identity, setIdentity] = React.useState(null)
    const [med_history, setMedHistory] = React.useState(null)
    const [work, setWork] = React.useState(null)

    const [radioSurg, setRadioSurg] = React.useState(null)

    const [fetchError, setFetchError] = React.useState(false)

    const { i18n, t } = useTranslation()

    const fetchData = () => {
        getPatientDetails(id).then(response => {
            const ident = {label: response.data.label,
                sex: response.data.sex,
                age: response.data.age,
                height: response.data.height,
                weight: response.data.weight}
            setIdentity(ident)
            const medhistory = {smoke: response.data.med_history.smoke,
                diabete: response.data.med_history.diabete,
                lumbar_pain: response.data.med_history.lumbar_pain,
                rad_pain: response.data.med_history.rad_pain,
                radiculalgia: response.data.med_history.radiculalgia,
                odi: response.data.med_history.odi}
            setMedHistory(medhistory)
            setWork({work: response.data.work.work, employement: response.data.work.employment})

            setRadioSurg(response.data.radiosurgery)
        }).catch(() => setFetchError(true))
    }

    React.useEffect(() => {
        if (fetchError) setFetchError(false)
        else fetchData()
    }, [id])
    React.useEffect(() => {
        if (!setFetchError) fetchData()
    }, [fetchError])

    if (identity !== null && med_history !== null && work !== null) return <Grid container id="patient-details">
        <GridSample
            titles={identities[i18n.language]}
            names={['label', 'sex', 'age', 'height', 'weight']}
            state={identity}
            unities={unities[i18n.language]}
        />
        <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
        <GridSample
            titles={medHistory[i18n.language]}
            names={['smoke','diabete','lumbar_pain','rad_pain', 'radiculalgia', 'odi']}
            state={med_history}
            unities={[' ', ' ', '/10', '/10', ' ', '/100']}
        />
        <Divider variant="middle" style={{width: '50%', margin: '10px auto'}} />
        <GridSample
            titles={workVals[i18n.language]}
            names={['work', 'employement']}
            state={work}
            unities={[' ', ' ']}
        />
        {radioSurg !== null && <RadioSurgery
            radiosigns={radioSurg.radiosigns}
            treatment={radioSurg.treatment}
            next_surg={radioSurg.next_surg}
            prev_surg={radioSurg.spine_op}
            radio_standard={radioSurg.radio_standard}
        />}
    </Grid>
    else return <Grid container id="patient-details-loading">
        {fetchError
            ? <p>{t('fetch-error')}</p>
            : <p>{t('loading')}</p>
        }
    </Grid>
}