import React from 'react'
import {createTheme, Grid} from "@mui/material";
import {discs} from "../items";
import {MainValues, NotSureResetBtn} from "../utils";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

export default class FacetEdema extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: 13
        }
    }

    handleValuesChange = (curr_val, disc, value) => {
        const not_sure = curr_val.endsWith('?') ? '?' : ''
        this.props.handleCheck(disc, value + not_sure)
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    render() {
        const theme = createTheme({typography: {fontSize: this.state.fontSize}})
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) {
                return <Grid key={keyDisc} container justify="center" alignItems="center" style={{backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    <Grid item xs={2} />
                    <Grid item xs={true} className="checkboxes">
                        <MainValues
                            theme={theme}
                            curr_val={curr_val}
                            values={valuesNameTrad['facet-edema']['values'][this.props.language]}
                            size={6}
                            handleValuesChange={(keyValue) => this.handleValuesChange(curr_val, keyDisc, keyValue.toString())}
                        />
                    </Grid>
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                </Grid>
            }
            else return null
        })
    }
}