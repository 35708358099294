import {Slider, styled} from "@mui/material";

export const diabete = ['no', "type 1", "type 2"]

export const sagittals = [0, 1, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export const axials = [2, 3, 13, 14, 15]

export const mod = [
    "SagT1", //0
    "SagT2", //1
    "AxT1", //2
    "AxT2", //3
    "SagT1w", //4
    "SagT2w", //5
    "SagT2 STIR", //6
    "Sag Flair", //7
    "Sag DIXON w", //8
    "Sag DIXON f", //9
    "Sag DIXON in", //10
    "Sag DIXON opp", //11
    "SagT2 3D", //12
    "AxT1w", //13
    "AxT2w", //14
    "AxT2 3D", //15
    "Coronal T2 STIR", //16
    "Unknown" //17
]
export const colors = [
    "rgba(19, 55, 90, 0.8)", //SagT1
    "rgba(19, 55, 90, 0.4)", //SagT2
    "rgba(215,174,103, 0.8)", //AxT1
    "rgba(215,174,103, 0.6)", //AxT2
    "rgba(19, 55, 90, 0.8)", //SagT1w
    "rgba(19, 55, 90, 0.4)", //SagT2w
    "rgba(19, 55, 90, 0.6)", //Sag STIR
    "rgba(19, 55, 90, 0.2)", //Sag Flair
    "rgba(95, 158, 160, 0.8)", //Sag DIXON w
    "rgba(95, 158, 160, 0.6)", //Sag DIXON f
    "rgba(95, 158, 160, 0.4)", //Sag DIXON in
    "rgba(95, 158, 160, 0.2)", //Sag DIXON opp
    "rgba(38,67,72, 0.5)", //Sag T2 3D
    "rgba(215,174,103, 0.8)", //AxT1w
    "rgba(215,174,103, 0.6)", //AxT2w
    "rgba(215,174,103, 0.4)", //Ax T2 3D
    "#d3d3d3", //Cor T2 STIR
    "#b3b3b3" //unknown
]
export const text_colors = [
    "white", //SagT1
    "white", //SagT2
    "#13375A", //AxT1
    "#13375A", //AxT2
    "white", //SagT1w
    "white", //SagT2w
    "white", //Sag STIR
    "#13375A", //Sag Flair
    "white", //Sag DIXON w
    "black", //Sag DIXON f
    "black", //Sag DIXON in
    "black", //Sag DIXON opp
    "white", //Sag T2 3D
    "#13375A", //AxT1w
    "#13375A", //AxT2w
    "#13375A", //AxT2 3D
    "#13375A", //Sor T2 STIR
    "#13375A", //Unknown
]

export const images = [['image-one-win', 'image-box-one-win'], ['image-two-win', 'image-box-two-win'], ['image-three-win-large', 'image-box-three-win'], ['image-three-win-small', 'image-box-three-win']]

export const resetBlobs = {
    0: {}, //SagT1
    1: {}, //SagT2
    2: {}, //AxT1
    3: {}, //AxT2
    4: {}, //SagT1w
    5: {}, //SagT2w
    6: {}, //Sag STIR
    7: {}, //Sag Flair
    8: {}, //Sag DIXON w
    9: {}, //Sag DIXON f
    10: {}, //Sag DIXON in
    11: {}, //Sag DIXON opp
    12: {}, //Sag T2 3D
    13: {}, //AxT1w
    14: {}, //AxT2w
    15: {}, //Ax T2 3D
    16: {}, //Cor T2 STIR
    17: {}, //Unknown
}

export const resetModalities = {
    "view_1": null,
    "view_2": {
        "left" : null,
        "right": null
    },
    "view_3": {
        "left": null,
        "right_up": null,
        "right_down": null
    }
}

export const resetSlices = {
    "view_1": 0,
    "view_2": {
        "left" : 0,
        "right": 0
    },
    "view_3": {
        "left": 0,
        "right_up": 0,
        "right_down": 0
    }
}

export const SlicesSlider = styled(Slider)({
    width: '75%',
    margin: 'auto',
    height: 3,
    "& .MuiSlider-thumb": {
        height: 12,
        width: 12,
        "&:hover, &:focus, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: '0 0 0 6px rgba(255, 255, 255, 0.2)',
        },
    },

});

export const DiscsSlider = styled(Slider)({
    color: 'var(--dark-blue)',
    width: '90%',
    margin: 'auto',
    height: 3,
    "& .MuiSlider-thumb": {
        height: 12,
        width: 12,
        backgroundColor: "var(--dark-blue)",
        border: "2px solid currentColor",
        "&:hover, &:focus, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: '0 0 0 6px rgba(19, 55, 90, 0.2)',
        },
    },

});

export const discs_range_marks = [
    {
        value: 0,
        label: 'T8',
    },
    {
        value: 1,
        label: 'T9',
    },
    {
        value: 2,
        label: 'T10',
    },
    {
        value: 3,
        label: 'T11',
    },
    {
        value: 4,
        label: 'T12',
    },
    {
        value: 5,
        label: 'L1',
    },
    {
        value: 6,
        label: 'L2',
    },
    {
        value: 7,
        label: 'L3',
    },
    {
        value: 8,
        label: 'L4',
    },
    {
        value: 9,
        label: 'L5',
    },
    {
        value: 10,
        label: 'S1',
    }
];