import React from 'react'
import {createTheme, Grid} from "@mui/material";
import fromStringToInt, {DropdownIcon, handleValuesChange, MainValues, NotSureResetBtn, SubValues} from "./utils";
import {discs_modic, valuesNameTrad} from "../../../../../../utils/traductions";


class EndplateDefects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursor: null,
            values: [],
        }
    }

    updateValuesState = (new_values) => {
        this.setState({values: fromStringToInt(new_values)})
    }

    componentDidMount() {
        this.updateValuesState(this.props.values)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.values !== prevProps.values) this.updateValuesState(this.props.values)
    }

    handleCheck = (disc, new_values, result) => {
        this.setState({values: new_values})
        if (disc%2 === 0 && new_values[disc+1] !== undefined) {
            this.props.handleCheck(Math.trunc(disc/2), result.concat(';', new_values[disc+1]))
        } else if (disc%2 !== 0 && new_values[disc-1] !== undefined) {
            this.props.handleCheck(Math.trunc(disc/2), new_values[disc-1].concat(';',result))
        } else this.props.handleCheck(Math.trunc(disc/2), null)
    }


    handleChange = (curr_val, disc, new_val) => {
        //disc is in (0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19)  => ex: T12 down
        //new_val is in ('0','1','2','3')
        this.setState({cursor: disc})
        const new_values = this.state.values.slice()
        const nb_sub_vals = valuesNameTrad['endplate']['sub_values'][this.props.language].length
        const result = handleValuesChange(curr_val === 'WARN' ? '100': curr_val, new_val, nb_sub_vals)
        new_values[disc] = result
        this.handleCheck(disc, new_values, result)
    }

    handleSubValuesChange = (disc, position) => {
        const new_values = this.state.values.slice()
        const val = new_values[disc].split(',')
        let not_sure = ''
        if (val[position + 1].endsWith("?")) not_sure = '?'
        if (val[position + 1][0] === '0') val[position + 1] = "1" + not_sure
        else val[position + 1] = '0' + not_sure
        let new_value = val.toString()
        new_values[disc] = new_value
        this.setState({values: new_values})
        if (disc%2 === 0 && new_values[disc+1] !== undefined) {
            this.props.handleCheck(Math.trunc(disc/2), new_value.concat(';', new_values[disc+1]))
        } else if (disc%2 !== 0 && new_values[disc-1] !== undefined) {
            this.props.handleCheck(Math.trunc(disc/2), new_values[disc-1].concat(';',new_value))
        } else this.props.handleCheck(Math.trunc(disc/2), null)
    }

    handleResetDisc = (disc, label) => {
        //disc is in (0,1,2,3,4,5,6,7,8,9,10,11)
        //label is in ('T8/T9', 'T9/T10', ..., 'L5/S1')
        const new_values = this.state.values.slice()
        new_values[disc] = '100'
        this.setState({values: new_values})
        this.props.handleResetDisc(Math.trunc(disc/2), label)
    }

    handleWarning = (curr_val, disc) => {
        //disc is in (0,1,2,3,4,5,6,7,8,9,10,11)
        const new_values = this.state.values.slice()
        const new_val = curr_val === 'WARN' ? '100' : 'WARN'
        new_values[disc] = new_val
        this.setState({values: new_values})
        this.props.handleCheck(Math.trunc(disc/2), new_val)
    }

    handleNotSure = (curr_val, disc) => {
        const new_values = this.state.values.slice()
        const result = curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?'
        new_values[disc] = result
        this.handleCheck(disc, new_values, result)
    }

    handleDropdown = (curr_val, disc) => {
        if (this.state.cursor === disc) this.setState({cursor: null})
        else if (curr_val !== "0" && curr_val !== "100") this.setState({cursor: disc})
    }

    render() {
        const theme = createTheme({typography: {fontSize: 13}})
        return discs_modic[this.props.language].map((disc, keyDisc) => {
            const curr_val = this.state.values[keyDisc]
            if (curr_val) {
                const is_sub_vals = curr_val !== "100" && curr_val !== "WARN" && curr_val[0] !== "0"
                const sub_vals = curr_val.slice(2).replace('?', '').split(',')
                return <Grid key={keyDisc} container justify="center" alignItems="center" style={{backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={2} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    <Grid item xs={1} className="dropdown-icon-grid">
                        {is_sub_vals && <DropdownIcon
                            up_down={this.state.cursor === keyDisc}
                            onClick={() => this.handleDropdown(curr_val, keyDisc)}
                        />}
                    </Grid>
                    <Grid item xs={6} className="checkboxes">
                        <MainValues
                            theme={theme}
                            curr_val={curr_val}
                            values={valuesNameTrad['endplate']['values'][this.props.language]}
                            size={6}
                            handleValuesChange={keyValue => this.handleChange(curr_val, keyDisc, keyValue.toString())}
                        />
                    </Grid>
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.handleResetDisc(keyDisc, disc)}
                    />
                    {is_sub_vals && this.state.cursor === keyDisc && <Grid container justify="center" alignItems="center">
                        <Grid item xs={3}/>
                        <SubValues
                            xs={6}
                            title={valuesNameTrad['endplate']['sub_values_title'][this.props.language]}
                            choices={valuesNameTrad['endplate']['sub_values'][this.props.language]}
                            sub_color={sub_vals.includes('1') ? 'var(--dark-blue)' : 'crimson'}
                            sub_vals={sub_vals}
                            handleChange={(keyValue) => this.handleSubValuesChange(keyDisc, keyValue)}
                        />
                        <Grid item xs={3}/>
                    </Grid>}
                </Grid>
            }
            else return null
        })
    }
}

export default EndplateDefects