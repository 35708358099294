import {axials, sagittals} from "./items";


export const matching_view2 = (window_id, ax_sag, series_values, slice_left, slice_right) => {
    const ax_left = axials.find(elt => elt === series_values[0])
    const sag_left = sagittals.find(elt => elt === series_values[0])
    const ax_right = axials.find(elt => elt === series_values[1])
    const sag_right = sagittals.find(elt => elt === series_values[1])

    if (window_id === 0) {
        // on cherche a afficher limage de gauche
        const win_is_sagittal = ax_sag === 'sag' && (sag_left !== undefined)
        const win_is_axial = ax_sag === 'ax' && (ax_left !== undefined)
        if (win_is_axial && sag_right !== undefined) return [sag_right, slice_right, series_values[0], slice_left]
        else if (win_is_sagittal && ax_right !== undefined) return [ax_right, slice_right, series_values[0], slice_left]
        else return null
    } else {
        // on cherche a afficher limage de droite
        const win_is_sagittal = ax_sag === 'sag' && (sag_right !== undefined)
        const win_is_axial = ax_sag === 'ax' && (ax_right !== undefined)
        if (win_is_axial && sag_left !== undefined) return [sag_left, slice_left, series_values[1], slice_right]
        else if (win_is_sagittal && ax_left !== undefined) return [ax_left, slice_left, series_values[1], slice_right]
        else return null
    }
}


/**
 * Check if a matching is possible
 *
 * @type {number|number}
 */
export const matching_view3 = (window_id, ax_sag, series_values, slice_left, slice_right_up, slice_right_down) => {
    const ax_left = axials.find(elt => elt === series_values[0])
    const sag_left = sagittals.find(elt => elt === series_values[0])

    const ax_right_up = axials.find(elt => elt === series_values[1])
    const sag_right_up = sagittals.find(elt => elt === series_values[1])

    const ax_right_down = axials.find(elt => elt === series_values[2])
    const sag_right_down = sagittals.find(elt => elt === series_values[2])

    if (window_id === 0) {
        const win_is_sagittal = ax_sag === 'sag' && (sag_left !== undefined)
        const win_is_axial = ax_sag === 'ax' && (ax_left !== undefined)

        //on cherche a afficher limage de gauche
        if (win_is_sagittal && (ax_right_up || ax_right_down)) { //si on a laxial a droite (en haut ou en bas) et pas a gauche
            if (ax_right_up) return [ax_right_up, slice_right_up, series_values[0], slice_left]
            else return [ax_right_down, slice_right_down, series_values[0], slice_left]

        } else if (win_is_axial && (sag_right_up || sag_right_down)) {
            if (sag_right_up) return [sag_right_up, slice_right_up, series_values[0], slice_left]
            else return [sag_right_down, slice_right_down, series_values[0], slice_left]
        } else return null

    } else if (window_id === 1) {
        const win_is_sagittal = ax_sag === 'sag' && !ax_right_up
        const win_is_axial = ax_sag === 'ax' && ax_right_up
        //on cherche a afficher limage en haut a droite
        if (win_is_sagittal && (ax_left || ax_right_down)) { //si de lautre cote on a de lax
            if (ax_left) return [ax_left, slice_left,series_values[1], slice_right_up]
            else return [ax_right_down, slice_right_down, series_values[1], slice_right_up]

        } else if (win_is_axial && (sag_left || sag_right_down)) {
            if (sag_left) return [sag_left, slice_left, series_values[1], slice_right_up]
            else return [sag_right_down, slice_right_down, series_values[1], slice_right_up]
        } else return null
    } else if (window_id === 2) {
        const win_is_sagittal = ax_sag === 'sag' && !ax_right_down
        const win_is_axial = ax_sag === 'ax' && ax_right_down
        //on cherche a afficher limage en bas a droite
        if (win_is_sagittal && (ax_left || ax_right_up)) { //si de lautre cote on a de lax
            if (ax_left) return [ax_left, slice_left, series_values[2], slice_right_down]
            else return [ax_right_up, slice_right_up, series_values[2], slice_right_down]

        } else if (win_is_axial && (sag_left || sag_right_up)) {
            if (sag_left) return [sag_left, slice_left, series_values[2], slice_right_down]
            else return [sag_right_up, slice_right_up, series_values[2], slice_right_down]
        } else return null
    }
}