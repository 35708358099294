import React from "react";
import {Grid} from "@mui/material";
import {MainValues, SubValues} from "../utils";
import {valuesNameTrad} from "../../../../../../../utils/traductions";
import {useTranslation} from "react-i18next";


export const NeuralForaminalStenosisModule = ({id, side, theme, curr_val, display_sub, handleValuesChange, handleSubValuesChange}) => {
    const color = id === 0 ? 'var(--dark-blue)' : 'var(--gold)'
    const is_sub_vals = display_sub && curr_val !== '100' && curr_val[0] !== '0'
    const sub_val = curr_val.slice(2).split(',')

    const { i18n } = useTranslation()

    return <Grid container>
        <Grid container justify="center" alignItems="center">
            <Grid item xs={3} style={{display: 'flex', flexDirection: 'column'}}>
                <p style={{color: color, cursor: 'default'}}>{side}</p>
            </Grid>
            <Grid item xs={9}>
                <MainValues
                    color={color}
                    theme={theme}
                    curr_val={curr_val}
                    values={valuesNameTrad['nfs']['values'][i18n.language]}
                    label={false}
                    size={[2, 3, 3, 4]}
                    handleValuesChange={handleValuesChange}
                />
            </Grid>
        </Grid>
        {is_sub_vals && <Grid container justify="center" alignItems="center">
            <SubValues
                color={id === 0}
                choices={valuesNameTrad['nfs']['sub_values'][i18n.language]}
                xs={12}
                sub_color={sub_val.includes('1') ? 'var(--dark-blue)' : 'crimson'}
                sub_vals={sub_val}
                handleChange={handleSubValuesChange}
            />
        </Grid>}
    </Grid>
}