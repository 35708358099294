const green_color = 'var(--green)'
const orange_color = 'var(--orange)'
const red_color = 'var(--red)'

const compareCounts = (count_disc, count_done) => {
    //TODO: write tests
    if (count_done === 0) return red_color
    else if (count_disc === count_done) return green_color
    else return orange_color
}

/**
 * Returns the color of the disease button
 *
 * @param {number} disease_id
 * @param {string[]} values
 * @returns {string}
 */
export const getColorDisease = (disease_id, values) => {
    if (disease_id !== null) {
        if (values !== null) {
            if (disease_id === 0 || disease_id === 1 || disease_id === 15) return getColorPfirrMod(values)
            else if (disease_id === 3) return getColorAnteroRetro(values)
            else if (disease_id === 4) return getColorHernie(values)
            else if (disease_id === 5) return getColorStenosis(values) //CCS
            else if (disease_id === 6) return getColorEndplateDefects(values) //NFS => OK
            else if (disease_id === 7) return getColorLateralRecess(values)
            else if (disease_id === 9) return getColorFacet(values)
            else if (disease_id === 8 || disease_id === 10) return getColorTransVb(values)
            else if (disease_id === 11) return getColorEndplateDefects(values)
            else if ((disease_id >= 12 && disease_id <= 14) || disease_id === 16) return getColorMuscles(values)
            else return 'var(--light-blue)'
        } else return 'var(--light-blue)'
    } else return 'var(--light-blue)'
}

export function getColorPfirrMod(values) {
    if (!values.includes('100')) return green_color
    else if (values.some(element => element !== '100' && element !== null)) return orange_color
    else return red_color
}

export function getColorAnteroRetro(values) {
    if (values.some(elt => elt !== null && elt !== 'WARN')) {
        if (values.some(elt => elt !== '100')) {
            let count_disc = values.length
            let count_done = 0
            values.forEach(ivd_value => {
                if (ivd_value === null) count_disc -= 1
                else if (ivd_value[0] === '0') count_done += 1
                else if (ivd_value !== '100') {
                    const sub_vals = ivd_value.replace('?', '').split(',').slice(1)
                    if (sub_vals.includes('x')) count_done += 0.5
                    else count_done += 1
                }
            })
            return compareCounts(count_disc, count_done)
        } else return red_color
    } else return green_color
}

export function getColorHernieModule(val) {
    if (val !== '100') {
        const sub_vals =  val.split(',')
        if (sub_vals[0] === '0') return 1
        else {
            const conflict_ok = sub_vals.length >= 5 ? sub_vals[5] !== 'x' && sub_vals[5] !== '0_0_0_0' : true
            if (sub_vals[0] === '1' && conflict_ok) return 1
            else {
                const trans_loc = sub_vals[1].split('_').slice(0, 4).includes('1') && sub_vals[1].split('_')[4] !== 'x'
                if (sub_vals[0] === '2' && sub_vals[1].split('_').includes('1') && conflict_ok) return 1
                else if (trans_loc && sub_vals[2] !== 'x' && conflict_ok) {
                    if (sub_vals[0] === '3') return 1
                    else if (sub_vals[3].split('_').includes('1') && sub_vals[4] !== 'x') return 1 //sagittal loc
                }
            }
        }
        return 0.5
    } else return 0
}

export function getColorHernie(values) {
    if (values.some(elt => elt !== null && elt !== 'WARN')) {
        if (values.some(elt => elt !== '100')) {
            let count_disc = values.length
            let count_done = 0
            values.forEach(ivd_value => {
                if (ivd_value === null) count_disc -= 1
                else if (ivd_value === 'WARN') count_done += 1
                else {
                    const hernies = ivd_value.replace('?', '').split(';')
                    const nbr_hernies = hernies.length
                    count_done += getColorHernieModule(hernies[0])/nbr_hernies
                    if (nbr_hernies > 1) count_done += getColorHernieModule(hernies[1])/nbr_hernies
                }
            })
            return compareCounts(count_disc, count_done)
        } else return red_color
    } else return green_color
}

export function getColorTransVb(values) {
    if (values.some(elt => elt !== null && elt !== 'WARN')) {
        if (values.some(elt => elt !== '100')) {
            let count_disc = values.length
            let count_done = 0
            values.forEach(ivd_value => {
                if (ivd_value === null) count_disc -= 1
                else if (ivd_value[0] === '0') count_done += 1
                else if (ivd_value !== '100') {
                    if (ivd_value.replace('?', '') === '1') count_done += 0.5
                    else count_done += 1
                }
            })
            return compareCounts(count_disc, count_done)
        } else return red_color
    } else return green_color
}

export function getSubValColorFacet(sub_vals) {
    //returns 0.5 if done, 0.25 if started, 0 if not done
    if (sub_vals.slice(0,3).includes('1')) return 0.5
    else {
        let total = 4
        let sub_vals_count = 0
        sub_vals.slice(3).forEach(sub_val => {
            const split_array = sub_val.split('-')
            if (split_array.length === 1) total -= 1
            else if (split_array.slice(0, 3).includes('1') && split_array[3] !== 'x') sub_vals_count += 1
            else sub_vals_count += 0.5
        })
        if (sub_vals_count !== 0 && sub_vals_count === total) return 0.5
        else if (sub_vals_count !== 0) return 0.25
        else return 0
    }
}

/**
 * Left or right value of a facet annotation
 *
 * @param {String} val
 * @returns {number}
 */
export function getColorLeftRightFacet(val) {
    //returns 0.5 if done, 0.25 if started, 0 if not done
    if (val === '0') return 0.5
    else if (val !== '100') {
        if (val[0] === '1' || val[0] === '2') return 0.5
        else if (getSubValColorFacet(val.slice(2).split(',')) === 0.5) return 0.5
        else return 0.25
    }
    else return 0
}

export function getColorFacet(values) {
    let count_disc = values.length
    let count_done = 0
    values.forEach(ivd_value => {
        if (ivd_value === null) count_disc -= 1
        else if (ivd_value === 'WARN') count_done += 1
        else ivd_value.replace('?', '').split(';').forEach(val => count_done += getColorLeftRightFacet(val))
    })
    if (count_done === 0) return red_color
    else return compareCounts(count_disc, count_done)
}

export function getColorLateralRecess(values) {
    if (values.some(elt => elt !== null && elt !== 'WARN')) {
        if (values.some(elt => elt !== '100;100')) {
            let count_disc = values.length*2
            let count_done = 0
            values.forEach(ivd_value => {
                if (ivd_value === null) count_disc -= 2
                else if (ivd_value === '100') count_disc -= 1
                else if (ivd_value === 'WARN') count_done += 2
                else ivd_value.split(',').forEach(elt => {if (elt !== 'x') count_done += 1})
            })
            return compareCounts(count_disc, count_done)
        } else return red_color
    } else return green_color
}

export function getColorStenosis(values) {
    if (values.some(elt => elt !== null && elt !== 'WARN')) {
        if (values.some(elt => elt !== '100')) {
            let count_disc = values.length
            let count_done = 0
            values.forEach(ivd_value => {
                if (ivd_value === null) count_disc -= 1
                else if (ivd_value === 'WARN') count_done += 1
                else {
                    if (ivd_value !== '100') {
                        if (ivd_value[0] === '0' || ivd_value.replace('?', '').slice(2).split(',').includes('1')) count_done += 1
                    }
                }
            })
            return compareCounts(count_disc, count_done)
        } else return red_color
    } else return green_color
}

export function getColorEndplate(val) {
    if (val === '100') return 0
    else {
        if (val[0] === '0') return 1
        else {
            if (val.split(',').slice(1).includes('1')) return 1
            else return 0.5
        }
    }
}

export function getColorEndplateDefects(values) {
    if (values.some(elt => elt !== null && elt !== 'WARN')) {
        if (values.some(elt => elt !== '100;100')) {
            let count_disc = values.length*2
            let count_done = 0
            values.forEach(ivd_value => {
                if (ivd_value === null) count_disc -= 2
                else if (ivd_value === 'WARN') count_done += 2
                else if (ivd_value !== '100') {
                    ivd_value.replace('?', '').split(';').forEach(elt => count_done += getColorEndplate(elt))
                }
            })
            return compareCounts(count_disc, count_done)
        } else return red_color
    } else return green_color
}

export function getColorMuscles(values) {
    if (values.some(elt => elt !== null && elt !== 'WARN')) {
        if (values.some(elt => elt !== '100')) {
            let count_disc = values.length*2
            let count_done = 0
            values.forEach(ivd_value => {
                if (ivd_value === null) count_disc -= 2
                else if (ivd_value === 'WARN') count_done += 2
                else if (ivd_value !== '100') {
                    ivd_value.replace('?', '').split(',').forEach(elt => {if (elt !== 'x') count_done += 1})
                }
            })
            return compareCounts(count_disc, count_done)
        } else return red_color
    } else return green_color
}