import React from 'react'
import './DiscRangeSelection.css'
import {Divider, Grid} from "@mui/material";
import {discs_range_marks, DiscsSlider} from "../Viewer/items";
import {disease_ids, updateDiscRange} from "../utils/DiscRange";
import {defaultTrad} from "../../../../../utils/traductions";


class DiscRangeSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sup: null,
            inf: null,
        }
    }

    updateAnnotationsValues = () => {
        const sup = this.state.sup
        const inf = this.state.inf
        Object.keys(this.props.values).forEach(disease => {
            const disease_vals = this.props.values[disease] //array
            if (disease_vals) {
                for (let disc=0; disc < disease_vals.length; disc++) {
                    if (disease_vals[disc] === null &&
                        disc <= sup && disc >= inf && disc+1 <= sup && disc+1 >= inf) {
                        const new_val = disease === 'facet' || disease === 'nfs' ? '100;100' : '100'
                        this.props.handleCheck(disease_ids[disease], disc, new_val)
                    } else if (disease_vals[disc] !== null &&
                        (disc > sup || disc < inf || disc+1 > sup || disc+1 < inf)) {
                        this.props.handleCheck(disease_ids[disease], disc, null)
                    }
                }
            }
        })
    }

    updateDiscRange = () => {
        if (this.props.values !== undefined && this.props.patho_ref !== undefined) {
            const infsup = updateDiscRange(this.props.values[this.props.patho_ref])
            this.setState({inf: infsup[0], sup: infsup[1]})
        }
        if (this.props.set_ready_for_annotation !== undefined) this.props.set_ready_for_annotation()
    }

    componentDidMount() {
        this.updateDiscRange()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.values !== this.props.values) this.updateDiscRange()
        if (prevState.sup !== this.state.sup || prevState.inf !== this.state.inf) {
            this.props.handleChangeSupInf(this.state.inf, this.state.sup)
            this.updateAnnotationsValues()
        }
    }

    render() {
        if (this.state.sup !== null && this.state.inf !== null && this.props.ready_for_annotations) {
            const txt = defaultTrad['annot-btns-txt'][this.props.language]
            return <Grid container className='disc-range-selection'>
                <Grid item xs={12}><p className='disc-range-title'>
                    {txt[0]} <b> {this.props.view}</b> {txt[1]}</p>
                </Grid>
                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                    <DiscsSlider
                        data-testid={'test-coucou'}
                        value={[this.state.inf, this.state.sup]}
                        onChange={(event, newValue) => this.setState({inf: newValue[0], sup: newValue[1]})}
                        marks={discs_range_marks}
                        min={0}
                        max={10}
                    />
                </Grid>
                <Divider />
            </Grid>
        }
        else return null
    }
}

export default DiscRangeSelection