export const months = {
    0: {'fr': 'Janvier', 'en': 'January'},
    1: {'fr': 'Février', 'en': 'February'},
    2: {'fr': 'Mars', 'en': 'March'},
    3: {'fr': 'Avril', 'en': 'April'},
    4: {'fr': 'Mai', 'en': 'May'},
    5: {'fr': 'Juin', 'en': 'June'},
    6: {'fr': 'Juillet', 'en': 'July'},
    7: {'fr': 'Août', 'en': 'August'},
    8: {'fr': 'Septembre', 'en': 'September'},
    9: {'fr': 'Octobre', 'en': 'October'},
    10: {'fr': 'Novembre', 'en': 'November'},
    11: {'fr': 'Décembre', 'en': 'December'}}

export const discs_modic = {
    'fr': ['T8 inf','T9 sup','T9 inf','T10 sup','T10 inf','T11 sup','T11 inf','T12 sup','T12 inf','L1 sup','L1 inf',
        'L2 sup','L2 inf','L3 sup','L3 inf','L4 sup','L4 inf', 'L5 sup','L5 inf','S1 sup'],
    'en': ['T8 down','T9 up','T9 down','T10 up','T10 down','T11 up','T11 down','T12 up','T12 down','L1 up','L1 down',
        'L2 up','L2 down','L3 up','L3 down','L4 up','L4 down', 'L5 up','L5 down','S1 up']
}

export const defaultTrad = {
    'annot-btns-txt': {
        'en' : ["Please, select the range of visible vertebrae on", "images before starting annotations."],
        'fr': ["Merci d'ajuster l'échelle des vertèbres visibles sur les images", "avant de commencer les annotations."]
    },
    'reset-sag-diseases': {
        'en': 'Reset sagittal diseases',
        'fr': 'Réinitialiser les pathologies sagittales'
    },
    'reset-ax-diseases': {
        'en': 'Reset axial diseases',
        'fr': 'Réinitialiser les pathologies axiales'
    }
}

export const valuesNameTrad = {
    'anteroretro': {
        'sub_values_title': {'en': 'Nature of spondylolisthesis', 'fr': 'Nature du spondylolisthesis'},
        'sub_values': {'en': ["degenerative?", "isthmic lysis?"], 'fr': ["dégénérative ?", "lyse isthmic ?"]},
        'sub_valuesvalues':{
            'en': [{'label': "NA", 'value': "x"}, {'label': "no", 'value': "0"}, {'label': "yes", 'value': "1"}],
            'fr': [{'label': "NA", 'value': "x"}, {'label': "non", 'value': "0"}, {'label': "oui", 'value': "1"}]
        }
    },
    'herniation': {
        'values': {
            'en': ["Normal", "Symmetric bulging", "Asymmetric bulging", "Protrusion", "Extrusion"],
            'fr': ["Normal", "Bombement symétrique", "Bombement asymétrique", "Protrusion", "Extrusion"]
        },
        'sub_values': [
            {
                "name": "Transversal localization",
                "acceptable_values" : ['2']
            },
            {
                "name": "Transversal localization",
                "acceptable_values" : ['3','4']
            },
            {
                "name": {'en': "Transligamentous?", 'fr': 'Transligamentaire ?'},
                "acceptable_values" : ['3','4'],
                "choices": {
                    'en': ["subligamentous", "trans/extraligamentous"],
                    'fr': ["sous-ligamentaire", "trans/extraligamentaire"]
                },
                "position" : 2,
                "backdrop": 2
            },
            {
                "name": "Sagittal localization",
                "acceptable_values" : ['4'],
            },
            {
                "name": {'en': "Sequestrated?", 'fr': 'Exclusion ?'},
                "acceptable_values" : ['4'],
                "choices": {'en': ["no", "probable", "yes"], 'fr': ["non", "probable", "oui"]},
                "position" : 4,
                "backdrop": 4
            },
            {
                "name": "Conflict",
                "acceptable_values" : ['1', '2', '3','4'],
            }
        ],
        'trans_loc_1': {
            'title': {'en': 'Transversal localization', 'fr': 'Localisation transversale'},
            'values': {
                'en': [['anterior', 'posterior'], ['left', 'right']],
                'fr': [['antérieure', 'postérieure'], ['gauche', 'droite']]
            }
        },
        'trans_loc_2': {
            'title': {'en': 'Transversal localization', 'fr': 'Localisation transversale'},
            'values': {
                'en': ['central canal', 'subarticular', 'foraminal', 'extraforaminal'],
                'fr': ['canal central', 'sous-articulaire', 'foraminale', 'extraforaminale']
            }
        },
        'trans_loc_2_spec': {
            'en': ['left', 'central', 'right'],
            'fr': ['gauche', 'centrale', 'droite']
        },
        'sag_loc': {
            'title': {'en': 'Sagittal localization', 'fr': 'Localisation sagittale'},
            'values': {
                'en': ["cranial", "discal", "caudal"],
                'fr': ["craniale", "discale", "caudale"]
            }
        },
        "conflict": {
            'title': {'en': 'Nerve root impingement?', 'fr': 'Conflit disco-radiculaire ?'},
            'values': {
                'en': ["NA", "no", "yes"],
                'fr': ["NA", "non", "oui"]
            }
        }
    },
    'fracture': {
        'reset-values': {'en': 'No', 'fr': 'Non'},
        'values': {'en': ['No', 'Yes'], 'fr': ['Non', 'Oui']},
        'sub_values_title': {'en': 'edema: ', 'fr': 'oedème : '},
        'sub_values': {
            'en': [{
                key: 0,
                label: 'No',
                value: '10'
            }, {
                key: 1,
                label: 'Yes',
                value: '11'
            }],
            'fr': [{
                key: 0,
                label: 'Non',
                value: '10'
            }, {
                key: 1,
                label: 'Oui',
                value: '11'
            }]
        }
    },
    'scs': {
        'reset-values': {'en': 'Normal', 'fr': 'Normal'},
        'values': {
            'en': ["A","B","C","D"],
            'fr': ["A","B","C","D"]
        },
        'column_values': {
            'en': ["Normal", "Mild", "Moderate", "Severe"],
            'fr': ["Normal", "Léger", "Modéré", "Sévère"]
        },
        'sub_values': {
            'en': ["Disc herniation", 'Disc bulging', "Enlargement of the facet joints", "Ligamentum flavum hypertrophy", "Articular cysts", "Spondylolisthesis", "Lipomatosis", "Others"],
            'fr': ["Hernie discale", 'Bombement discal', "Élargissement des articulations des facettes", "Hypertrophie des ligaments jaunes", "Kystes articulaires", "Spondylolisthesis", "Lipomatose", "Autre(s)"]
        }
    },
    'lrs': {
        'reset-values': {'en': 'No', 'fr': 'Non'},
        'left-right': {
            'en': ['Left', 'Right'],
            'fr': ['Gauche', 'Droite']
        },
        'values': {
            'en': ['No', 'Contact', 'Compression'],
            'fr': ['Non', 'Contact', 'Compression']
        }
    },
    'nfs': {
        'reset-values': {'en': 'No', 'fr': 'Non'},
        'left-right': {
            'en': ['Left', 'Right'],
            'fr': ['Gauche', 'Droite']
        },
        'values': {
            'en': ['No', 'Mild', 'Moderate', 'Severe'],
            'fr': ['Non', 'Léger', 'Modéré', 'Sévère']
        },
        'sub_values': {
            'en': ["Disc herniation", 'Disc bulging', "Abnormal curve of the spine", "Thickened ligaments", "Bone spurs", "Articular cysts", "Spondylolisthesis", "Others"],
            'fr': ["Hernie discale", 'Bombement discal', "Courbure anormale de la colonne", "Epaississement des ligaments", "Ostéophytes", "Kystes articulaires", "Spondylolisthesis", "Autre(s)"]
        }
    },
    'trans_vb': {
        'reset-values': {'en': 'No', 'fr': 'Non'},
        'sub_values_title': {'en': 'inflammatory?', 'fr': 'inflammatoire ? '},
        'sub_values': {
            'en': [{
                key: 0,
                label: 'No',
                value: '10'
            }, {
                key: 1,
                label: 'Yes',
                value: '11'
            }],
            'fr': [{
                key: 0,
                label: 'Non',
                value: '10'
            }, {
                key: 1,
                label: 'Oui',
                value: '11'
            }]
        }
    },
    'endplate': {
        'reset-values': {'en': 'No', 'fr': 'Non'},
        'values': {'en': ["No", "Yes"], 'fr': ["Non", "Oui"]},
        'sub_values_title': {'en': 'Origin', 'fr': 'Origine'},
        'sub_values': {
            'en': ["Endplate irregularity", "Schmorl node", "Endplate fracture"],
            'fr': ["Irrégularité du plateau", "Nodule de schmorl", "Fracture du plateau"]
        }
    },
    'facet-arth': {
        'reset-values': {'en': 'No', 'fr': 'Non'},
        'left-right': {
            'en': ['Left', 'Right'],
            'fr': ['Gauche', 'Droite']
        },
        'values': {
          'en': ["No", null, "Moderate", "Severe"],
          'fr': ["Non", null, "Modéré", "Sévère"]
        },
        'sub_values': {
            'en': ["Effusion", "Periarticular edema", "Osseous edema", "Synovial cysts", "Subchondral cysts",
                "Subchondral irregularity", "Osteophytes"],
            'fr': ["Epanchement", "Œdème périarticulaire", "Œdème osseux", "Kystes synoviaux", "Kystes sous-chondraux",
                "Irrégularité sous-chondrale", "Ostéophytes"]
        },
        'sub_details': {
            'en': [
                {'title': 'size:', 'choices': ['mild', 'moderate', 'severe']},
                {'title': 'endocanalar?', 'choices': [{'label':'NA', 'value': 'x'}, {'label':'yes', 'value': '1'}, {'label':'no', 'value': '0'}]}
            ],
            'fr': [
                {'title': 'taille :', 'choices': ['petits', 'moyens', 'grands']},
                {'title': 'endocanalaire ?', 'choices': [{'label':'NA', 'value': 'x'}, {'label':'oui', 'value': '1'}, {'label':'non', 'value': '0'}]}
            ]
        }
    },
    'facet-edema': {
        'reset-values': {'en': 'No', 'fr': 'Non'},
        'values': {
            'en': ["No", "Yes"],
            'fr': ["Non", "Oui"]
        },
    },
    'muscles': {
        'left-right': {
            'en': [{'id': 0, 'label': 'Left', 'color': "var(--dark-blue)"}, {'id': 2, 'label': 'Right', 'color': "var(--gold)"}],
            'fr': [{'id': 0, 'label': 'Gauche', 'color': "var(--dark-blue)"}, {'id': 2, 'label': 'Droite', 'color': "var(--gold)"}]
        }
    }
}

export const diseasesNameTrad = {
    'pfirrmann': {'en':'Disc degeneration (Pfirrmann)','fr': 'Dégénérescence des disques (Pfirrmann)'},
    'modic': {'en': 'Modic type endplate changes', 'fr': 'Dégénérescence des plateaux (Modic)'},
    'anteroretro': {'en':'Anterolisthesis-Retrolisthesis', 'fr': 'Anterolisthesis-Retrolisthesis'},
    'fracture': {'en': 'Compression fracture', 'fr': 'Fracture de compression'},
    'herniation': {'en': 'Disc herniation', 'fr': 'Hernie discale'},
    'nfs': {'en': 'Neural foraminal stenosis', 'fr': 'Sténose foraminale'},
    'scs': {'en': 'Spinal canal Stenosis', 'fr': 'Sténose du canal central'},
    'lrs': {'en': 'Lateral recess Stenosis', 'fr': 'Sténose du récessus latéral'},
    'facet-art': {'en': 'Facet arthropathy', 'fr': 'Arthropathie des facettes'},
    'facet-edema': {'en': 'Facet edema', 'fr': 'Oedème des facettes'},
    'trans-vb': {'en': 'Transitional vertebrae', 'fr': 'Vertèbre transitionnelle'},
    'endplate': {'en': 'Endplate defects', 'fr': 'Défauts des plateaux'},
    'multifidus': {'en': 'Multifidus fatty involution', 'fr': 'Infiltration graisseuse du multifide'},
    'psoas': {'en': 'Psoas fatty involution', 'fr': 'Infiltration graisseuse du psoas'},
    'erector-spinae': {'en': 'Erector spinae fatty involution', 'fr': 'Infiltration graisseuse des érecteurs du rachis'},
    'spinalmuscles': {'en': 'Spinal muscles fatty involution', 'fr': 'Infiltration graisseuse des muscles spinaux'},
}

export const helpTrad = {
    'pfirrmann': {
        'title': {
            'en': "Disc degeneration by Pfirrmann grade",
            'fr': "Dégénérescence des disques caractérisé par l'échelle de Pfirrmann"
        }
    },
    'modic': {
        'title': {
            'en': "Modic type endplate changes",
            'fr': "Dégénérescence des plateaux (Modic)"
        },
        'definition': {
            'en': 'Classification of degenerative marrow changes surrounding a dehydrated lumbar disk.¹',
            'fr': 'Classification des modifications dégénératives de la moelle entourant un disque lombaire déshydraté¹.'
        }
    },
    'anteroretro': {
        'title': {
            'en': "Anterolisthesis Retrolisthesis",
            'fr': "Antérolisthesis Rétrolisthesis"
        },
        'definition': {
            'en': "Anterolisthesis (respectively retrolisthesis) is a forward (respectively backward) displacement of a vertebra.<br/>" +
                "This displacement is generally graduated according to the Meyerding classification.<br/>" +
                "The grade percent is determined by drawing a line through the <span className='help-red'>posterior wall</span> of the superior and inferior vertebral bodies" +
                "and measuring the <span className='help-red'>translation of the superior vertebral body</span> as a percentage of the distance between the two lines.",
            'fr': "L'antérolisthésis (respectivement le rétrolisthésis) est un déplacement vers l'avant (respectivement vers l'arrière) d'une vertèbre.<br/>" +
                "Ce déplacement est généralement gradué selon la classification de Meyerding.<br/>" +
                "Le pourcentage du grade est déterminé en traçant une ligne à travers le <span className='help-red'>mur postérieur</span> des corps vertébraux supérieur et inférieur" +
                "et en mesurant <span className='help-red'>la translation du corps vertébral supérieur</span> en pourcentage de la distance entre les deux lignes."
        },
        'isthmic': {
            'title': {'en': "Isthmic lysis", 'fr': "Isthmic lysis"},
            'definition': {
                'en': "Spondylolysis is a loss of continuity of the articular isthmus (pars interarticularis), located between the two superior and inferior articular processes of the posterior vertebral arch.",
                'fr': "La spondylolyse est une perte de continuité de l'isthme articulaire (pars interarticularis), situé entre les deux processus articulaires supérieur et inférieur de l'arc vertébral postérieur."
            }
        }
    },
    'nfs': {
        'title': {
            'en': "Neural Foraminal Stenosis",
            'fr': "Sténose foraminale"
        },
        'definition': {
            'en': 'Neural foraminal stenosis refers to compression of a spinal nerve as it leaves the spinal canal through the foramen.',
            'fr': 'La sténose foraminale correspond à la compression d\'un nerf rachidien lorsqu\'il quitte le canal rachidien par le foramen.'
        }
    },
    'endplate-defects': {
        'title': {'en': "Endplate defects", 'fr': "Défauts des plateaux"},
        'definition': {'en': 'May have 2 of the three conditions listed.', 'fr': 'Peut présenter deux des trois conditions énumérées.'}
    },
    'scs': {
        'title': {'en': "Spinal Canal Stenosis", 'fr': "Sténose du canal central"},
        'definition': {
            'en': "Spinal Canal stenosis refers to compression of a spinal nerve in the spinal canal.",
            'fr': "La sténose du canal central correspond à la compression d'un nerf rachidien dans le canal rachidien."
        }
    },
    'lrs': {
        'title': {'en': "Lateral Recess Stenosis", 'fr': "Sténose du récessus latéral"},
        'definition': {
            'en': "Lateral Recess stenosis refers to compression of a spinal nerve in the lateral recess. It occurs when the spinal nerve is compressed just before it reaches the intervertebral foramen.",
            'fr': "La sténose du récessus latéral correspond à la compression d'un nerf rachidien dans le récessus latéral. Elle se produit lorsque le nerf rachidien est comprimé juste avant d'atteindre le foramen intervertébral."
        }
    },
    'herniation': {
        'title': {'en': "Disc Herniation", 'fr': "Hernie discale"},
        'trans_loc': {
            'title': {'en': "Transversal localization", 'fr': "Localisation transversale"},
            'definition': {
                'en': "Alphanumeric system to classify, according to location, the position of disc fragments that have " +
                    "migrated in the horizontal plane.<br/>If the <b>bulging/protrusion/extrusion</b> are located " +
                    "astride 2 zones you can select 2 zones.",
                'fr': "Il s'agit de classer, en fonction de leur emplacement, la position des fragments de disque ayant " +
                    "migré dans le plan horizontal.<br/>Si <b>le bombement/la protrusion/l'extrusion</b> est situé à " +
                    "cheval sur 2 zones, vous pouvez sélectionner 2 zones."
            }
        },
        'transligamentous': {
            'title': {'en': "Posterior Longitudinal ligament status", 'fr': "Statut du ligament postérieur longitudinal"},
            'definition': {
                'en': "Refer to the position of the displaced disc material regarding Posterior Longitudinal Ligament (PLL).",
                'fr': "Se référer à la position du disque déplacé en ce qui concerne le ligament postérieur longitudinal (LPL)."
            }
        },
        'sag_loc': {
            'title': {'en': "Sagittal localization", 'fr': "Localisation sagittale"},
            'definition': {
                'en': "Alphanumeric system to classify, according to location, the position of disc fragments that " +
                    "have migrated in the sagittal plane. <br/>If the <b>extrusion</b> are located astride 2 zones you " +
                    "can select 2 zones.",
                'fr': "Il s'agit de classer, en fonction de leur emplacement, la position des fragments discaux ayant " +
                    "migré dans le plan sagittal des fragments de disque qui ont migré dans le plan sagittal.<br/>Si l'<b>extrusion</b> " +
                    "est située à cheval sur 2 zones, vous pouvez sélectionner 2 zones."
            }
        },
        'sequestrated': {
            'title': {'en': "Sequestrated", 'fr': "Exclusion"},
            'definition': {
                'en': "A sequestrated disc is an extruded disc in which the displaced disc material has lost all " +
                    "connection with the disc of origin.",
                'fr': "Un disque exclu est un disque extrudé dans lequel le matériel discal déplacé a perdu " +
                    "toute connexion avec le disque d'origine."
            }
        },
        'conflict': {
            'title': {'en': "Nerve root impingement", 'fr': "Conflit disco-radiculaire"},
        }
    },
    'facet-arth': {
        'title': {'en': "Facet Arthropathy", 'fr': 'Arthropathie des facettes'},
        'definition': {
            'en': 'Facet Arthropathy is a arthritic condition of the facet joints.',
            'fr': 'Affection arthritique des articulations des facettes.'
        }
    },
    'muscles': {
        'title': {
            'en': "Muscle fatty involution by the Goutalier Classification",
            'fr': "Infiltration graisseuse caractérisée par la classification de Goutalier"
        },
        'definition': {
            'en': "Muscle fatty involution refers to the muscle degeneration due to fat infiltration in the muscle.",
            'fr': "Classification compatible avec les 3 muscles : psoas, multifidus et erector spinae."
        }
    },
    'spinalmuscles': {
        'title': {
            'en': "Spinal muscles fatty involution by the Goutalier Classification",
            'fr': "Infiltration graisseuse caractérisée par la classification de Goutalier"
        },
        'definition': {
            'en': "",
            'fr': ""
        }
    }
}