import {Grid} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {medTrad, valuesRadiographie, valuesTreatment} from "./constants";

export function displayLine(values) {
    if (values !== null && values !== "NA" && Object.values(values).find(elt => elt !== null) !== undefined) {
        return Object.values(values).some(elt => elt !== null && elt !== "")
    }
    else return false
}

/**
 * Displays the grid for radiology/surgery categories
 *
 * @param questions {string[]}
 * @param title {string} - red title on the left column --- Previous treatment
 * @param subtitles {string[]} - blue subtitle on the left column, under the red title
 * @param keys_state {string[]}
 * @param state {boolean[][]} - ivd list, for each criterion (such as Spondylolisthesis) : true if the ivd has been selected, false otherwise
 *
 */
export const Radiology = ({questions, title, subtitles, keys_state, state}) => {
    const radio_normale = state["radio_normale"] === true ? 'Yes' : state["radio_normale"] !== undefined ? 'No' : null
    const surg_opinion = state["surg_opinion"] === "" ? "NA" : state["surg_opinion"] !== undefined ? 'Yes' : null

    const { i18n } = useTranslation()

    return <>
        <Grid container>
            <Grid item xs={6}>{title && <p className='overview-subtitles'>{title}</p>}</Grid>
            <Grid item xs={6}/>
        </Grid>
        {surg_opinion !== null && <Grid container>
            <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>{questions[0]}</h5></Grid>
            <Grid item xs={6}><p className='details_radiosurg'>{medTrad[surg_opinion][i18n.language]}</p></Grid>
        </Grid>}
        {radio_normale !== null && <Grid container>
            <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>{questions[1]}</h5></Grid>
            <Grid item xs={6}><p className='details_radiosurg'>{medTrad[radio_normale][i18n.language]}</p></Grid>
        </Grid>}
        {subtitles.map((disease, keyDisease) => {
            const vals = state[keys_state[keyDisease]] //dictionary with ivd keys
            //console.log(disease)
            if (vals !== null && (Object.values(vals).includes('0') || Object.values(vals).includes('1'))) {
                let ivd_list = ''
                Object.keys(vals).forEach(ivd => {
                    if (vals[ivd] === '1') ivd_list = ivd_list.concat(' ', ivd.replace('_', '-'))
                })

                if (disease === valuesRadiographie[i18n.language][0]) ivd_list = medTrad['Yes'][i18n.language]
                return <Grid container key={keyDisease}>
                    <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>{disease}</h5></Grid>
                    <Grid item xs={6}><p className='details_radiosurg'>{ivd_list}</p></Grid>
                </Grid>
            }
            else return null
        })}
        {state["details"] !== null && state["details"] !== "" && <Grid container>
            <Grid item xs={12}/>
            <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>
                {medTrad['Details'][i18n.language]}
            </h5></Grid>
            <Grid item xs={6}><p className='details_radiosurg'>{state["details"]}</p></Grid>
        </Grid>}
        <br/><br/>
    </>
}

/**
 *
 * @param title {string} 'Treatments' or 'Treatment'
 * @param keys_state {string[]}
 * @param state {Object.<string,?(number|string)>}
 * @returns {JSX.Element}
 * @constructor
 */
export const Treatment = ({title, keys_state, state}) => {
    const { i18n } = useTranslation()
    return <>
        <Grid container>
            <Grid item xs={6}>{title && <p className='overview-subtitles'>{title}</p>}</Grid>
            <Grid item xs={6}/>
        </Grid>
        {keys_state.map((drug, keyDrug) => {
            if (state[drug] !== null && state[drug] !== "") return <Grid container key={keyDrug}>
                {drug === 'Details' && <Grid item xs={12}/>}
                <Grid item xs={6}><h5 className='overview-titles' style={{lineHeight: '16px', marginTop: 0}}>
                    {valuesTreatment[drug][i18n.language]}
                </h5></Grid>
                <Grid item xs={6}><p className='details_radiosurg'>
                    {medTrad[state[drug]] !== undefined ? medTrad[state[drug]][i18n.language] : state[drug]}
                </p></Grid>
            </Grid>
            else return null
        })}
        <br/><br/>
    </>
}