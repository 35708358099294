import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import {Grid} from "@mui/material";


export const NoImageBox = ({btn_font_size, handleNewMod}) => {
    return <div style={{height: '100%', width: '100%'}}>
        <Grid container className='tools'>
            <Grid item xs='auto' style={{height: '100%'}}>
                <button><ZoomInIcon fontSize={btn_font_size}/></button>
                <button><ZoomOutIcon fontSize={btn_font_size}/></button>
                <button><SettingsBackupRestoreIcon fontSize={btn_font_size}/></button>
                <button onClick={() => handleNewMod('back')}><ArrowBackIosNewIcon fontSize={btn_font_size}/></button>
                <button onClick={() => handleNewMod('next')}><ArrowForwardIosIcon fontSize={btn_font_size}/></button>
            </Grid>
        </Grid>
        <p>Loading...</p>
    </div>
}