import React from 'react'
import './Disease.css'
import {SagDiseases} from "./SagDiseases";
import {AxDiseases} from "./AxDiseases";
import {DiseasesBtn} from "../utils/DiseasesButtons";
import {useTranslation} from "react-i18next";

const ax_ids = [{'id': 4, 'label': 'herniation'}, {'id': 5, 'label': 'scs'}, {'id': 7, 'label': 'lrs'},
    {'id': 9, 'label': 'facet'}, {'id': 12, 'label': 'multifidus'}, {'id': 13, 'label': 'psoas'},
    {'id': 14, 'label': 'erectorspinae'}, {'id': 15, 'label': 'facet-edema'}, {'id': 16, 'label': 'spinalmuscles'}]
const sag_ids = [{'id': 0, 'label': 'pfirrmann'}, {'id': 1, 'label': 'modic'}, {'id': 3, 'label': 'anteroretro'},
    {'id': 8, 'label': 'fracture'}, {'id': 6, 'label': 'nfs'}, {'id': 10, 'label': 'trans_vb'},
    {'id': 11, 'label': 'endplate'}]

const getValues = (values, ax_sag) => {
    const result = {}
    if (ax_sag === 'ax') ax_ids.forEach(elt => result[elt['label']] = values[elt['id']])
    else sag_ids.forEach(elt => result[elt['label']] = values[elt['id']])
    return result
}

export const Diseases = ({patient_id, values, color, handleCheck, handleResetDisease, handleClearDisease,
                             handleResetDisc, handleResetSagAx}) => {
    const [displayPatho, setDisplayPatho] = React.useState(null)
    const [displaySagAx, setDisplaySagAx] = React.useState(null)

    const handlePathoClick = (patho_name) => {
        if (displayPatho === patho_name) setDisplayPatho(null)
        else setDisplayPatho(patho_name)
    }

    const handleSagAxClick = (new_val) => {
        if (displaySagAx === new_val) setDisplaySagAx(null)
        else setDisplaySagAx(new_val)
    }

    const resetSagAx = (sag_ax) => {
        let selection = window.confirm("Reset all "+sag_ax+" annotations?");
        if (selection) {
            if (sag_ax === 'sag') sag_ids.forEach(elt => handleResetSagAx(elt.id))
            else if (sag_ax === 'ax') ax_ids.forEach(elt => handleResetSagAx(elt.id))
        }
    }

    const { t } = useTranslation()

    React.useEffect(() => {
        setDisplayPatho(null)
    }, [patient_id])

    return <div className='disease'>
        {Object.values(getValues(values, 'sag')).find(elt => elt !== null) !== undefined && <>
            <DiseasesBtn onClick={() => handleSagAxClick('sagittal')}>
                {t('annot-btns-sag').toUpperCase()}
            </DiseasesBtn>
            {displaySagAx === 'sagittal' && <SagDiseases
                sag_values={getValues(values, 'sag')}
                sag_colors={getValues(color, 'sag')}
                display={displayPatho}
                handleClick={handlePathoClick}
                handleCheck={handleCheck}
                handleResetSagDiseases={() =>  resetSagAx('sag')}
                resetDisease={handleResetDisease}
                clearDisease={handleClearDisease}
                resetDisc={handleResetDisc}
            />}
        </>}
        {Object.values(getValues(values, 'ax')).find(elt => elt !== null) !== undefined && <>
            <DiseasesBtn onClick={() => handleSagAxClick('axial')}>
                {t('annot-btns-ax').toUpperCase()}
            </DiseasesBtn>
            {displaySagAx === 'axial' && <AxDiseases
                ax_values={getValues(values, 'ax')}
                ax_colors={getValues(color, 'ax')}
                display={displayPatho}
                handleClick={handlePathoClick}
                handleCheck={handleCheck}
                handleResetAxDiseases={() =>  resetSagAx('ax')}
                resetDisease={handleResetDisease}
                clearDisease={handleClearDisease}
                resetDisc={handleResetDisc}
            />}
        </>}
    </div>
}