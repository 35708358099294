import React from 'react'
import './Help.css'
import parse from 'html-react-parser';

export const Base = props => {
    return <div className='help'>
        <h1>{props.title}</h1>
        {props.definition && <p className='help-definition'>{parse(props.definition)}</p>}
        <div className='help-content'>
            {props.content}
        </div>
        {props.references && <p className='help-references'>
            {props.references.map((ref, keyRef) => {
            return <span key={keyRef}>{ref}<br/></span>
        })}</p>}
    </div>
}