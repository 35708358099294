import React from 'react'
import '../Disc.css'
import {createTheme, Grid} from "@mui/material";
import {discs} from "../items";
import {DropdownIcon, getNewSubValues, handleValuesChange, MainValues, NotSureResetBtn, SubValues} from "../utils";

export default class SpinalCanalStenosisModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursor: null
        }
    }

    handleValuesChange = (curr_val, disc, new_val) => {
        this.setState({cursor: disc})
        this.props.handleCheck(disc, handleValuesChange(curr_val === 'WARN' ? '100': curr_val, new_val, this.props.sub_choices.length))
    }

    handleSubValuesChange = (curr_val, disc, position) => {
        const sub_vals = curr_val.split(',').slice(1)
        if (sub_vals.length > position) this.props.handleCheck(disc, getNewSubValues(curr_val, position))
        else alert('An error occured, please contact Caerus team')
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    handleDropdown = (curr_val, disc) => {
        if (this.state.cursor === disc) this.setState({cursor: null})
        else if (curr_val !== "0" && curr_val !== "100") this.setState({cursor: disc})
    }

    render() {
        const theme = createTheme({typography: {fontSize: 13}})
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) {
                const is_sub_vals = curr_val !== "WARN" && curr_val !== "100" && curr_val[0] !== "0"
                const sub_vals = curr_val.slice(2).replace('?', '').split(',')
                return <Grid key={keyDisc} container justify="center" alignItems="center" style={{backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    <Grid item xs={1} className="dropdown-icon-grid">
                        {is_sub_vals && <DropdownIcon
                            up_down={this.state.cursor === keyDisc}
                            onClick={() => this.handleDropdown(curr_val, keyDisc)}
                        />}
                    </Grid>
                    <Grid item xs={true}>
                        <MainValues
                            theme={theme}
                            curr_val={curr_val}
                            values={this.props.choices}
                            size={3}
                            handleValuesChange={(keyValue) => this.handleValuesChange(curr_val, keyDisc, keyValue.toString())}
                        />
                    </Grid>
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                    {is_sub_vals && this.state.cursor === keyDisc && <Grid container justify="center" alignItems="center">
                        <SubValues
                            xs={12}
                            choices={this.props.sub_choices}
                            sub_color={sub_vals.includes('1') ? 'var(--dark-blue)' : 'crimson'}
                            sub_vals={sub_vals}
                            handleChange={(keyValue) => this.handleSubValuesChange(curr_val, keyDisc, keyValue)}
                        />
                    </Grid>}
                </Grid>
            }
            else return null
        })
    }


}