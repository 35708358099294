import {Grid} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {valuesNameTrad} from "../../../../../../../utils/traductions";
import {CheckboxBtnGrid, RadioBtnGrid} from "../utils";

const left_right = {'en': ['left', 'right'], 'fr': ['gauche', 'droite']}

export const Conflict = ({values, size, ivd, language, handleChange, handleRadioConflict, handleBackdrop}) => {
    const color = values.includes('1') ? 'var(--dark-blue)' : 'crimson'
    const title = valuesNameTrad['herniation']['conflict']['title'][language]
    const choices = valuesNameTrad['herniation']['conflict']['values'][language]

    return <Grid item xs={size} style={{margin: '7px auto'}}>
        <Grid container justify="center" alignItems="center">
            <Grid item xs={6}>
                <p style={{color: "white", fontSize: "var(--medium-font-size)", cursor: "help"}} onClick={handleBackdrop}>{title}</p>
            </Grid>
            {choices.map((choice, keyChoice) => {
                const checked = (
                    (values.length === 1 && ((values[0] === 'x' && keyChoice === 0) || (values[0] === '0' && keyChoice === 1)))
                    || (values.length > 1 && keyChoice === 2)
                )
                return <Grid item xs={2} key={keyChoice}>
                    <RadioBtnGrid
                        color={(values.length === 1 && values[0] === '0') || values.length > 1 ? 'var(--dark-blue)' : 'crimson'}
                        value={choice}
                        checked={checked}
                        onChange={() => handleRadioConflict(keyChoice)}
                    />
                </Grid>
            })}
        </Grid>
        <Grid container justify="center" alignItems="center">
            {values.length > 1 && ivd.map((disc, keyDisc) => {
                return left_right[language].map((side, keySide) => {
                    const name = `${disc} ${side}`
                    const id = 2*keyDisc + keySide
                    return <Grid item xs={3} key={name}>
                        <CheckboxBtnGrid
                            color={color}
                            value={name}
                            checked={values[id] === "1"}
                            onChange={() => handleChange(id)}
                        />
                    </Grid>
                })
            })}
        </Grid>
    </Grid>
}

export const Transligamentous = ({xs, title, choices, checked, handleChange, handleBackdrop}) => {
    const color = checked === "x" ? 'crimson' : 'var(--dark-blue)'
    return <Grid item xs={xs}>
        <Grid container justify="center" alignItems="center">
            <p style={{color: "white", fontSize: "var(--medium-font-size)", cursor: "help"}} onClick={handleBackdrop}>{title}</p>
        </Grid>
        <Grid container justify="center" alignItems="center">
            <RadioBtnGrid
                color={color}
                value='NA'
                checked={checked === "x"}
                onChange={() => handleChange("x")}
            />
            {choices.map((choice, keyChoice) => {
                return <RadioBtnGrid
                    key={keyChoice}
                    color={color}
                    value={choice}
                    checked={checked === keyChoice.toString()}
                    onChange={() => handleChange(keyChoice)}
                />
            })}
        </Grid>
    </Grid>
}

export const TransversalLocalizationV1 = ({values, handleChange, handleBackdrop}) => {
    const color = values.includes('1') ? 'var(--dark-blue)' : 'crimson'

    const { i18n } = useTranslation()
    const title = valuesNameTrad['herniation']['trans_loc_1']['title'][i18n.language]
    const trans_loc = valuesNameTrad['herniation']['trans_loc_1']['values'][i18n.language]

    return <Grid container>
        <Grid item xs={3}/>
        <Grid item xs={6}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                    <p style={{color: "white", fontSize: "var(--medium-font-size)", cursor: 'help'}} onClick={handleBackdrop}>{title}</p>
                </Grid>
                <Grid item xs={6}>
                    {trans_loc[0].map((sub_value, keySubValue) => {
                        return <CheckboxBtnGrid
                            key={keySubValue}
                            color={color}
                            value={sub_value}
                            checked={values[keySubValue] === "1"}
                            onChange={() => handleChange(keySubValue)}
                        />
                    })}
                </Grid>
                <Grid item xs={6}>
                    {trans_loc[1].map((sub_value, keySubValue) => {
                        return <CheckboxBtnGrid
                            key={keySubValue}
                            color={color}
                            value={sub_value}
                            checked={values[keySubValue+2] === "1"}
                            onChange={() => handleChange(keySubValue+2)}
                        />
                    })}
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={3}/>
    </Grid>
}

export const TransversalLocalizationV2 = ({values, size, handleRadioChange, handleChange, handleBackdrop}) => {
    const handleClick = (value) => {
        if (value === 0 && values[0] !== "1" && values[1] !== "1" && values[2] !== "1" && values[3] !== "1") handleRadioChange(1)
        handleChange(value)
    }

    const color = values.slice(0,4).includes('1') ? 'var(--dark-blue)' : 'crimson'

    const { i18n } = useTranslation()
    const title = valuesNameTrad['herniation']['trans_loc_2']['title'][i18n.language]
    const trans_loc = valuesNameTrad['herniation']['trans_loc_2']['values'][i18n.language]
    const trans_loc_spec = valuesNameTrad['herniation']['trans_loc_2_spec'][i18n.language]

    return <Grid item xs={size}>
        <Grid container justify="center" alignItems="center">
            <p style={{color: "white", fontSize: 'var(--medium-font-size)', cursor: 'help'}} onClick={handleBackdrop}>{title}</p>
        </Grid>
        <Grid container justify="center" alignItems="center">
            <Grid item xs={6}>
                {trans_loc.map((sub_value, keySubValue) => {
                    return <CheckboxBtnGrid
                        key={keySubValue}
                        color={color}
                        value={sub_value}
                        checked={values[keySubValue] === "1"}
                        onChange={() => handleClick(keySubValue)}
                    />
                })}
            </Grid>
            <Grid item xs={6}>
                <RadioBtnGrid
                    color={values[4] === "x" ? 'crimson' : 'var(--dark-blue)'}
                    value="NA"
                    checked={values[4] === "x"}
                    onChange={() => handleRadioChange("x")}
                />
                {trans_loc_spec.map((sub_value, keySubValue) => {
                    return <RadioBtnGrid
                        key={keySubValue}
                        color={values[4] === "x" ? 'crimson' : 'var(--dark-blue)'}
                        value={sub_value}
                        checked={values[4] === keySubValue.toString()}
                        onChange={() => handleRadioChange(keySubValue)}
                    />
                })}
            </Grid>
        </Grid>
    </Grid>
}

export const SagittalLocalization = ({values, size, handleChange, handleBackdrop}) => {
    const color = values.includes('1') ? 'var(--dark-blue)' : 'crimson'

    const { i18n } = useTranslation()
    const title = valuesNameTrad['herniation']['sag_loc']['title'][i18n.language]
    const sag_loc = valuesNameTrad['herniation']['sag_loc']['values'][i18n.language]

    return <Grid item xs={size}>
        <Grid container justify="center" alignItems="center">
            <p style={{color: "white", fontSize: "var(--medium-font-size)", cursor: 'help'}} onClick={handleBackdrop}>{title}</p>
        </Grid>
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
                {sag_loc.map((sub_value, keySubValue) => {
                    return <CheckboxBtnGrid
                        key={keySubValue}
                        color={color}
                        value={sub_value}
                        checked={values[keySubValue] === "1"}
                        onChange={() => handleChange(keySubValue)}
                    />
                })}
            </Grid>
        </Grid>
    </Grid>
}