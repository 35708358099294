import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const DiseasesBtn = withStyles({
    root: {
        marginTop: "5px",
        textDecoration: "none",
        backgroundColor: 'var(--dark-gold)',
        border : '2px solid var(--dark-gold)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--dark-gold)',
        },
        '&:disabled': {
            backgroundColor: 'var(--light-blue)',
            border : '2px solid var(--light-blue)',
        },
        color: "white"
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);