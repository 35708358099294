import http from "../http-common"

/**
 * Renvoie la liste des patients a annoter pour chaque chirurgien
 *
 * @param cancelToken
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAnnot = async (cancelToken) => {
    return await http.get(`/core/annot/`, {cancelToken: cancelToken})
        .then(annotations => annotations.data)

}

/**
 * Renvoie la liste des annotations de chaque patient (disease+disc) + la liste des pathologies à afficher
 *
 * @param patient_id
 * @param cancelToken
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAnnotPerPatient = async (patient_id, cancelToken) => {
    return await http.get(`/core/annot/${patient_id}/`, {cancelToken: cancelToken})
        .then(annotations => annotations.data)
        .catch(thrown => {
            //if (axios.isCancel(thrown)) console.log('Request canceled')
        })
}

const updateAnnotation = (patient_id, disease_id, disc_data) => {
    return http.put(`/core/annot/${patient_id}/${disease_id}/`, disc_data)
}

const getMatchingData = (patient_id, serie_id_sag, slice_sag_id) => {
    return http.get(`/core/matching/${patient_id}/${serie_id_sag}/${slice_sag_id}/`)
        .then(data => data.data)
}

const getMatchingDataSag = (patient_id, serie_id_ax, slice_ax_id) => {
    return http.get(`/core/matching_sag/${patient_id}/${serie_id_ax}/${slice_ax_id}/`)
        .then(data => data.data)
}

export  {getAnnot, getAnnotPerPatient, updateAnnotation, getMatchingData, getMatchingDataSag}