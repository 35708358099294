import React from 'react'
import './WindowsManagement.css'
import {getImageBlob} from "../../../../../services/ImageService"
import {getMatchingData, getMatchingDataSag} from "../../../../../services/AnnotationService";
import {axials, resetBlobs, resetModalities, resetSlices} from "./items";
import Image from "./Image";
import {matching_view2, matching_view3} from "./utils";

class WindowManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blobs: {...resetBlobs},
            matching: {},
            modalities: {...resetModalities},
            slices: {...resetSlices},
            finished: false,

            slope_view2: {
                'sag': {0: null, 1: null},
                'ax': {0: null, 1: null}
            },

            slope_view3: {
                'sag': {0: null, 1: null, 2: null},
                'ax': {0: null, 1: null, 2: null}
            },

            image_size: null,
            link : {
                0: true,
                1: false,
                2: false
            },
        }

        const AbortController = window.AbortController;
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    revokeBlobURLs() {
        const previous_blobs = {...this.state.blobs}
        for (let mod in previous_blobs) {for (let slice in previous_blobs[mod]) {URL.revokeObjectURL(previous_blobs[mod][slice])}}
        this.setState({blobs: resetBlobs})
    }

    updateSlopeDictView3 = (window_id, mod, data) => {
        const sag_ax_values = {...this.state.slope_view3[mod], [window_id]: data}
        const window_values = {...this.state.slope_view3, [mod]: sag_ax_values}
        this.setState({slope_view3: window_values})
    }
    testView3 = async (window_id, mod) => {
        if (mod === 'sag') {
            const match = this.matchingView3(window_id, mod)
            if (match) return await getMatchingData(this.props.patient_id, match[2], match[3])
                .then(data => this.updateSlopeDictView3(window_id, mod, data))
        } else {
            const match = this.matchingView3(window_id, mod)
            if (match) return await getMatchingDataSag(this.props.patient_id, match[2], match[3])
                .then(data => this.updateSlopeDictView3(window_id, mod, data))
        }
    }
    sagAxMatchingDataView3 = async (mod) => {
        const requests = [0, 1, 2].map(window_id => this.testView3(window_id, mod))
        await Promise.all(requests).catch(() => {})
    }
    getMatchingDataView3 = async () => {
        const sagAxRequests = ['sag', 'ax'].map(mod => this.sagAxMatchingDataView3(mod))
        await Promise.all(sagAxRequests).catch(() => {})
    }

    updateSlopeDictView2 = (window_id, mod, data) => {
        const sag_ax_values = {...this.state.slope_view2[mod], [window_id]: data}
        const window_values = {...this.state.slope_view2, [mod]: sag_ax_values}
        this.setState({slope_view2: window_values})
    }
    testView2 = async (window_id, mod) => {
        if (mod === 'sag') {
            const match = this.matchingView2(window_id, mod)
            if (match) return await getMatchingData(this.props.patient_id, match[2], match[3])
                .then(data => this.updateSlopeDictView2(window_id, mod, data))
        } else {
            const match = this.matchingView2(window_id, mod)
            if (match) return await getMatchingDataSag(this.props.patient_id, match[2], match[3])
                .then(data => this.updateSlopeDictView2(window_id, mod, data))
        }
    }
    sagAxMatchingDataView2 = async (mod) => {
        const requests = [0, 1].map(window_id => this.testView2(window_id, mod))
        await Promise.all(requests).catch(() => {})
    }
    getMatchingDataView2 = async () => {
        const sagAxRequests = ['sag', 'ax'].map(mod => this.sagAxMatchingDataView2(mod))
        await Promise.all(sagAxRequests).catch(() => {})
    }


    componentDidMount() {
        this.upload(this.props.patient_id).then(() => {}, () => this.setState({finished: false}))
        if (this.props.view_id === 1) this.handleNewModView1(0)
        else if (this.props.view_id === 2) this.handleNewModView2(0,1)
        else if (this.props.view_id === 3) this.handleNewModView3(0,1,2)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //si on change de patient mais que les nouvelles valeurs de series n ont pas encore ete chargees
        if (prevProps.patient_id !== this.props.patient_id) {
            this.revokeBlobURLs()
            this.controller.abort()
        }
        //si les nouvelles valeurs de series du nouveau patient ont ete chargees
        if (prevProps.series !== this.props.series) {
            //on reset les images
            const blobs = {...resetBlobs}
            this.setState({finished: false, blobs: blobs})
            //on met a jour les nouvelles images
            this.upload(this.props.patient_id).then(() => {}, () => this.setState({finished: false}))
        } else if (prevProps.modality !== this.props.modality || prevProps.view_id !== this.props.view_id) {
            if (this.props.view_id === 1) this.handleNewModView1(0)
            else if (this.props.view_id === 2) this.handleNewModView2(0,1)
            else if (this.props.view_id === 3) this.handleNewModView3(0,1,2)
        }
        //si la fenetre change de modalite
        if (prevState.modalities !== this.state.modalities && this.props.view_id > 1) {
            if (this.props.view_id === 2) this.getMatchingDataView2().then(() => {})
            else this.getMatchingDataView3().then(() => {})
        }
    }

    componentWillUnmount() {
        this.controller.abort()
    }

    uploadSlice = async (index_serie, index_slice) => {
        await getImageBlob(this.props.patient_id, index_serie, index_slice, this.signal)
            .then(response => response.blob())
            .then(myBlob => URL.createObjectURL(myBlob))
            .then(blob => {
                const current = {...this.state.blobs[index_serie], [index_slice]: blob}
                const blobs = {...this.state.blobs, [index_serie]: current}
                this.setState({blobs: blobs})
                //URL.revokeObjectURL(blob)
            })
    }
    uploadSerie = async (index_serie, nbr_slices) => {
        const sliceUploads = [...Array(nbr_slices).keys()].map(slice => this.uploadSlice(index_serie, slice))
        await Promise.all(sliceUploads)
    }
    upload = async () => {
        const fileUploads = this.props.series.map(serie => this.uploadSerie(serie[0], serie[1]))
        await Promise.all(fileUploads).catch(() => {})
    }

    handleNewModView1 = (value) => {
        const new_mods = {...this.state.modalities, "view_1": value}
        this.setState({modalities: new_mods})
    }
    handleNewSliceView1 = (value) => {
        const new_slices = {...this.state.slices, "view_1": value}
        this.setState({slices: new_slices})
    }
    handleNewModView2 = (value_left, value_right) => {
        const values = {"left": value_left, "right": value_right}
        const new_mods = {...this.state.modalities, "view_2": values}
        this.setState({modalities: new_mods})
    }
    handleNewSliceView2 = (window, value) => {
        const max_slice_left = this.props.series[this.state.modalities["view_2"]["left"]][1]
        const max_slice_right = this.props.series[this.state.modalities["view_2"]["right"]][1]
        const values = {
            "left": value < max_slice_left ? value : max_slice_left - 1,
            "right": value < max_slice_right ? value : max_slice_right - 1
        }
        if (!this.state.link[1]) {
            if (window === 0) {
                values["right"] = this.state.slices["view_2"]["right"]
            } else {
                values["left"] = this.state.slices["view_2"]["left"]
            }
        }
        const new_slices = {...this.state.slices, "view_2": values}
        this.setState({slices: new_slices})
    }
    handleNewModView3 = (value_left, value_right_up, value_right_down) => {
        const modalities = {...resetModalities}
        modalities["view_3"] = {...modalities["view_3"], "left": value_left, "right_up": value_right_up, "right_down": value_right_down}
        this.setState({modalities: modalities})
    }
    handleNewSliceView3 = (view, value) => {
        const values = {...this.state.slices["view_3"], "left": value, "right_up": value, "right_down": value}
        if (view !== 0) values['left'] = this.state.slices["view_3"]['left']
        if (view !== 1) values['right_up'] = this.state.slices["view_3"]['right_up']
        if (view !== 2) values['right_down'] = this.state.slices["view_3"]['right_down']
        const new_slices = {...this.state.slices, "view_3": values}
        this.setState({slices: new_slices})
    }

    //return [axial_id, ax_slice_id, sag_serie_id, sag_slice_id] if a matching possibility is found, null otherwise
    matchingView2(window_id, sag_ax) {
        const mods = this.state.modalities["view_2"]
        const series_values = [this.props.series[mods["left"]][0], this.props.series[mods["right"]][0]]
        const result = matching_view2(window_id, sag_ax, series_values,
            this.state.slices["view_2"]["left"],
            this.state.slices["view_2"]["right"]
        )
        return result
    }

    matchingView3 = (window_id, sag_ax) => {
        const mods = this.state.modalities["view_3"]
        const series_values = [this.props.series[mods["left"]][0], this.props.series[mods["right_up"]][0], this.props.series[mods["right_down"]][0]]
        return matching_view3(window_id, sag_ax, series_values,
            this.state.slices["view_3"]["left"],
            this.state.slices["view_3"]["right_up"],
            this.state.slices["view_3"]["right_down"]
        )
    }

    /**
     * Returns [top, slope, nb_pixels] if a matching possibility is found, null otherwise
     *
     * @param {number} window_id - 0 for left window, 1 for right window
     * @param {String} sag_ax - 'sag' if the window as a sagittal view, 'ax' otherwise
     * @returns {null|*}
     */
    handleMatching2(window_id, sag_ax) {
        const match = this.matchingView2(window_id, sag_ax) // ex: [14, 6] axial current mod (14) and slice (6)
        if (match === null || this.state.slope_view2 === null) return null
        else {
            const sag_ax_val = this.state.slope_view2[sag_ax]
            if (sag_ax_val !== undefined) {
                const window_val = sag_ax_val[window_id]
                if (window_val !== null) return window_val[match[0]][match[1]]
            }
        }
        return null
    }

    /**
     * Returns [top, slope, nb_pixels] if a matching possibility is found, null otherwise
     *
     * @param {number} window_id
     * @param {String} sag_ax
     * @returns {null|*}
     */
    handleMatching3(window_id, sag_ax) {
        const match = this.matchingView3(window_id, sag_ax)
        if (match === null || this.state.slope_view3 === null) return null
        else {
            const sag_ax_val = this.state.slope_view3[sag_ax]
            if (sag_ax_val !== undefined) {
                const window_val = sag_ax_val[window_id]
                if (window_val !== null) return window_val[match[0]][match[1]]
            }
        }
        return null
    }

    getImgSource(mod, slice) {
        if (this.state.blobs && this.state.blobs[this.props.series[mod][0]]) return this.state.blobs[this.props.series[mod][0]][slice]
        else return null
    }

    getProgress(mod) {
        if (this.state.blobs && this.state.blobs[this.props.series[mod][0]]) {
            return Math.round((Object.keys(this.state.blobs[this.props.series[mod][0]]).length*100)/this.props.series[mod][1])
        } else return 0
    }

    handleLink(view) {
        const new_link = {...this.state.link, [view]: !this.state.link[view]}
        this.setState({link: new_link})
    }

    render() {
        const mod_view2 = this.state.modalities["view_2"]
        const mod_view3 = this.state.modalities["view_3"]
        if ((this.props.view_id === 1 || this.props.series.length === 1) && this.state.modalities["view_1"] !== null) return <div className='one-win-all'>
            <Image
                id={0}
                view_id={1}
                classname="one-win"
                slice={this.state.slices["view_1"]}
                img_source={this.getImgSource(this.state.modalities["view_1"], this.state.slices["view_1"])}
                series={this.props.series}
                modal={this.state.modalities["view_1"]}
                progress={this.getProgress(this.state.modalities["view_1"])}
                handleNewSlice={this.handleNewSliceView1}
                handleNewMod={this.handleNewModView1}
            />
        </div>
        else if (this.props.view_id === 2 && mod_view2["left"] !== null && mod_view2["right"] !== null) {
            const mod_left = mod_view2["left"]
            const mod_right = mod_view2["right"]
            const sag_ax_left = axials.find(elt => elt === this.props.series[mod_left][0]) ? 'ax' : 'sag'
            const sag_ax_right = axials.find(elt => elt === this.props.series[mod_right][0]) ? 'ax' : 'sag'
            return <div className='two-win-all'>
                <Image
                    id={0}
                    view_id={2}
                    classname="two-win-left"
                    slice={this.state.slices["view_2"]["left"]}
                    other_slice={this.state.slices["view_2"]["right"]}
                    img_source={this.getImgSource(mod_left, this.state.slices["view_2"]["left"])}
                    series={this.props.series}
                    modal={mod_left}
                    progress={this.getProgress(mod_left)}
                    handleNewSlice={value => this.handleNewSliceView2(0, value)}
                    handleNewMod={value => this.handleNewModView2(value, mod_right)}
                    handleImageSize={size => this.setState({image_size: size})}
                    matching_side={sag_ax_left}
                    matching={this.handleMatching2(0, sag_ax_left)}
                />
                <Image
                    id={1}
                    view_id={2}
                    classname="two-win-right"
                    slice={this.state.slices["view_2"]["right"]}
                    other_slice={this.state.slices["view_2"]["left"]}
                    img_source={this.getImgSource(mod_right, this.state.slices["view_2"]["right"])}
                    series={this.props.series}
                    modal={mod_right}
                    progress={this.getProgress(mod_right)}
                    handleNewSlice={value => this.handleNewSliceView2(1, value)}
                    handleNewMod={value => this.handleNewModView2(mod_left, value)}
                    handleImageSize={size => this.setState({image_size: size})}
                    matching_side={sag_ax_right}
                    matching={this.handleMatching2(1, sag_ax_right)}
                    link={this.state.link[1]}
                    handleLink={() => this.handleLink(1)}
                />
            </div>
        } else if (this.props.view_id === 3 && mod_view3["left"] !== null && mod_view3["right_up"] !== null && mod_view3["right_down"] !== null) {
            const mod_left = mod_view3["left"]
            const mod_right_up = mod_view3["right_up"]
            const mod_right_down = mod_view3["right_down"]
            const sag_ax_left = axials.find(elt => elt === this.props.series[mod_left][0]) ? 'ax' : 'sag'
            const sag_ax_right_up = axials.find(elt => elt === this.props.series[mod_right_up][0]) ? 'ax' : 'sag'
            const sag_ax_right_down = axials.find(elt => elt === this.props.series[mod_right_down][0]) ? 'ax' : 'sag'
            return <div className='three-win-all'>
                <Image
                    id={0}
                    view_id={3}
                    classname="three-win-left"
                    slice={this.state.slices["view_3"]["left"]}
                    img_source={this.getImgSource(mod_left, this.state.slices["view_3"]["left"])}
                    series={this.props.series}
                    modal={mod_left}
                    progress={this.getProgress(mod_left)}
                    handleNewSlice={value => this.handleNewSliceView3(0, value)}
                    handleNewMod={value => this.handleNewModView3(value, mod_right_up, mod_right_down)}
                    matching_side={sag_ax_left}
                    matching={this.handleMatching3(0, sag_ax_left)}
                />
                <div className='three-win-all-right'>
                    <Image
                        id={1}
                        view_id={4}
                        classname="three-win-up"
                        slice={this.state.slices["view_3"]["right_up"]}
                        img_source={this.getImgSource(mod_right_up, this.state.slices["view_3"]["right_up"])}
                        series={this.props.series}
                        modal={mod_right_up}
                        progress={this.getProgress(mod_right_up)}
                        handleNewSlice={value => this.handleNewSliceView3(1, value)}
                        handleNewMod={value => this.handleNewModView3(mod_left, value, mod_right_down)}
                        matching_side={sag_ax_right_up}
                        matching={this.handleMatching3(1, sag_ax_right_up)}
                    />
                    <Image
                        id={2}
                        view_id={4}
                        classname="three-win-down"
                        slice={this.state.slices["view_3"]["right_down"]}
                        img_source={this.getImgSource(mod_right_down, this.state.slices["view_3"]["right_down"])}
                        series={this.props.series}
                        modal={mod_right_down}
                        progress={this.getProgress(mod_right_down)}
                        handleNewSlice={value => this.handleNewSliceView3(2, value)}
                        handleNewMod={value => this.handleNewModView3(mod_left, mod_right_up, value)}
                        matching_side={sag_ax_right_down}
                        matching={this.handleMatching3(2, sag_ax_right_down)}
                    />
                </div>
            </div>
        }
        else return <p>ERROR WITH VIEW ID</p>
    }
}

export default WindowManagement