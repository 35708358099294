import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import './Navbar.css'
import './Dropdown.css'
import {useTranslation} from "react-i18next";
import {LANGUAGES} from "../../i18n";
import {months} from "../../utils/traductions";

export const Navbar = props => {
    const [click, setClick] = useState(false)

    const { i18n, t } = useTranslation();

    const onChangeLang = lang_code => i18n.changeLanguage(lang_code)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    let now = new Date()
    return <nav className='navbar'>
        <div className='nav-logo'>
            <Link to='/'>
                <img className='logo-caerus' src='/images/BarSup/logo_caerus.png' alt='logo_caerus'/>
            </Link>
            <h4>{t('nav-conf')}</h4>
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <div className='menu-items'>
                <li className='item'>
                    <Link to='/' className='menu-links' onClick={closeMobileMenu}>{t('nav-home-btn')}</Link>
                </li>
                <li className='item'>
                    <Link to='/Annotations' className='menu-links' onClick={closeMobileMenu}>{t('nav-annot-btn')}</Link>
                </li>
            </div>
            <button className='logout-button' onClick={props.handle_logout}>{t('nav-logout-btn')}</button>
            <div className='select-language'>
                {LANGUAGES.map(({code, label}) => {
                    return <p key={code} onClick={() => onChangeLang(code)} className={i18n.language === code ? 'select-language-active' : ''}>
                        {code.toUpperCase()}
                    </p>
                })}
            </div>
        </ul>
        <div className='nav-info-chir'>
            <h5>CONFIDENTIAL</h5>
            <p className='name-barsup'>{props.is_surgeon ? 'Dr.' : 'Admin'} {props.name}</p>
            <p className='date-barsup'>{months[now.getMonth()][i18n.language]}, {now.getDate()} {now.getFullYear()}</p>
        </div>
        <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
    </nav>
}