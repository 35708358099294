import React from 'react'
import {Base} from "./Base";
import {helpTrad} from "../../../../../../utils/traductions";

export const PfirrmannHelp = ({language}) => {
    return <Base
        title={helpTrad['pfirrmann']['title'][language]}
        content={<img src={`/help/Pfirrmann_${language}.jpg`} alt='test'/>}
        references={["¹Pfirrmann, C., Metzdorf, A., Zanetti, M., Hodler, J. & Boos, N. Magnetic Resonance Classification of Lumbar Intervertebral Disc Degeneration. Spine 26, 1873–8 (2001)"]}
    />
}

export const ModicHelp = ({language}) => {
    return <Base
        title={helpTrad['modic']['title'][language]}
        definition={helpTrad['modic']['definition'][language]}
        content={<img src={`/help/Modic_${language}.jpg`} alt='test'/>}
        references={["¹Petersen, T., Laslett, M. & Juhl, C. Clinical classification in low back pain: best-evidence diagnostic rules based on systematic reviews. BMC Musculoskelet Disord 18, 188 (2017)",
            "²Modic, M. T., Steinberg, P. M., Ross, J. S., Masaryk, T. J. & Carter, J. R. Degenerative disk disease: assessment of changes in vertebral body marrow with MR imaging. Radiology 166, 193–199 (1988)"]}
    />
}

export const AnteroRetroHelp = ({language}) => {
    return <Base
        title={helpTrad['anteroretro']['title'][language]}
        definition={helpTrad['anteroretro']['definition'][language]}
        content={<img src={`/help/AnteroRetro_${language}.jpg`} alt='test'/>}
        references={["¹Meyerding, H. Spondylolisthesis. Surg Gynecol Obstet (1932)"]}
    />
}

export const NFStenosisHelp = ({language}) => {
    return <Base
        title={helpTrad['nfs']['title'][language]}
        definition={helpTrad['nfs']['definition'][language]}
        content={<img src={`/help/NFStenosis_${language}.png`} alt='test'/>}
        references={["¹Miskin, N. et al. Standardized Classification of Lumbar Spine Degeneration on Magnetic Resonance Imaging Reduces Intra- and Inter-subspecialty Variability. Curr Probl Diagn Radiol S0363-0188(21)00136–5 (2021) doi:10.1067/j.cpradiol.2021.08.001"]}
    />
}

export const SCStenosisHelp = ({language}) => {
    return <Base
        title={helpTrad['scs']['title'][language]}
        definition={helpTrad['scs']['definition'][language]}
        content={<img src={`/help/SCStenosis_${language}.png`} alt='test'/>}
        references={["¹Schizas, C. et al. Qualitative grading of severity of lumbar spinal stenosis based on the morphology of the dural sac on magnetic resonance images. Spine 35, 1919–1924 (2010)",
            "²Lee, G. Y. et al. A new grading system of lumbar central canal stenosis on MRI: an easy and reliable method. Skeletal Radiol. 40, 1033–1039 (2011)",
            "³Miskin, N. et al. Standardized Classification of Lumbar Spine Degeneration on Magnetic Resonance Imaging Reduces Intra- and Inter-subspecialty Variability. Curr Probl Diagn Radiol S0363-0188(21)00136–5 (2021) doi:10.1067/j.cpradiol.2021.08.001"
        ]}
    />
}

export const LRStenosisHelp = ({language}) => {
    return <Base
        title={helpTrad['lrs']['title'][language]}
        definition={helpTrad['lrs']['definition'][language]}
        content={<img src={`/help/LRStenosis_${language}.png`} alt='test'/>}
        references={["¹Miskin, N. et al. Standardized Classification of Lumbar Spine Degeneration on Magnetic Resonance Imaging Reduces Intra- and Inter-subspecialty Variability. Curr Probl Diagn Radiol S0363-0188(21)00136–5 (2021) doi:10.1067/j.cpradiol.2021.08.001"
        ]}
    />
}

export const DiscHerniationHelp = ({language}) => {
    return <Base
        title={helpTrad['herniation']['title'][language]}
        content={<img src={`/help/DiscHerniation_${language}.jpg`} alt='test'/>}
        references={["¹Fardon, D. F. et al. Lumbar disc nomenclature: version 2.0: Recommendations of the combined task forces of the North American Spine Society, the American Society of Spine Radiology and the American Society of Neuroradiology. Spine J 14, 2525–2545 (2014)"
        ]}
    />
}

export const EndplateDefectsHelp = ({language}) => {
    return <Base
        title={helpTrad['endplate-defects']['title'][language]}
        definition={helpTrad['endplate-defects']['definition'][language]}
        content={<img src={`/help/EndplateDefects_${language}.jpg`} alt='test'/>}
        references={["¹Fardon, D. F. et al. Lumbar disc nomenclature: version 2.0: Recommendations of the combined task forces of the North American Spine Society, the American Society of Spine Radiology and the American Society of Neuroradiology. Spine J 14, 2525–2545 (2014)",
            "²Yang, S. et al. Can T2-weighted Dixon fat-only images replace T1-weighted images in degenerative disc disease with Modic changes on lumbar spine MRI? Eur Radiol (2021) doi:10.1007/s00330-021-07946-2",
            "³Colombini, A. et al. Classification of endplate lesions in the lumbar spine and association with risk factors, biochemistry, and genetics. European Spine Journal 30, (2021)"
        ]}
    />
}

export const FacetArthropathyHelp = ({language}) => {
    return <Base
        title={helpTrad['facet-arth']['title'][language]}
        definition={helpTrad['facet-arth']['definition'][language]}
        content={<img src={`/help/FacetArthropathy_${language}.png`} alt='test'/>}
        references={["¹Miskin, N. et al. Standardized Classification of Lumbar Spine Degeneration on Magnetic " +
        "Resonance Imaging Reduces Intra- and Inter-subspecialty Variability. Curr Probl Diagn Radiol " +
        "S0363-0188(21)00136–5 (2021) doi:10.1067/j.cpradiol.2021.08.001"
        ]}
    />
}

export const FattyInvolutionHelp = ({language}) => {
    return <Base
        title={helpTrad['muscles']['title'][language]}
        definition={helpTrad['muscles']['definition'][language]}
        content={<img src={`/help/MuscleFattyInvolution_${language}.png`} alt='test'/>}
        references={["BATTAGLIA, Patrick J., MAEDA, Yumi, WELK, Aaron, et al. Reliability of the Goutallier " +
        "classification in quantifying muscle fatty degeneration in the lumbar multifidus using magnetic resonance " +
        "imaging. Journal of manipulative and physiological therapeutics, 2014, vol. 37, no 3, p. 190-197"]}
    />
}

export const SpinalMusclesFattyInvolutionHelp = ({language}) => {
    return <Base
        title={helpTrad['spinalmuscles']['title'][language]}
        definition={helpTrad['spinalmuscles']['definition'][language]}
        content={<img src={`/help/SpinalMusclesFattyInvolution_${language}.png`} alt='test'/>}
        references={["BATTAGLIA, Patrick J., MAEDA, Yumi, WELK, Aaron, et al. Reliability of the Goutallier " +
        "classification in quantifying muscle fatty degeneration in the lumbar multifidus using magnetic resonance " +
        "imaging. Journal of manipulative and physiological therapeutics, 2014, vol. 37, no 3, p. 190-197"]}
    />
}