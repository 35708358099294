import React from "react";
import {Checkbox, FormControlLabel, Grid, Radio, ThemeProvider} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export const CheckboxBtnGrid = ({color, checked, value, onChange}) => {
    return <Grid container>
        <Grid item xs={12}>
            <FormControlLabel
                fontSize="small"
                style={{margin: 0, color: color}}
                control={<Checkbox
                    style={{color: color, margin: 0, padding: 0}}
                    size="small"
                    checked={checked}
                    onChange={onChange}
                />}
                label={<span style={{fontSize: 'var(--small-font-size)'}}>{value}</span>}
            />
        </Grid>
    </Grid>
}

export const RadioBtnGrid = ({color, value, checked, onChange}) => {
    return <Grid container>
        <Grid item xs={12}>
            <FormControlLabel
                fontSize="small"
                style={{margin: 0, color: color}}
                control={<Radio
                    style={{color: color, margin: 0, padding: 0}}
                    size="small"
                    checked={checked}
                    onChange={onChange}
                />}
                label={<span style={{fontSize: 'var(--small-font-size)'}}>{value}</span>}
            />
        </Grid>
    </Grid>
}

export const MainValues = ({values, pfirr=false, theme, size, curr_val, handleValuesChange, color='var(--dark-blue)', label=true}) => {
    return <Grid container justify="center" alignItems="center">
        {values.map((value, keyValue) => {
            const val = pfirr ? keyValue+1 : keyValue
            return <Grid key={value} item xs={typeof size === "object" ? size[keyValue] : size} style={{display: "flex", justifyContent: "center"}}>
                {value !== null && <ThemeProvider theme={theme}>
                    <FormControlLabel
                        style={{margin: 0, color: color}}
                        control={
                            <Radio
                                style={{color: color, margin: 0, padding: 0}}
                                size="small"
                                checked={curr_val !== "100" && curr_val[0] === val.toString()}
                                onChange={() => handleValuesChange(val)}
                            />
                        }
                        label={label ? value : ''}
                    />
                </ThemeProvider>}
            </Grid>
        })}
    </Grid>
}

export const MainValuesCheckboxes = ({values, pfirr=false, theme, size, curr_val, handleValuesChange, color='var(--dark-blue)', label=true}) => {
    return <Grid container justify="center" alignItems="center">
        {values.map((value, keyValue) => {
            const val = pfirr ? keyValue+1 : keyValue
            return <Grid key={value} item xs={typeof size === "object" ? size[keyValue] : size} style={{display: "flex", justifyContent: "center"}}>
                <ThemeProvider theme={theme}>
                    <FormControlLabel
                        style={{margin: 0, color: color}}
                        control={<Checkbox
                            style={{color: color, margin: 0, padding: 0}}
                            size="small"
                            checked={curr_val.toString() !== "100" && curr_val.includes(val.toString())}
                            onChange={() => handleValuesChange(val)}
                        />}
                        label={label ? value : ''}
                    />
                </ThemeProvider>
            </Grid>
        })}
    </Grid>
}

export const SubValues = ({choices, title, xs, sub_vals, sub_color, handleChange, color=true}) => {
    return <Grid item xs={xs} className="checkboxes" style={{backgroundColor: color ? "var(--light-blue)" : "var(--light-gold)", borderRadius: "10px", display: "flex", flexDirection: "column"}}>
        <p style={{color: color ? "white" : "#000", fontSize: "var(--medium-font-size)"}}>{title}</p>
        {choices.map((value, keyValue) => {
            return <CheckboxBtnGrid
                key={keyValue}
                value={value}
                color={sub_color}
                checked={sub_vals[keyValue] === '1'}
                onChange={() => handleChange(keyValue)}
            />
        })}
    </Grid>
}

/**
 * Used for main grade value changes in Endplate Defects, NFStenosis et SCStenosis
 *
 * @param curr_val {string}
 * @param new_val {string}
 * @param sub_vals_length {number}
 * @returns {string|*}
 */
export const handleValuesChange = (curr_val, new_val, sub_vals_length) => {
    const not_sure = curr_val.endsWith('?') ? '?' : ''
    if (new_val === "0") return "0"+not_sure
    else {
        const sub_val = Array(sub_vals_length).fill(',0').join('')
        if (curr_val === "100" || curr_val[0] === "0") return new_val+sub_val+not_sure
        else return new_val + curr_val.slice(1)
    }
}

/**
 * Used for sub_values changes in NFStenosis and SCStenosis
 *
 * @param curr_val {string}
 * @param position {number}
 * @returns {string}
 */
export const getNewSubValues = (curr_val, position) => {
    const val = curr_val.split(',')
    const sub_val = val[position+1]
    const not_sure = sub_val.endsWith('?') ? '?' : ''
    const result = sub_val.replace('?', '') === '0' ? '1' : '0'
    val[position+1] = result + not_sure
    return val.toString()
}

/**
 * Component used for all pathologies: not sure button + reset disc button + warn button
 *
 * @param theme
 * @param size {number}
 * @param value {string}
 * @param visible {boolean}
 * @param handleNotSure {function}
 * @param handleReset {function}
 * @param handleWarning {function}
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const NotSureResetBtn = ({theme, size, value, visible=true, handleNotSure, handleReset, handleWarning}) => {
    return <Grid item xs={size !== undefined ? size : 'auto'}>
        <Grid container>
            <Grid item xs={4} style={{display: "flex", justifyContent: "center", padding: "0 1px"}}>
                <ThemeProvider theme={theme}>
                    <FormControlLabel
                        style={{margin: 0, color: "var(--gold)"}}
                        control={<Checkbox
                            style={{color: "var(--gold)", margin: 0, padding: 0}}
                            checked={value.endsWith("?")}
                            disabled={value === "100" || value === "100;100" || value === "WARN"}
                            onChange={handleNotSure}
                        />}
                        label="?"
                    />
                </ThemeProvider>
            </Grid>
            <Grid item xs={4} style={{display: "flex", justifyContent: "center", padding: "0 1px", cursor: 'pointer'}}>
                {visible
                    ? <VisibilityIcon id="visible-icon" onClick={handleWarning}/>
                    : <VisibilityOffIcon id="visible-icon" onClick={handleWarning}/>
                }
            </Grid>
            <Grid item xs={4} style={{display: "flex", justifyContent: "center", padding: "0 1px", cursor: 'pointer'}}>
                <RestartAltIcon id="reset-icon" onClick={handleReset}/>
            </Grid>
        </Grid>
    </Grid>
}

/**
 * Button component used for all pathologies:
 *
 * @param font_size {string} - font size, in px
 * @param up_down {boolean} - whether the sub_values are displayed or not
 * @param onClick {function}
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const DropdownIcon = ({font_size="20px", up_down, onClick}) => {
    return <ArrowCircleDownIcon id='dropdown-icon'
        style={{
            fontSize: font_size,
            transform: up_down ? "rotate(180deg)" : "rotate(360deg)"
        }}
        onClick={onClick}
    />
}

/**
 * Function used for Endplate defects and Modic to change disc values structure
 *
 * @param values {string[]} - ex: ['0;0', '0;2', '2;3', '8;12', '1;9', '1;9']
 *
 * @returns {string[]} - ex: ['0', '0', '0', '2', '2', '3', '8', '12', '1', '9', '1', '9']
 */
export default function fromStringToInt(values) {
    const result = []
    for (let i=0; i<values.length; i++) {
        let first = '100'
        let second = '100'
        if (values[i]) {
            if (!values[i].includes(';')) {
                first = values[i]
            } else {
                first = values[i].split(';')[0]
                second = values[i].split(';')[1]
            }
        } else {
            first = null
            second = null
        }
        result[2*i]=first
        result[2*i+1]=second
    }
    return result
}