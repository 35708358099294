import React, {useState} from 'react'
import './SelectPatient.css'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export default function SelectPatient(props) {
    const [dropdown, setDropdown] = useState(false)
    const onMouseEnter = () => {setDropdown(true)}
    const onMouseLeave = () => {setDropdown(false)}

    const handleClick = key => {
        setDropdown(false)
        props.handleNewPatient(key)
    }

    return (
        <button onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <FormatListBulletedIcon fontSize={window.innerWidth < 400 ? "inherit" : "small"}/>
            {dropdown &&
            <div className='select-patient-box'>
                <ul>
                    {props.patients.map((patient,key) => (
                        <li key={key} onClick={() => handleClick(key)}>
                            {patient}
                        </li>
                    ))}
                </ul>
            </div>}
        </button>
    )
}