import React from "react";
import {createTheme, Grid} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import {DropdownIcon, MainValues, NotSureResetBtn} from "../utils";
import {width} from "./items";
import {
    Conflict,
    SagittalLocalization,
    Transligamentous,
    TransversalLocalizationV1,
    TransversalLocalizationV2
} from "./SubValues";
import {
    handleConflictBoxes,
    handleConflictRadio,
    handleSagittalLocalization,
    handleSubValuesChange,
    handleTransLocal2,
    handleTransversalLocalizationV1, handleValuesChange
} from "./utils";
import {useTranslation} from "react-i18next";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

export const DiscHerniationModule = ({id, fontSize, disc, curr_val, cursor, dropdown, lines, handleWarning,
                                     handleBackdrop, handleResetDisc, handleDropdown, handleAddHerniation,
                                     handleCheck}) => {
    const theme = createTheme({typography: {fontSize: fontSize}})
    const color = id === 0 ? 'var(--dark-blue)' : 'var(--gold)'

    const { i18n } = useTranslation()

    const is_sub_values = curr_val !== "WARN" && curr_val !== "100" && curr_val[0] !== "0"
    const sub_vals = curr_val.split(',')

    const handleNotSure = () => handleCheck(curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    const handleValues = (new_val) => handleCheck(handleValuesChange(curr_val, new_val))
    const handleSubValues = (position, value) => handleCheck(handleSubValuesChange(curr_val, position, value))
    const handleTransLoc1 = (box) => handleCheck(handleTransversalLocalizationV1(curr_val, box))
    const handleTransLoc2 = (box, value) => handleCheck(handleTransLocal2(curr_val, box, value))
    const handleSagLoc = (box) => handleCheck(handleSagittalLocalization(curr_val, box))
    const handleConflict = (box) => handleCheck(handleConflictBoxes(curr_val, box))
    const handleConfRadio = (value) => handleCheck(handleConflictRadio(curr_val, value))

    return <Grid container>
        {id === 0 //si c'est la premiere ligne on affiche le nom du disc
            ? <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
            : <Grid item xs={1} />
        }
        {/* dropdown button */}
        {id === 0 && curr_val !== 'WARN'
            ? <Grid item xs={1}>
                {lines === 1
                    ? <AddCircleIcon id='add-hernie-icon' onClick={handleAddHerniation}/>
                    : <RemoveCircleIcon id='remove-hernie-icon' onClick={handleAddHerniation}/>
                }
                {dropdown && <DropdownIcon
                    font_size="17px"
                    up_down={cursor}
                    onClick={handleDropdown}
                />}
            </Grid>
            : <Grid item xs={1}/>}
        {/* checkboxes */}
        <Grid item xs={8} className="checkboxes herniation-main-values">
            <MainValues
                values={valuesNameTrad['herniation']['values'][i18n.language]}
                theme={theme}
                size={width}
                curr_val={curr_val}
                color={color}
                label={false}
                handleValuesChange={handleValues}
            />
        </Grid>
        {id === 0
            ? <NotSureResetBtn
                theme={theme}
                size={2}
                value={curr_val}
                visible={curr_val !== 'WARN'}
                handleWarning={handleWarning}
                handleNotSure={handleNotSure}
                handleReset={handleResetDisc}
            />
            : <Grid item xs={3}/>
        }
        {/* subvalues */}
        {is_sub_values && cursor && <Grid container justify="center" alignItems="center" className='herniation-sub-values'>
            {valuesNameTrad['herniation']['sub_values'].map((subValue, keySubValue) => {
                if (subValue["acceptable_values"].find(elt => elt === curr_val[0]) !== undefined) {
                    if (subValue["name"] === "Transversal localization") {
                        if (curr_val[0] === "2") return <TransversalLocalizationV1
                            key={keySubValue}
                            values={sub_vals[1].split('_')}
                            handleChange={handleTransLoc1}
                            handleBackdrop={() => handleBackdrop(1)}
                        />
                        else return <TransversalLocalizationV2
                            key={keySubValue}
                            size={7}
                            values={sub_vals[1].split('_')}
                            handleChange={change => handleTransLoc2(change, "1")}
                            handleRadioChange={value => handleTransLoc2(4, value)}
                            handleBackdrop={() => handleBackdrop(1)}
                        />
                    }
                    else if (subValue["name"] === "Sagittal localization") return <SagittalLocalization
                        key={keySubValue}
                        size={7}
                        values={sub_vals[3].split('_')}
                        handleChange={handleSagLoc}
                        handleBackdrop={() => handleBackdrop(3)}
                    />
                    else if (subValue["name"] === 'Conflict') return <Conflict
                        key={keySubValue}
                        size={12}
                        ivd={disc.split('/')}
                        values={sub_vals[5].replace('?', '').split('_')}
                        language={i18n.language}
                        handleChange={handleConflict}
                        handleRadioConflict={handleConfRadio}
                        handleBackdrop={() => handleBackdrop(5)}
                    />
                    else return <Transligamentous
                        key={keySubValue}
                        xs={5}
                        title={subValue["name"][i18n.language]}
                        choices={subValue["choices"][i18n.language]}
                        checked={sub_vals[subValue["position"]].replace('?', '')}
                        handleChange={(change) => handleSubValues(subValue["position"]-1, change)}
                        handleBackdrop={() => handleBackdrop(subValue["backdrop"])}
                    />
                }
                else return null
            })}
        </Grid>}
    </Grid>

}