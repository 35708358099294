import http from "../http-common"

export const getSurgeonListDataVis = async (signal) => {
    return await http.get(`/core/datavis/surgeons/`, {signal: signal})
        .then(data => data.data)
}

export const getDataVisPerSurgeon = async (signal, surgeon_id) => {
    return await http.get(`/core/datavis/surgeon/${surgeon_id}/`, {signal: signal})
        .then(data => data.data)
}

export const getDataVisHome = async (signal) => {
    return await http.get(`/core/datavis/`, {signal: signal})
        .then(data => data.data)

}