import React from "react"
import {getAnnot} from "../../../../services/AnnotationService"
import errorMgt from "../../../../sessionExpired";
import SelectPatient from "./Viewer/SelectPatient";
import AnnotateDisease from "./AnnotateDisease/AnnotateDisease";
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import {AnnotateImage} from "./AnnotateImage";

class Annotate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            max_mod: 3,
            no_patient: false,

            //pour le chirurgien courant
            patient_ids: [], //array de int
            patient_labels: [], //array de str
            patient_status: [], //array de float : 0, 0.5 ou 1

            //pour le patient courant
            patient_id: props.patient_id ? props.patient_id.patient : 0,
            view_id: 1,

            serie_count: 0,

            update_status: false,
        }
    }

    updateAnnotations = () => {
        getAnnot()
            .then(annotations => {
                if (annotations.length===0) this.setState({no_patient: true})
                else {
                    this.setState({no_patient: false})
                    const patient_ids = []
                    const patient_labels = []
                    const patient_status = []
                    annotations.forEach(patient => {
                        patient_ids.push(patient.patient.id)
                        patient_labels.push(patient.patient.label)
                        patient_status.push(patient.patient.status)
                    })
                    this.setState({patient_ids: patient_ids, patient_labels: patient_labels, patient_status: patient_status})
                }

            }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) errorMgt('Your session expired.')
                else errorMgt('An error occured.')
            }
        })
    }

    componentDidMount() {
        if (this.props.is_surgeon && this.props.surgeon_id>=0) this.updateAnnotations()
    }

    //si chirurgien, remplissage de patient_ids
    componentDidUpdate(prevProps, prevState, snapshot) {
        //change state of patient_ids and patient_labels
        if (this.props.surgeon_id !== prevProps.surgeon_id && this.props.is_surgeon && this.props.surgeon_id>=0) {
            this.updateAnnotations()
        }
        if (this.state.update_status !== prevState.update_status) {
            getAnnot()
                .then(annotations => {
                    const patient_status = []
                    annotations.forEach(patient => {patient_status.push(patient.patient.status)})
                    this.setState({patient_status: patient_status})
                }).catch(error => {
                if (error.response) {
                    if (error.response.status === 401) errorMgt('Your session expired.')
                    else errorMgt('An error occured.')
                }
            })
        }
    }

    handleNewView = (new_view_id) => {
        this.setState({view_id: new_view_id < this.state.max_mod+1 ? new_view_id : this.state.max_mod})
    }

    handleFirstNonAnnPatient = () => {
        this.handleNewPatient(this.state.patient_status.findIndex(elt => elt < 1))
    }

    handleNewPatient = (new_patient_id) => {
        if (new_patient_id < this.state.patient_ids.length && new_patient_id>=0) {
            this.setState({patient_id: new_patient_id})
        }
    }  //redirection au patient new_patient_id

    render() {
        if (!this.props.is_surgeon) return <div>As an administrator, you don't have any patient to annotate.</div>
        else if (this.state.patient_ids.length<1 && !this.state.no_patient ) return <div>Loading...</div>
        else if (this.state.no_patient) return <h2>No patient to annotate</h2>
        else return <div id='annot' className='annotate'>
                <div className='viewer' tabIndex="0">
                    <div className='toolbarsup'>
                        <div className='select-view-tool'>
                            <button onClick={() => this.handleNewView(1)}><Filter1Icon fontSize={window.innerWidth < 400 ? "inherit" : "small"}/></button>
                            {this.state.serie_count > 1 && <button onClick={() => this.handleNewView(2)}><Filter2Icon fontSize={window.innerWidth < 400 ? "inherit" : "small"}/></button>}
                            {this.state.serie_count > 2 && <button onClick={() => this.handleNewView(3)}><Filter3Icon fontSize={window.innerWidth < 400 ? "inherit" : "small"}/></button>}
                        </div>
                        <div className='select-patient-tool'>
                            <SelectPatient patients={this.state.patient_labels} handleNewPatient={this.handleNewPatient} />
                        </div>
                        <div className='first-non-annotated-patient-tool'>
                            <button onClick={this.handleFirstNonAnnPatient}><SkipNextIcon fontSize={window.innerWidth < 400 ? "inherit" : "small"}/></button>
                        </div>
                    </div>
                    <AnnotateImage
                        view_id={this.state.view_id}
                        updateSerieCount={value => this.setState({serie_count: value})}
                        patient_id={this.state.patient_ids[this.state.patient_id]}
                        handleNewView={this.handleNewView}
                    />
                </div>
                <AnnotateDisease
                    patient_id={this.state.patient_id}
                    patient_id_db={this.state.patient_ids[this.state.patient_id]}
                    patient_label={this.state.patient_labels[this.state.patient_id]}
                    nbr_patients={this.state.patient_ids.length}
                    handleNewPatient={this.handleNewPatient}
                    handleStatusChange={() => this.setState({update_status: !this.state.update_status})}
                />
            </div>
    }
}

export default Annotate