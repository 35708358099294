import React from "react";
import './DiseaseStructure.css'
import {Backdrop, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

const DiseaseStructureButtons = ({help, handleResetDisease, handleClearDisease, resetTitles, setOpenHelp}) => {
    const { t } = useTranslation()
    return <Grid container spacing={0}>
        <Grid item xs="auto">
            <button className='reset-disease-button' onClick={handleResetDisease}>{t('disease-struct-reset')}</button>
        </Grid>
        <Grid item xs="auto">
            <button className='reset-disease-button' onClick={handleClearDisease}>
                {resetTitles[1]} {t('disease-struct-forall')}
            </button>
        </Grid>
        <Grid item xs/>
        {help && <Grid item xs="auto">
            <button className='help-button' onClick={() => setOpenHelp(true)}>{t('disease-struct-help')}</button>
        </Grid>}
    </Grid>
}

const Columns = ({columnNames, sizeColumnNames}) => {
    return <>
        {columnNames.map((value, keyValue) => {
            return <Grid item xs={sizeColumnNames[keyValue]} key={keyValue}>
                <p className='column-names'>{value}</p>
            </Grid>
        })}
    </>
}

export const DiseaseStructure = ({leftright, help, handleResetDisease, handleClearDisease, columnNames, sizeColumnNames, content,
                                 resetTitles}) => {
    const [openHelp, setOpenHelp] = React.useState(false)

    return <div className='disease-structure'>
        {help && <Backdrop
            sx={{ color: '#fff', zIndex: 1000}}
            open={openHelp}
            onClick={() => setOpenHelp(false)}
        >{help}</Backdrop>}
        <DiseaseStructureButtons
            help={help}
            handleResetDisease={handleResetDisease}
            handleClearDisease={handleClearDisease}
            resetTitles={resetTitles}
            setOpenHelp={setOpenHelp}
        />
        {columnNames && <Grid container>
            <Grid item xs={2}/>
            <Grid item xs={8}>
                <Grid container justify="center" alignItems="center">
                    {leftright
                        ? <>
                            <Grid item xs={3}/>
                            <Grid item xs={9}>
                                <Grid container justify="center" alignItems="center">
                                    <Columns columnNames={columnNames} sizeColumnNames={sizeColumnNames} />
                                </Grid>
                            </Grid>
                        </>
                        : <Columns columnNames={columnNames} sizeColumnNames={sizeColumnNames} />
                    }
                </Grid>
            </Grid>
            <Grid item xs={2}/>
        </Grid>}
        {content}
    </div>
}