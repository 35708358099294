export const disease_ids = {'pfirrmann': 0, 'modic': 1, 'anteroretro': 3, 'herniation': 4, 'scs': 5, 'nfs': 6, 'lrs': 7,
    'fracture': 8, 'facet': 9, 'trans_vb': 10, 'endplate': 11, 'multifidus': 12, 'psoas': 13, 'erectorspinae': 14,
    'facet-edema': 15, 'spinalmuscles': 16}

/**
 * Returns [inf, sup] to update the slider, according to values
 *
 * @param values {Array}
 * @returns {[number,number]}
 */
export const updateDiscRange = (values) => {
    let inf = null
    let sup = null
    for (let disc=0; disc < values.length; disc++) {
        if (values[disc] !== null) {
            if (inf === null && sup === null) {
                inf = disc
                sup = disc
            } else {
                if (disc < inf) inf = disc
                else if (disc > sup || disc+1 > sup) sup = disc+1
            }
        }
    }
    if (inf === null) inf = 4
    if (sup === null) sup = 10
    return [inf, sup]
}