import React from 'react'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {Paper, Table, TableBody, TableContainer, TableHead, TableRow} from "@mui/material";
import {
    getDataVisPerSurgeon,
    getSurgeonListDataVis
} from "../../../services/DataVisService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'var(--dark-blue)',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'var(--light-gold)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default class DataVisAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            surgeons: {},
            data: []
        }

        this.controller = new AbortController();
        this.signal = this.controller.signal;

    }

    updateSurgeonsList = () => {
        Object.keys(this.state.surgeons).forEach(surgeon_id => {
            getDataVisPerSurgeon(this.signal, surgeon_id).then(data => {
                const surgeon = {...this.state.surgeons[surgeon_id], ...data, id: surgeon_id}
                const dataList = [...this.state.data, surgeon]
                this.setState({data: dataList})
            })
        })
    }

    componentDidMount() {
        getSurgeonListDataVis(this.signal).then(data => this.setState({surgeons: data}))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.surgeons !== this.state.surgeons && Object.keys(this.state.surgeons).length > 0) this.updateSurgeonsList()
    }

    componentWillUnmount() {
        this.controller.abort()
    }

    render() {
        const sortedTab = this.state.data.sort((first_dict, second_dict) => (second_dict.id - first_dict.id))
        if (sortedTab.length > 0) return <div className='home-datavis'>
            <TableContainer component={Paper} style={{marginTop: "5px"}}>
                <Table sx={{ minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Doctor ID</StyledTableCell>
                            <StyledTableCell align="center">Connexion ID</StyledTableCell>
                            <StyledTableCell align="center">First Name</StyledTableCell>
                            <StyledTableCell align="center">Last Name</StyledTableCell>
                            <StyledTableCell align="center">Last connexion date</StyledTableCell>
                            <StyledTableCell align="center">Total annotations</StyledTableCell>
                            <StyledTableCell align="center">Total patients</StyledTableCell>
                            <StyledTableCell align="center">Patients DONE</StyledTableCell>
                            <StyledTableCell align="center">Patients STARTED</StyledTableCell>
                            <StyledTableCell align="center">Patients TO DO</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTab.map(row => {
                            return <StyledTableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.id}</TableCell>
                                <TableCell align="center">{row.connexion_id}</TableCell>
                                <TableCell align="center">{row.first_name}</TableCell>
                                <TableCell align="center">{row.last_name}</TableCell>
                                <TableCell align="center">{row.last_connexion}</TableCell>
                                <TableCell align="center">{row.nb_annot}</TableCell>
                                <TableCell align="center">{row.nb_patients}</TableCell>
                                <TableCell align="center">{row.count_done}</TableCell>
                                <TableCell align="center">{row.count_started}</TableCell>
                                <TableCell align="center">{row.count_todo}</TableCell>
                            </StyledTableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        else return <p></p>
    }

}