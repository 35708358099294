import http from "../http-common"

export const loginService = async (data) => {
    return await fetch(`${process.env.REACT_APP_IP}/token-auth/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
}

export const startSession = async (json) => {
    return await fetch(`${process.env.REACT_APP_IP}/core/sessions/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${json.token}`
        },
        body: JSON.stringify({'status' : 'start', 'platform' : 'annotator'})
    })
}

export const getCurrentUser = async () => {
    return await http.get(`/core/current_user/`)
}

export const updateSession = async () => {
    return await fetch(`${process.env.REACT_APP_IP}/core/sessions/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({'status' : 'end', 'platform' : 'annotator'})
    })
}