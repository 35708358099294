import http from "../http-common"

export const getClinicalData = async (patient_id, signal) => {
    return await fetch(`${process.env.REACT_APP_IP}/core/collector/${patient_id}/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${sessionStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        signal
    })
        .then(response => response.json())

}

export const getPatientDetails = (id) => {
    return http.get(`/core/patient/${id}/`)
}