import React from 'react'
import DiscRangeSelection from "./DiscRangeSelection";
import {DiseaseStructure} from "./Diseases/DiseaseStructure";
import {
    DiscHerniationHelp,
    FacetArthropathyHelp,
    FattyInvolutionHelp,
    LRStenosisHelp,
    SCStenosisHelp,
    SpinalMusclesFattyInvolutionHelp
} from "./Help/Help";
import {SpinalCanalStenosis} from "./Diseases/Stenosis/SpinalCanalStenosis";
import LateralRecessStenosis from "./Diseases/Stenosis/LateralRecessStenosis";
import DiscHerniation from "./Diseases/DiscHerniation/DiscHerniation";
import FacetArthropathy from "./Diseases/Facet/FacetArthropathy";
import FacetEdema from "./Diseases/Facet/FacetEdema";
import FattyInvolution from "./Diseases/Muscles/FattyInvolution";
import {useTranslation} from "react-i18next";
import {defaultTrad, diseasesNameTrad, valuesNameTrad} from "../../../../../utils/traductions";

export const AxDiseases = ({ax_values, ax_colors, display, handleClick, handleCheck, handleResetAxDiseases,
                                resetDisease, clearDisease, resetDisc}) => {
    const [inf, setInf] = React.useState(4)
    const [sup, setSup] = React.useState(10)
    const [ready, setReady] = React.useState(false)

    const handleChangeSupInf = (inf, sup) => {
        setInf(inf)
        setSup(sup)
    }

    const { i18n } = useTranslation()

    return <div className='sag-diseases'>
        <DiscRangeSelection
            language={i18n.language}
            values={ax_values}
            view={'AXIAL'}
            patho_ref={'herniation'}
            ready_for_annotations={ready}
            handleChangeSupInf={handleChangeSupInf}
            set_ready_for_annotation={() => setReady(true)}
            handleCheck={handleCheck}
        />
        {ready && <>
            <button className='reset-patient-button' onClick={handleResetAxDiseases}>
                {defaultTrad['reset-ax-diseases'][i18n.language]}
            </button>
            <div className="disease-list">
                {ax_values['scs'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['scs']}} onClick={() => handleClick('spinal_canal')}>
                        {diseasesNameTrad['scs'][i18n.language]}
                    </button>
                    {display === 'spinal_canal' && <DiseaseStructure
                        resetTitles={['Spinal Canal Stenosis', valuesNameTrad['scs']['reset-values'][i18n.language]]}
                        columnNames={valuesNameTrad['scs']['column_values'][i18n.language]}
                        sizeColumnNames={[3,3,3,3]}
                        handleResetDisease={() => resetDisease(5, 'Spinal Canal Stenosis')}
                        handleClearDisease={() => clearDisease(5)}
                        help={<SCStenosisHelp language={i18n.language}/>}
                        content={<SpinalCanalStenosis
                            value_discs={[inf, sup]}
                            values={ax_values['scs']}
                            handleCheck={(disc, value) => handleCheck(5, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(5, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['lrs'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['lrs']}} onClick={() => handleClick('lateral_canal')}>
                        {diseasesNameTrad['lrs'][i18n.language]}
                    </button>
                    {display === 'lateral_canal' && <DiseaseStructure
                        resetTitles={['Lateral Canal Stenosis', valuesNameTrad['lrs']['reset-values'][i18n.language]]}
                        leftright={true}
                        columnNames={valuesNameTrad['lrs']['values'][i18n.language]}
                        sizeColumnNames={[3,4,5]}
                        handleResetDisease={() => resetDisease(7, 'Lateral Canal Stenosis')}
                        handleClearDisease={() => clearDisease(7)}
                        help={<LRStenosisHelp language={i18n.language}/>}
                        content={<LateralRecessStenosis
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['lrs']}
                            handleCheck={(disc, value) => handleCheck(7, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(7, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['herniation'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['herniation']}} onClick={() => handleClick('disc_herniation')}>
                        {diseasesNameTrad['herniation'][i18n.language]}
                    </button>
                    {display === 'disc_herniation' && <DiseaseStructure
                        resetTitles={['Disc Herniation', 'Normal']}
                        columnNames={valuesNameTrad['herniation']['values'][i18n.language]}
                        sizeColumnNames={[2,3,3,2,2]}
                        handleResetDisease={() => resetDisease(4, 'Disc Herniation')}
                        handleClearDisease={() => clearDisease(4)}
                        help={<DiscHerniationHelp language={i18n.language}/>}
                        content={<DiscHerniation
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['herniation']}
                            handleCheck={(disc, value) => handleCheck(4, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(4, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['facet'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['facet']}} onClick={() => handleClick('facet')}>
                        {diseasesNameTrad['facet-art'][i18n.language]}
                    </button>
                    {display === 'facet' && <DiseaseStructure
                        resetTitles={['Facet arthropathy', valuesNameTrad['facet-arth']['reset-values'][i18n.language]]}
                        leftright={true}
                        columnNames={valuesNameTrad['facet-arth']['values'][i18n.language]}
                        sizeColumnNames={[3, 1, 4, 4]}
                        handleResetDisease={() => resetDisease(9, 'Facet arthropathy')}
                        handleClearDisease={() => clearDisease(9)}
                        help={<FacetArthropathyHelp language={i18n.language}/>}
                        content={<FacetArthropathy
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['facet']}
                            handleCheck={(disc, value) => handleCheck(9, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(9, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['facet-edema'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['facet-edema']}} onClick={() => handleClick('facet_edema')}>
                        {diseasesNameTrad['facet-edema'][i18n.language]}
                    </button>
                    {display === 'facet_edema' && <DiseaseStructure
                        resetTitles={['Facet edema', valuesNameTrad['facet-edema']['reset-values'][i18n.language]]}
                        handleResetDisease={() => resetDisease(15, 'Facet edema')}
                        handleClearDisease={() => clearDisease(15)}
                        content={<FacetEdema
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['facet-edema']}
                            handleCheck={(disc, value) => handleCheck(15, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(15, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['multifidus'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['multifidus']}} onClick={() => handleClick('multifidus')}>
                        {diseasesNameTrad['multifidus'][i18n.language]}
                    </button>
                    {display === 'multifidus' && <DiseaseStructure
                        resetTitles={['Multifidus fatty involution', '0']}
                        handleResetDisease={() => resetDisease(12, 'Multifidus fatty involution')}
                        handleClearDisease={() => clearDisease(12)}
                        help={<FattyInvolutionHelp language={i18n.language}/>}
                        content={<FattyInvolution
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['multifidus']}
                            handleCheck={(disc, value) => handleCheck(12, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(12, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['psoas'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['psoas']}} onClick={() => handleClick('psoas')}>
                        {diseasesNameTrad['psoas'][i18n.language]}
                    </button>
                    {display === 'psoas' && <DiseaseStructure
                        resetTitles={['Psoas fatty involution', '0']}
                        handleResetDisease={() => resetDisease(13, 'Psoas fatty involution')}
                        handleClearDisease={() => clearDisease(13)}
                        help={<FattyInvolutionHelp language={i18n.language}/>}
                        content={<FattyInvolution
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['psoas']}
                            handleCheck={(disc, value) => handleCheck(13, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(13, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['erectorspinae'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['erectorspinae']}} onClick={() => handleClick('erectorspinae')}>
                        {diseasesNameTrad['erector-spinae'][i18n.language]}
                    </button>
                    {display === 'erectorspinae' && <DiseaseStructure
                        resetTitles={['Erector spinae fatty involution', '0']}
                        handleResetDisease={() => resetDisease(14, 'Erector spinae fatty involution')}
                        handleClearDisease={() => clearDisease(14)}
                        help={<FattyInvolutionHelp language={i18n.language}/>}
                        content={<FattyInvolution
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['erectorspinae']}
                            handleCheck={(disc, value) => handleCheck(14, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(14, disc, label_disc)}
                        />}
                    />}
                </>}
                {ax_values['spinalmuscles'] && <>
                    <button className='disease-button' style={{backgroundColor: ax_colors['spinalmuscles']}} onClick={() => handleClick('spinalmuscles')}>
                        {diseasesNameTrad['spinalmuscles'][i18n.language]}
                    </button>
                    {display === 'spinalmuscles' && <DiseaseStructure
                        resetTitles={['Spinal muscles fatty involution', '0']}
                        handleResetDisease={() => resetDisease(16, 'Spinal muscles fatty involution')}
                        handleClearDisease={() => clearDisease(16)}
                        help={<SpinalMusclesFattyInvolutionHelp language={i18n.language}/>}
                        content={<FattyInvolution
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={ax_values['spinalmuscles']}
                            handleCheck={(disc, value) => handleCheck(16, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(16, disc, label_disc)}
                        />}
                    />}
                </>}
            </div>
        </>}
    </div>
}