import React from 'react'
import WindowManagement from "./Viewer/WindowManagement";
import {getImageInfo} from "../../../../services/ImageService";
import {useTranslation} from "react-i18next";

const resetSeries = []

export const AnnotateImage = ({patient_id, view_id, handleNewView, updateSerieCount}) => {
    const [noImage, setNoImage] = React.useState(false)
    const [loading, setLoading] = React.useState(true)

    const [series, setSeries] = React.useState([]) //contient pour chaque mod, le num de la mod + le nbr de slices
    const [seriesCount, setSeriesCount] = React.useState(-1) //permet daffichier windows management uniquement s il y a des images a afficher

    const [currSerie, setCurrSerie] = React.useState(0)
    //const [currSlice, setCurrSlice] = React.useState(0)

    const { t } = useTranslation()

    const getInfo = () => {
        getImageInfo(patient_id).then(fetchedSeries => {
            const nb_series = fetchedSeries.length
            if (nb_series < view_id) handleNewView(nb_series)
            if (nb_series >=1) {
                const new_series = series.slice()
                fetchedSeries.forEach(serie => new_series.push([serie.modality, serie.slice_count]))
                setSeries(new_series)
                setCurrSerie(fetchedSeries.findIndex(elt => elt !== 0))
                updateSerieCount(nb_series)
            } else setNoImage(true)
            return nb_series
        }).then(nb_series => {
            setSeriesCount(nb_series)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        if (series.length === 0) getInfo()
    }, [series])

    React.useEffect(() => {
        if (loading === false) {
            setNoImage(false)
            setLoading(true)
            setSeriesCount(-1)
            setSeries(resetSeries)
        }
    }, [patient_id])

    if (loading) return <p>Loading ...</p>
    else if (!noImage && series.length === seriesCount) return <WindowManagement
        view_id={view_id}
        modality={currSerie}
        series={series}
        patient_id={patient_id}
    />
    else return <p>{t('annot-viewer-no-img')}</p>
}