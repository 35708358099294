import React from 'react'
import './HomePage.css'
import {getAnnot} from "../../../services/AnnotationService"
import {Link} from "react-router-dom"
import axios from "axios"
import {useTranslation} from "react-i18next";
import DataVisAdmin from "./DataVisAdmin";

const HomePageModule = ({user_name, patient_count, patient_done, patient_started, patient_todo, patient_ids}) => {

    const { t } = useTranslation();

    const getPatientId = (patient) => patient_ids.findIndex(elt => elt===patient[0])

    return <div className='home'>
        <br/>
        <h1>{t('home-title')} Dr. {user_name}</h1>
        <p>{t('home-subtitle', {patient_count: patient_count})}</p>
        <br/>
        {patient_count > 0 && <ul>
            {[["home-done", "home-links-done", patient_done],
                ["home-to-finish", "home-links-started", patient_started],
                ["home-to-do", "home-links-todo", patient_todo]].map((section, keySection) => {
                return <div className={section[0]} key={keySection}>
                    <p> {t(section[0])} : {section[2].length}</p>
                    {section[2].map((patient,keyPatient) => {
                        return <li key={keyPatient}>
                            <Link className={section[1]} to={{pathname: "/Annotations", state: {patient: getPatientId(patient)}}}>
                                Patient {patient[1]}
                            </Link>
                        </li>})}
                </div>
            })}
        </ul>}
    </div>
}

class HomePage extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            patient_ids: [],
            patient_done: [],
            patient_started: [],
            patient_todo: [],
            no_patient: false
        }

        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()
    }

    updateAnnotList = (annotations) => {
        if (annotations.length===0 && this._isMounted) this.setState({no_patient: true})
        else {
            if (this._isMounted) this.setState({no_patient: false})
            annotations.forEach(patient => {
                const pat = patient.patient.status
                const ids = this.state.patient_ids.slice()
                ids.push(patient.patient.id)
                if (pat === 0) {
                    const patients = this.state.patient_todo.slice()
                    patients.push([patient.patient.id, patient.patient.label])
                    if (this._isMounted) this.setState({patient_todo: patients, patient_ids: ids})
                }
                else if (pat === 0.5) {
                    const patients = this.state.patient_started.slice()
                    patients.push([patient.patient.id, patient.patient.label])
                    if (this._isMounted) this.setState({patient_started: patients, patient_ids: ids})
                } else {
                    const patients = this.state.patient_done.slice()
                    patients.push([patient.patient.id, patient.patient.label])
                    if (this._isMounted) this.setState({patient_done: patients, patient_ids: ids})
                }
            })
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.is_surgeon && this.props.surgeon_id>=0) {
            getAnnot(this.source.token)
                .then(this.updateAnnotList)
                .catch(thrown => {
                    //alert("An error occured. Please, try again or contact the support.")
                    //this.props.logout()
                    //if (axios.isCancel(thrown)) console.log('Request canceled')
                })
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.surgeon_id !== prevProps.surgeon_id && this.props.is_surgeon && this.props.surgeon_id>=0) {
            await getAnnot(this.source.token)
                .then(annotations => this.updateAnnotList(annotations))
                .catch(thrown => {
                    alert("An error occured. Please, try again or contact the support.")
                    this.props.logout()
                })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.source.cancel()
    }
    render () {
        const {patient_ids, patient_todo, patient_started, patient_done, no_patient} = this.state
        const patient_count = patient_todo.length+patient_started.length+patient_done.length
        if (this.props.surgeon_id<0 || (patient_count === 0 && !no_patient && this.props.is_surgeon)) return <div> Loading... </div>
        else if (this.props.is_surgeon) return <HomePageModule
            user_name={this.props.name}
            patient_count={patient_count}
            patient_done={patient_done}
            patient_started={patient_started}
            patient_todo={patient_todo}
            patient_ids={patient_ids}
        />
        else return <DataVisAdmin />
    }
}

export default HomePage