export const clear_values = {
    0: {'clear': '1', 'reset': '100'}, // Pfirrmann
    1: {'clear': '0;0', 'reset': '100'}, // Modic
    3: {'clear': '0', 'reset': '100'}, // AnteroRetro
    4: {'clear': '0', 'reset': '100'}, // Disc herniation
    5: {'clear': '0', 'reset': '100'}, // SCStenosis
    6: {'clear': '0;0', 'reset': '100;100'}, // NFStenosis
    7: {'clear': '0,0', 'reset': '100'}, // LRStenosis
    8: {'clear': '0', 'reset': '100'}, // Vertebral collapse
    9: {'clear': '0;0', 'reset': '100;100'}, // Facet arthropathy
    10: {'clear': '0', 'reset': '100;100'}, // Transitional vertebrae
    11: {'clear': '0;0', 'reset': '100'}, // Endplate defects
    12: {'clear': '0,0', 'reset': '100'}, // Multifidus fatty involution
    13: {'clear': '0,0', 'reset': '100'}, // Psoas fatty involution
    14: {'clear': '0,0', 'reset': '100'}, // Erector spinae fatty involution
    16: {'clear': '0,0', 'reset': '100'}, // Spinal muscles fatty involution
}