import React from "react";
import {AnnotationTool} from "../Annotation/AnnotationTool";
import {getAnnotPerPatient} from "../../../../../services/AnnotationService";
import errorMgt from "../../../../../sessionExpired";
import {getColorDisease} from "../getDiseasesColor";
import {updateDataBase} from "../utils/ResetClearDiseases";
import {clearDisease, handleDiseasesValues, resetDiscModic, resetDisease} from "./utils";

export default class AnnotateDisease extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            max_diseases: 17,
            max_discs: 10,
            disease_count: 0,
            patient_status: [], //array de int                          => status of each disease
            patient_colors: [], //array de dimension disease_count      => colors of each disease
            current_values: [], //array de dimension disease_count*6    => values of each disease for each disc
        }
    }

    handleDiseasesValues = (diseases) => {
        const vals = handleDiseasesValues(diseases, this.state.max_diseases, this.state.max_discs)
        this.setState({
            disease_count: Object.values(diseases).length,
            current_values: vals.current_values,
            patient_colors: vals.patient_colors
        })
        return vals.disease_status
    }

    updatePatientStatus = (diseases, patient) => {
        const patient_status = []
        const disease_status = this.handleDiseasesValues(diseases)
        patient_status[this.state.patient_ids.findIndex(element => element === patient)] = Math.min(...disease_status)
        if (patient_status.length === this.state.patient_ids.length && !patient_status.some(elt => elt === null)) {
            this.setState({patient_status: patient_status})
        }
    }

    componentDidMount() {
        const patient = this.props.patient_id_db
        getAnnotPerPatient(patient)
            .then(diseases => this.updatePatientStatus(diseases, patient))
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) errorMgt('Your session expired.')
                    else errorMgt('An error occured.')
                }
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //change state of disease_count, current_values, patient_colors
        if (this.props.patient_id !== prevProps.patient_id) {
            getAnnotPerPatient(this.props.patient_id_db)
                .then(diseases => this.handleDiseasesValues(diseases))
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) errorMgt('Your session expired.')
                        else errorMgt('An error occured.')
                    }
                })
        }
        if (this.state.current_values !== prevState.current_values) this.props.handleStatusChange()
    }

    handleNewValues = (disease_id, disc_id, value) => {
        const new_values = this.state.current_values[disease_id]
        new_values[disc_id] = value

        updateDataBase(this.props.patient_id_db, new_values, disease_id,
            () => this.setState({
                current_values: [
                    ...this.state.current_values.slice(0, disease_id),
                    new_values,
                    ...this.state.current_values.slice(disease_id+1)
                ],
                patient_colors: [
                    ...this.state.patient_colors.slice(0, disease_id),
                    getColorDisease(disease_id, new_values),
                    ...this.state.patient_colors.slice(disease_id+1)
                ]
            }))
    }

    handleResetDisc = (disease_id, disc_id, label_disc) => {
        let selection = window.confirm("Reset "+label_disc+"?");
        if (selection) this.resetDisc(disease_id, disc_id, label_disc)
    }
    resetDisc = (disease_id, disc_id, label_disc) => {
        if (disease_id === 6 || disease_id === 9) this.handleNewValues(disease_id, disc_id, '100;100')
        else if (disease_id !== 11) this.handleNewValues(disease_id, disc_id,'100')
        else this.resetDiscModic(disease_id, disc_id, label_disc)
    }
    resetDiscModic = (disease_id, disc_id, label) => {
        this.handleNewValues(disease_id, disc_id, resetDiscModic(this.state.current_values[disease_id][disc_id], label))
    }

    handleResetDisease = (disease_id, disease_label) => {
        let selection = window.confirm("Reset "+disease_label+" annotations?");
        if (selection) this.resetDisease(disease_id)
    }


    resetClearDisease = (disease_id, value, color) => {
        const disease_values = this.state.current_values.slice()
        const new_values = new Array(this.state.max_discs).fill(null)
        if (disease_values[disease_id] !== null) {
            for (let i=0; i<this.state.max_discs; i++) if (disease_values[disease_id][i] !== null) new_values[i] = value
            updateDataBase(this.props.patient_id_db, new_values, disease_id,
                () => this.setState({
                    current_values: [
                        ...this.state.current_values.slice(0, disease_id),
                        new_values,
                        ...this.state.current_values.slice(disease_id+1)
                    ],
                    patient_colors: [
                        ...this.state.patient_colors.slice(0, disease_id),
                        color,
                        ...this.state.patient_colors.slice(disease_id+1)
                    ]
                }))
        }
    }

    resetDisease = (disease_id) => this.resetClearDisease(disease_id, resetDisease(disease_id), 'var(--red)')
    clearDisease = (disease_id) => this.resetClearDisease(disease_id, clearDisease(disease_id), 'var(--green)')

    render() {
        return <AnnotationTool
            patient_id={this.props.patient_id}
            patient_id_db={this.props.patient_id_db}
            patient_label={this.props.patient_label}
            values={this.state.current_values}
            colors={this.state.patient_colors}
            handleCheckValue={this.handleNewValues}
            handleNewPatient={this.props.handleNewPatient}
            handleResetDisc={this.handleResetDisc}
            handleResetDisease={this.handleResetDisease}
            handleClearDisease={this.clearDisease}
            handleResetSagAx={this.resetDisease}
        />
    }
}