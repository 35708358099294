import React from 'react'
import './Disc.css'
import {
    Backdrop,
    createTheme,
    Grid,
    Slider,
    styled
} from "@mui/material";
import {Base} from "../Help/Base";
import {discs} from "./items";
import {DropdownIcon, NotSureResetBtn, RadioBtnGrid} from "./utils";
import {helpTrad, valuesNameTrad} from "../../../../../../utils/traductions";

const NASlider = styled(Slider)({
    width: '100%',
    margin: '15px auto',
    height: 3,
    color: 'var(--dark-blue)',
    '& .MuiSlider-thumb': {
        display: 'none'
    }
});

const marks = [
    {value: -5, label: '5',},
    {value: -4, label: '4',},
    {value: -3, label: '3',},
    {value: -2, label: '2',},
    {value: -1, label: '1',},
    {value: 0, label: '0',},
    {value: 1, label: '-1',},
    {value: 2, label: '-2',},
    {value: 3, label: '-3',},
    {value: 4, label: '-4',},
    {value: 5, label: '-5',}]

const IsthmicHelp = ({id, language}) => id === 0 ? null : <Base
    title={helpTrad['anteroretro']['isthmic']['title'][language]}
    definition={helpTrad['anteroretro']['isthmic']['definition'][language]}
    content={<img src={`/help/AnteroRetro/Isthmic_${language}.png`} alt='test'/>}
/>

const AnteroRetroSlider = styled(Slider)({
    width: '100%',
    margin: '15px auto',
    height: 3,
    color: 'var(--dark-blue)',
    "& .MuiSlider-thumb": {
        height: 12,
        width: 12,
        backgroundColor: 'var(--dark-blue)',
        border: "2px solid currentColor",
        "&:hover, &:focus, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: '0 0 0 6px rgba(19, 55, 90, 0.2)',
            cursor: 'pointer'
        },
    },

});

const SubValues = ({xs, title, sub_vals, sub_values, sub_valuesvalues, handleChange, handleBackdrop}) => {
    return <Grid item xs={xs} className="dropdown-grid">
        <p style={{cursor: 'help', fontSize: 'var(--medium-font-size)'}} onClick={handleBackdrop}>{title}</p>
        {sub_values.map((value, keyValue) => {
            const sub_val = sub_vals[keyValue]
            const color = sub_val === 'x' ? 'crimson' : 'var(--dark-blue)'
            return <Grid key={keyValue} container justify="center" alignItems="center">
                <Grid item xs={5}>
                    <p style={{color: 'var(--dark-blue)', margin: "auto 5px 0 0", cursor: 'default', fontSize: 'var(--medium-font-size)'}}>{value}</p>
                </Grid>
                <Grid item xs={7}>
                    <Grid container justify="center" alignItems="center">
                        {sub_valuesvalues.map((val, keyVal) => {
                            return <Grid item xs={4} key={`${keyValue}-${keyVal}`}>
                                <RadioBtnGrid
                                    key={keyVal}
                                    color={color}
                                    value={val['label']}
                                    checked={sub_val === val['value']}
                                    onChange={() => handleChange(keyValue, val['value'])}
                                />
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Grid>
        })}
    </Grid>
}

class AnteroRetro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cursor: null,
            backdrop: -1,
            fontSize: 12
        }
    }

    handleDropdown = disc => {
        if (this.state.cursor === disc) this.setState({cursor: null})
        else this.setState({cursor: disc})
    }

    handleValuesChange = (curr_val, is_sub_vals, disc, value) => {
        this.setState({cursor: disc})
        const not_sure = curr_val.endsWith("?") ? '?' : ''
        if (value === 0) this.props.handleCheck(disc, "0"+not_sure)
        else {
            if (!is_sub_vals) this.props.handleCheck(disc, (value*(-1)).toString()+",x,x"+not_sure)
            else {
                const previous = parseInt(curr_val.replace('?', ''))
                const id = previous <= 0 ? 2 : 1
                this.props.handleCheck(disc, (value*(-1)).toString() + curr_val.slice(id))
            }
        }
    }

    handleSubValuesChange = (curr_val, disc, position, value) => {
        const val = curr_val.split(',')
        const not_sure = val[position+1].endsWith('?') ? '?' : ''
        val[position+1] = value + not_sure
        this.props.handleCheck(disc, val.toString())
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    render() {
        const theme = createTheme({typography: {fontSize: this.state.fontSize}})
        return <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.backdrop !== -1}
                onClick={() => this.setState({backdrop: -1})}
            >
                {this.state.backdrop >= 0 && <IsthmicHelp language={this.props.language} id={[this.state.backdrop]} />}
            </Backdrop>
            {discs.map((disc, keyDisc) => {
                const curr_val = this.props.values[keyDisc]
                const selected_val = -1*parseInt(curr_val) <100 && -1*parseInt(curr_val) >-100
                if (curr_val) {
                    const is_sub_vals = curr_val !== '0' && curr_val !== '0?' && curr_val !== '100' && curr_val !== 'WARN'
                    return <Grid key={keyDisc} container justify="center" alignItems="center" style={{backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc-anteroretro">{disc}</p></Grid>
                    <Grid item xs={1} className="dropdown-icon-grid">
                        {is_sub_vals && <DropdownIcon
                            up_down={this.state.cursor === keyDisc}
                            onClick={() => this.handleDropdown(keyDisc)}
                        />}
                    </Grid>
                    <Grid item xs={true}>
                        {selected_val
                            ? <AnteroRetroSlider
                                min={-5}
                                max={5}
                                track={false}
                                defaultValue={0}
                                value={-1*parseInt(curr_val)}
                                marks={marks}
                                onChangeCommitted={(e, value) => this.handleValuesChange(curr_val, is_sub_vals, keyDisc,value)}
                            />
                            : <NASlider
                                min={-5}
                                max={5}
                                track={false}
                                defaultValue={0}
                                value={0}
                                marks={marks}
                                onChangeCommitted={(e, value) => this.handleValuesChange(curr_val, false, keyDisc, value)}
                            />
                        }
                    </Grid>
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                    {is_sub_vals && this.state.cursor === keyDisc && <Grid key={keyDisc} container justify="center" alignItems="center">
                        <SubValues
                            xs={12}
                            title={valuesNameTrad['anteroretro']['sub_values_title'][this.props.language]}
                            sub_vals={curr_val.replace('?', '').split(',').slice(1)}
                            sub_values={valuesNameTrad['anteroretro']['sub_values'][this.props.language]}
                            sub_valuesvalues={valuesNameTrad['anteroretro']['sub_valuesvalues'][this.props.language]}
                            handleChange={(keyPosition, new_val) => this.handleSubValuesChange(curr_val, keyDisc, keyPosition, new_val)}
                            handleBackdrop={() => this.setState({backdrop: 1})}
                        />
                    </Grid>}
                </Grid>
                }
                else return null
            })}
        </>
    }
}

export default AnteroRetro