import React from 'react'
import {createTheme, Grid} from "@mui/material";
import {discs} from "./items";
import {MainValues, NotSureResetBtn} from "./utils";
import {SubValuesLine} from "./CompressionFracture";
import {valuesNameTrad} from "../../../../../../utils/traductions";

class TransitionalVertebrae extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: 13
        }
    }

    handleValuesChange = (curr_val, disc, value) => {
        const not_sure = curr_val.endsWith('?') ? '?' : ''
        this.props.handleCheck(disc, value + not_sure)
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    render() {
        const theme = createTheme({typography: {fontSize: this.state.fontSize}})
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) {
                const yes = curr_val !== "100" && curr_val[0] === "1"
                const sub_val_color = (curr_val === "1" || curr_val === "1?") ? 'crimson' : 'var(--gold)'
                return <Grid key={keyDisc} container justify="center" alignItems="center" style={{backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    {!yes && <Grid item xs={3}/>}
                    <Grid item xs={true} className="checkboxes">
                        <MainValues
                            theme={theme}
                            curr_val={curr_val}
                            values={valuesNameTrad['fracture']['values'][this.props.language]}
                            size={6}
                            handleValuesChange={keyValue => this.handleValuesChange(curr_val, keyDisc, keyValue.toString())}
                        />
                    </Grid>
                    {yes
                        ? <SubValuesLine
                            xs={6}
                            theme={theme}
                            curr_val={curr_val}
                            title={valuesNameTrad['trans_vb']['sub_values_title'][this.props.language]}
                            sub_values={valuesNameTrad['trans_vb']['sub_values'][this.props.language]}
                            sub_color={sub_val_color}
                            handleChange={new_val => this.handleValuesChange(curr_val, keyDisc, new_val)}
                        />
                        : <Grid item xs={3}/>
                    }
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                </Grid>
            } else return null
        })
    }
}

export default TransitionalVertebrae