import React from 'react'
import '../Disc.css'
import {createTheme, Grid} from "@mui/material";
import {discs} from "../items";
import {NotSureResetBtn} from "../utils";
import {LateralRecessModule} from "./LateralRecessModule";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

class LateralRecessStenosis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: 13
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (this.state.fontSize === 13 && window.innerWidth <= 1123) this.setState({fontSize: 11})
        else if (this.state.fontSize === 11 && window.innerWidth > 1123) this.setState({fontSize: 13})
    }

    handleValuesChange = (disc, position, value) => {
        const values = this.props.values.slice()
        let val = values[disc]
        let not_sure = ''
        if (val.endsWith('?')) not_sure = '?'
        if (position === 0) {
            if (val === "100" || val === 'WARN') this.props.handleCheck(disc, value+",x"+not_sure)
            else this.props.handleCheck(disc, value+val.slice(1,3)+not_sure)
        } else if (position === 1) {
            if (val === "100" || val === 'WARN') this.props.handleCheck(disc, "x,"+value+not_sure)
            else this.props.handleCheck(disc, val.slice(0,2)+value+not_sure)
        }
        /*if (value === "0") this.props.handleCheck(disc, "0")
        else {
            if (new_values[disc] === "100" || new_values[disc][0] === "0") this.props.handleCheck(disc, value+",x")
            else this.props.handleCheck(disc, value + new_values[disc].slice(1))
        }*/
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }


    handleNotSure = disc => {
        const values = this.props.values.slice()
        let val = values[disc]
        if (val.endsWith("?")) val = val.slice(0,-1)
        else val = values[disc].concat('', '?')
        this.props.handleCheck(disc, val)
    }

    render() {
        const theme = createTheme({typography: {fontSize: this.state.fontSize}})
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) {
                const left_right = curr_val.replace('?', '').split(',')
                return <Grid key={keyDisc} container justify="center" alignItems="center" style={{padding: '5px 0', backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={true}>
                        {valuesNameTrad['lrs']['left-right'][this.props.language].map((side, keySide) => {
                            return <LateralRecessModule
                                key={keySide}
                                side={side}
                                color={keySide === 0 ? "var(--dark-blue)" : "var(--gold)"}
                                theme={theme}
                                main_value={curr_val}
                                side_value={left_right[keySide]}
                                onChange={keyValue => this.handleValuesChange(keyDisc, keySide, keyValue.toString())}
                            />
                        })}
                    </Grid>
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                </Grid>
            }
            else return null
        })
    }
}

export default LateralRecessStenosis