import React from 'react'
import '../Disc.css'
import {createTheme, Grid} from "@mui/material";
import {discs} from "../items";
import {MainValuesCheckboxes, NotSureResetBtn} from "../utils";

const values = ["0","1","2","3"]

export default class ModicDiscCheckboxes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: 13
        }
    }

    handleValuesChange = (curr_val, disc, value) => {
        const not_sure = curr_val.endsWith('?') ? '?' : ''
        let new_val = curr_val === '0;0' || curr_val === '0;0?' ? '0' : curr_val.replace('?', '')
        if (new_val === '100' || new_val === '0' || new_val === 'WARN') this.props.handleCheck(disc, value+not_sure)
        else {
            if (value === 0) this.props.handleCheck(disc, value.toString()+not_sure)
            else {
                if (new_val.includes(value)) {
                    new_val = new_val.replace(value.toString(), '')
                    if (new_val === '') this.props.handleCheck(disc, '100')
                    else this.props.handleCheck(disc, new_val+not_sure)
                }
                else this.props.handleCheck(disc, new_val+value+not_sure)
            }
        }
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    render() {
        const theme = createTheme({
            typography: {
                fontSize: this.state.fontSize
            },
        });
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) return <Grid key={keyDisc} container justify="center" alignItems="center" style={{backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                <Grid item xs={2} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                <Grid item xs={true}>
                    <MainValuesCheckboxes
                        values={values}
                        theme={theme}
                        size={true}
                        curr_val={curr_val}
                        handleValuesChange={keyValue => this.handleValuesChange(curr_val, keyDisc, keyValue)}
                    />
                </Grid>
                <NotSureResetBtn
                    theme={theme}
                    value={curr_val}
                    visible={curr_val !== 'WARN'}
                    handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                    handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                    handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                />
            </Grid>
            else return null
        })
    }
}