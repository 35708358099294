import React from 'react'
import SpinalCanalStenosisModule from "./SpinalCanalStenosisModule"
import {useTranslation} from "react-i18next";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

export const SpinalCanalStenosis = (props) => {
    const { i18n } = useTranslation()
    return <SpinalCanalStenosisModule
        choices={valuesNameTrad['scs']['values'][i18n.language]}
        sub_choices={valuesNameTrad['scs']['sub_values'][i18n.language]}
        values={props.values}
        handleCheck={props.handleCheck}
        handleResetDisc={props.handleResetDisc}
    />
}