export const identities = {
    'fr': ['Label', 'Sexe', 'Age', 'Taille', 'Poids'],
    'en': ['Label', 'Sex', 'Age', 'Height', 'Weight']
}
export const unities = {
    'fr': [' ', ' ',' ans',' cm',' kg'],
    'en': [' ', ' ',' years old',' cm',' kg']
}
export const treatmentVals = {
    'fr': 'Traitements',
    'en': 'Treatments'
}
export const medHistory = {
    'fr': ['Fumeur','Diabète','Douleur lombaire','Douleur radiculaire', 'Localisation de la douleur radiculaire', 'Indice d\'invalidité d\'Oswestry'],
    'en': ['Smoke','Diabete','Lumbar pain','Radicular pain', 'Radiculalgia side', 'Oswestry Disability Index']
}
export const workVals = {
    'fr': ['Type de profession', 'Situation professionnelle'],
    'en': ['Work', 'Employment']
}
export const standardXRay = {
    'fr': 'Radiographie',
    'en': 'X-ray'
}
export const radiographieQuestions = {
    'fr': ['Avis chirurgical nécessaire ?', 'Radiographie normale ?'],
    'en': ['Surgical opinion required?', 'Normal x-ray?']
}

export const titlesSurgery = {
    'en': ['Main radiological signs', "Previous spine operation", "Surgery indication/infiltration"],
    'fr': ['Signes radiologiques principaux', "Précédente opération du rachis", "Indication chirurgicale/infiltration"]
}

export const valuesSurgery = {
    'en': ["Hernia removal", "Laminectomy and recalibration", "Lumbar arthrodesis", "Infiltration", "Other"],
    'fr': ["Ablation d'une hernie", "Laminectomie et recalibrage", "Arthrodèse lombaire", "Infiltration", "Autre"]
}
export const valuesOperation = {
    'en': ["Hernia removal", "Laminectomy and recalibration", "Lumbar arthrodesis", "Other"],
    'fr': ["Ablation d'une hernie", "Laminectomie et recalibrage", "Arthrodèse lombaire", "Autre"]
}
export const valuesRadiographie = {
    'en': ["Scoliosis", "Transitional abnormality", "Spondylolisthesis"],
    'fr': ["Scoliose", "Anomalie transitionnelle", "Spondylolisthesis"]
}
export const valuesTreatment = {
    "Non - morphine analgesic": {
        'en': "Non-morphine analgesic",
        'fr': "Antalgique non morphinique"
    },
    "Morphine analgesic": {
        'en': "Morphine analgesic",
        'fr': "Antalgique morphinique"
    },
    "Anti - inflammatory (NSAIDs & corticosteroids)": {
        'en': "Anti-inflammatory (NSAIDs & corticosteroids)",
        'fr': "Anti-inflammatoires (AINS et corticostéroïdes)"
    },
    "Infiltration": {
        'en': "Infiltration",
        'fr': "Infiltration"
    },
    "Neurotropic treatment": {
        'en': "Neurotropic treatment",
        'fr': "Traitement neurotrope"
    },
    "Physiotherapy": {
        'en': "Physiotherapy",
        'fr': "Kinésithérapie"
    },
    "Other": {
        'en': "Other",
        'fr': "Autre(s) traitement(s)"
    },
    "Details": {
        'en': "Details",
        'fr': "Détails"
    }
}

export const medTrad = {
    'NA ': {
        'fr': 'Non précisé',
        'en': 'NA'
    },
    'NA': {
        'fr': 'Non précisé',
        'en': 'NA'
    },
    'W ': {
        'fr': 'F',
        'en': 'W'
    },
    'W': {
        'fr': 'F',
        'en': 'W'
    },
    'M ': {
        'fr': 'M',
        'en': 'M'
    },
    'M': {
        'fr': 'M',
        'en': 'M'
    },
    'Yes ': {
        'fr': 'Oui',
        'en': 'Yes'
    },
    'Yes': {
        'fr': 'Oui',
        'en': 'Yes'
    },
    'No ': {
        'fr': 'Non',
        'en': 'No'
    },
    'No': {
        'fr': 'Non',
        'en': 'No'
    },
    'Previous': {
        'fr': 'Précédemment',
        'en': 'Previous'
    },
    'Previous ': {
        'fr': 'Précédemment',
        'en': 'Previous'
    },
    'Type 1': {
        'fr': 'Type 1',
        'en': 'Type 1'
    },
    'Type 2': {
        'fr': 'Type 2',
        'en': 'Type 2'
    },
    'Left': {
        'fr': 'Gauche',
        'en': 'Left'
    },
    'Left ': {
        'fr': 'Gauche',
        'en': 'Left'
    },
    'Right': {
        'fr': 'Droite',
        'en': 'Right'
    },
    'Right ': {
        'fr': 'Droite',
        'en': 'Right'
    },
    'Bilateral': {
        'fr': 'Bilateral',
        'en': 'Bilatérale'
    },
    'Mechanical vibrations ': {
        'en': 'Mechanical vibrations',
        'fr': 'Sujette aux vibrations mécaniques'
    },
    'Manual handling of loads ': {
        'en': 'Manual handling of loads',
        'fr': 'Manipulation manuelle de charges'
    },
    'Painful postures/repetitive movements ': {
        'en': 'Painful postures/repetitive movements',
        'fr': 'Postures douloureuses/mouvements répétitifs'
    },
    'Office work ': {
        'en': 'Office work',
        'fr': 'Travail de bureau'
    },
    'Other ': {
        'en': 'Other',
        'fr': 'Autre'
    },
    'Employee': {
        'en': 'Employee',
        'fr': 'Employé.e'
    },
    'Employee ': {
        'en': 'Employee',
        'fr': 'Employé.e'
    },
    'Self-employed': {
        'fr': 'Indépendant.e',
        'en': 'Self-employed'
    },
    'Self-employed ': {
        'fr': 'Indépendant.e',
        'en': 'Self-employed'
    },
    'Unemployed': {
        'fr': 'Sans emploi',
        'en': 'Unemployed'
    },
    'Unemployed ': {
        'fr': 'Sans emploi',
        'en': 'Unemployed'
    },
    'Retired': {
        'fr': 'Retraité.e',
        'en': 'Retired'
    },
    'Retired ': {
        'fr': 'Retraité.e',
        'en': 'Retired'
    },
    'ND': {
        'fr': 'Non précisé',
        'en': 'Not documented'
    },
    "Details": {
        'en': "Details",
        'fr': "Détails"
    }
}