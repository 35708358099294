//TODO : add tests file to tests all of these functions

const default_trans_loc_v1 = '0_0_0_0'
const default_trans_loc_v2 = '0_0_0_0_x'

const default_transligamentous = 'x'
const default_sag_loc = '0_0_0'

const default_sequestrated = 'x'
const default_conflict_na = 'x'
const default_conflict_yes = '0_0_0_0'

const default_all = [default_transligamentous, default_sag_loc, default_sequestrated, default_conflict_na]


export const handleConflictRadio = (curr_val, value) => {
    const val = curr_val.split(',')
    const all_boxes = val[5].split('_')
    if (all_boxes.length === 1) {
        if (value === 0) val[5] = default_conflict_na // NA
        else if (value === 1) val[5] = '0' // no
        else if (value === 2) val[5] = default_conflict_yes // yes
    } else {
        if (value === 0) val[5] = default_conflict_na // NA
        else if (value === 1) val[5] = '0' // no
    }
    return val.toString()
}

export const handleConflictBoxes = (curr_val, box) => {
    const val = curr_val.split(',')
    const all_boxes = val[5].split('_')
    if (all_boxes[box] === '0') all_boxes[box] = 1
    else all_boxes[box] = 0
    val[5] = all_boxes.join('_')
    return val.toString()
}


export const handleTransversalLocalizationV1 = (curr_val, box) => {
    const val = curr_val.split(',')
    const all_boxes = val[1].split('_')
    if (all_boxes.length === 5) val[1] = default_trans_loc_v1 //si avant on etait en version 2 (protusion/extrusion)
    else {
        if (all_boxes[box] === '0') all_boxes[box] = 1
        else all_boxes[box] = 0
        val[1] = all_boxes.join('_')
    }
    return val.toString()
}

export const handleTransLocal2 = (curr_val, box, value) => {
    const val = curr_val.split(',')
    const all_boxes = val[1].split('_')
    if (all_boxes.length === 4) val[1] = default_trans_loc_v2 //si avant on etait en version 1 (asymmetric bulging)
    else {
        if (box === 4) all_boxes[4] = value // si radio button : left, central right
        else { // si checkboxes : central canal, subarticular, foraminal, extraforaminal
            if (all_boxes[box] === '0') all_boxes[box] = value
            else all_boxes[box] = 0
        }
        val[1] = all_boxes.join('_')
    }
    return val.toString()
}

export const handleSagittalLocalization = (curr_val, box) => {
    const val = curr_val.split(',')
    const all_boxes = val[3].split('_')
    if (all_boxes[box] === '0') all_boxes[box] = 1
    else all_boxes[box] = 0
    val[3] = all_boxes.join('_')
    return val.toString()
}

export const handleSubValuesChange = (curr_val, position, value) => {
    const val = curr_val.split(',')
    if (val[position+1].endsWith('?')) val[position+1] = value+'?'
    else val[position+1] = value
    return val.toString()
}

export const handleValuesChange = (curr_val, new_val) => {
    const not_sure = curr_val.endsWith("?") ? '?' : ''
    if (new_val === 0) return "0"+not_sure
    else {
        if (curr_val === "WARN" || curr_val === "100" || curr_val[0] === "0") {
            let sub_vals = [new_val.toString()]
            if (new_val === 2) sub_vals.push(default_trans_loc_v1)
            else sub_vals.push(default_trans_loc_v2)
            sub_vals = sub_vals.concat(default_all)
            return sub_vals.join(',')+not_sure
        }
        else {
            let sub_vals = curr_val.split(',')
            sub_vals[0] = new_val.toString()
            //si on etait en asymmetric bulging et qu on passe en protrusion/extrusion
            if ((sub_vals[1].split('_').length === 4 && (new_val === 3 || new_val === 4))) sub_vals[1] = "0_0_0_0_x"
            //si on etait en protrusion/extrusion et quon passe en asymmetric bulging
            else if (sub_vals[1].split('_').length === 5 && new_val === 2) sub_vals[1] = "0_0_0_0"
            return sub_vals.join(',')+not_sure
        }
    }
}