import http from "../http-common";

export const getImageInfo = async (patient_id) => {
    return await http.get(`/core/images/${patient_id}/`).then(response => response.data)
}

export const getImageBlob = async (patient_id, serie_id, slice_id, signal) => {
    return await fetch(`${process.env.REACT_APP_IP}/core/images/${patient_id}/${serie_id}/${slice_id}/`, {
        method: 'GET',
        headers: {Authorization: `JWT ${sessionStorage.getItem('token')}`},
        signal
    })
        //.then(response => response.blob())
        //.then(myBlob => URL.createObjectURL(myBlob))
}
