import {Checkbox, FormControlLabel, Grid, Radio} from "@mui/material";
import React from "react";
import {valuesNameTrad} from "../../../../../../../utils/traductions";
import {useTranslation} from "react-i18next";


const SubDetailsFacet = ({sub_val, default_color, handleChange}) => {
    const size_color = sub_val.split('-').slice(0,3).includes('1') ? default_color : "crimson"
    const endocanalar_color = sub_val.split('-')[3][0] === "x" ? "crimson" : default_color

    const { i18n } = useTranslation()
    const details = valuesNameTrad['facet-arth']['sub_details'][i18n.language]

    return <>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <p style={{color: default_color, margin: "auto 5px 0 0", fontSize: 'var(--small-font-size)'}}>
                {details[0]['title']}
            </p>
            {details[0]['choices'].map((item, keyItem) => {
                const checked = sub_val.split('-')[keyItem][0] === "1"
                const new_val = checked ? "0" : "1"
                return <FormControlLabel
                    key={"item-"+keyItem}
                    style={{color: size_color, margin: 0}}
                    control={
                        <Checkbox
                            style={{color: size_color, margin: 0, padding: 0}}
                            size="small"
                            checked={checked}
                            onChange={() => handleChange(keyItem, new_val)}
                        />
                    }
                    label={<span style={{fontSize: 'var(--small-font-size)'}}>{item}</span>}
                />
            })}
        </div>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <p style={{color: default_color, margin: "auto 5px 0 0", fontSize: 'var(--small-font-size)'}}>
                {details[1]['title']}
            </p>
            {details[1]['choices'].map((val, keyVal) => {
                const checked = sub_val.split('-')[3][0] === val['value']
                return <FormControlLabel
                    key={keyVal}
                    style={{color: endocanalar_color, margin: 0}}
                    control={
                        <Radio
                            style={{color: endocanalar_color, margin: 0, padding: "1px"}}
                            size="small"
                            checked={checked}
                            onChange={() => handleChange(3, val['value'])}
                        />
                    }
                    label={<span style={{fontSize: 'var(--small-font-size)'}}>{val['label']}</span>}
                />
            })}
        </div>
    </>
}

export const SubValuesFacet = ({xs, sub_val, sub_color, bg_color, handleSubValuesChange, handleSubSubValuesChange}) => {
    const { i18n } = useTranslation()
    return <Grid item xs={xs} className="checkboxes" style={{backgroundColor: bg_color, borderRadius: "10px", display: "flex", flexDirection: "column"}}>
        {valuesNameTrad['facet-arth']['sub_values'][i18n.language].map((value, keyValue) => {
            const sub = sub_val[keyValue]
            const is_sub_details = sub.split('-').length === 4
            return <div key={"value-"+keyValue}>
                <FormControlLabel
                    style={{color: sub_color, margin: 0}}
                    control={
                        <Checkbox
                            style={{color: sub_color, margin: 0, padding: "1px"}}
                            size="small"
                            checked={sub === "1" || is_sub_details}
                            onChange={() => handleSubValuesChange(keyValue)}
                        />
                    }
                    label={<span style={{fontSize: 'var(--medium-font-size)'}}>{value}</span>}
                />
                {is_sub_details && <SubDetailsFacet
                    sub_val={sub}
                    default_color={bg_color === 'var(--light-blue)' ? 'white' : 'var(--dark-gold)'}
                    handleChange={(keyPosition, new_val) => handleSubSubValuesChange(keyValue, keyPosition, new_val)}
                />}
            </div>
        })}
    </Grid>
}