import React from 'react'
import {Grid} from "@mui/material";
import {SubValuesFacet} from "./utils";
import {MainValues} from "../utils";
import {valuesNameTrad} from "../../../../../../../utils/traductions";
import {useTranslation} from "react-i18next";


export const FacetModule = ({id, curr_val, theme, side, display_sub, handleValuesChange, handleSubValuesChange, handleSubSubValuesChange}) => {
    const color = id === 0 ? "var(--dark-blue)" : "var(--gold)"
    const is_sub_vals = display_sub && curr_val[0] === '3'
    const sub_val = curr_val.slice(2).split(',')
    const sub_color = sub_val.slice(0, 3).includes('1') || sub_val.slice(3).some(elt => elt.length === 7)

    const { i18n } = useTranslation()

    return <Grid container>
        <Grid container justify="center" alignItems="center">
            <Grid item xs={3} style={{display: 'flex', flexDirection: 'column'}}>
                <p style={{color: color, cursor: 'default'}}>{side}</p>
            </Grid>
            <Grid item xs={9}>
                <MainValues
                    color={color}
                    theme={theme}
                    curr_val={curr_val}
                    values={valuesNameTrad['facet-arth']['values'][i18n.language]}
                    size={[3, 1, 4, 4]}
                    label={false}
                    handleValuesChange={handleValuesChange}
                />
            </Grid>
        </Grid>
        {is_sub_vals && <Grid container justify="center" alignItems="center">
            <SubValuesFacet
                xs={12}
                sub_val={sub_val}
                bg_color={id === 0 ? 'var(--light-blue)' : 'var(--light-gold)'}
                sub_color={sub_color ? 'var(--dark-blue)' : 'crimson'}
                handleSubValuesChange={handleSubValuesChange}
                handleSubSubValuesChange={handleSubSubValuesChange}
            />
            <Grid item xs={3}/>
        </Grid>}
    </Grid>
}