import React from 'react'
import './AnnotationTool.css'
import {Backdrop, Grid} from "@mui/material"
import {ClinicalData} from "./ClinicalData/ClinicalDataBis";
import {Diseases} from "./Diseases";

export const AnnotationTool = ({patient_id, patient_id_db, patient_label, values, colors, handleNewPatient,
                               handleCheckValue, handleClearDisease, handleResetDisc, handleResetDisease,
                               handleResetSagAx}) => {
    const [clinical, setClinical] = React.useState(false)

    const handleClinicalClick = () => setClinical(prevState => !prevState)

    React.useEffect(() => {
        setClinical(false)
    }, [patient_id])

    return <div className='annotation-tool'>
        <Grid container>
            <Grid item xs={2}/>
            <Grid item xs={8}>
                <div className='patient'>
                    <h1>
                        <i id='patient_previous' className="fas fa-arrow-circle-left" onClick={() => handleNewPatient(patient_id-1)}/>
                        Patient {patient_label}
                        <i id='patient_next' className="fas fa-arrow-circle-right" onClick={() => handleNewPatient(patient_id+1)}/>
                    </h1>
                </div>
            </Grid>
            <Grid item xs={2}>
                <button className='clinical-data-button' onClick={handleClinicalClick}>Clinical</button>
            </Grid>
        </Grid>
        {clinical && <Backdrop
            style={{top: 'var(--navbar)', height: 'var(--content-height)'}}
            sx={{ color: '#000', zIndex: 100}}
            open={clinical}
            onClick={() => setClinical(false)}
        >
            {clinical >= 0 && <ClinicalData id={patient_id_db}/>}
        </Backdrop>}
        {values.length > 0 && <Diseases
            patient_id={patient_id}
            values={values}
            color={colors}
            handleCheck={handleCheckValue}
            handleResetDisc={handleResetDisc}
            handleResetDisease={handleResetDisease}
            handleClearDisease={handleClearDisease}
            handleResetSagAx={handleResetSagAx}
        />}
    </div>
}