import React from 'react'
import '../Disc.css'
import {createTheme, FormControlLabel, Radio, ThemeProvider, Grid} from "@mui/material";
import {discs} from "../items";
import {NotSureResetBtn} from "../utils";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

const values = ['0', '1', '2', '3', '4']


const FattyInvolutionModule = ({side, theme, color, main_value, side_value, onChange}) => {
    return <Grid container justify="center" alignItems="center">
        <Grid item xs={3} style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{color: color, cursor: 'default'}}>{side}</p>
        </Grid>
        <Grid item xs={9}>
            <Grid container>
                {values.map((value, keyValue) => {
                    return <Grid key={keyValue} item xs={true} style={{display: 'flex', justifyContent: 'center'}}>
                        <ThemeProvider theme={theme}>
                            <FormControlLabel
                                style={{color: color, margin: 0}}
                                control={
                                    <Radio
                                        style={{color: color, margin: 0, padding: 0}}
                                        size="small"
                                        checked={main_value !== "100" && side_value === keyValue.toString()}
                                        onChange={() => onChange(keyValue)}
                                    />
                                }
                                label={keyValue}
                            />
                        </ThemeProvider>
                    </Grid>
                })}
            </Grid>
        </Grid>
    </Grid>
}

export default class FattyInvolution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: 13
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (this.state.fontSize === 13 && window.innerWidth <= 1123) this.setState({fontSize: 11})
        else if (this.state.fontSize === 11 && window.innerWidth > 1123) this.setState({fontSize: 13})
    }

    handleValuesChange = (disc, position, value) => {
        const values = this.props.values.slice()
        let val = values[disc]
        let not_sure = ''
        if (val.endsWith('?')) not_sure = '?'
        if (position === 0) {
            if (val === "100" || val === 'WARN') this.props.handleCheck(disc, value+",x"+not_sure)
            else this.props.handleCheck(disc, value+val.slice(1,3)+not_sure)
        } else if (position === 1) {
            if (val === "100" || val === 'WARN') this.props.handleCheck(disc, "x,"+value+not_sure)
            else this.props.handleCheck(disc, val.slice(0,2)+value+not_sure)
        }
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = disc => {
        const values = this.props.values.slice()
        let val = values[disc]
        if (val.endsWith("?")) val = val.slice(0,-1)
        else val = values[disc].concat('', '?')
        this.props.handleCheck(disc, val)
    }

    render() {
        const theme = createTheme({typography: {fontSize: this.state.fontSize}})
        return discs.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) return <Grid key={keyDisc} container justify="center" alignItems="center" style={{padding: '5px 0', backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                <Grid item xs={true}>
                    {valuesNameTrad['muscles']['left-right'][this.props.language].map((elt, keyElt) => {
                        return <FattyInvolutionModule
                            key={keyElt}
                            side={elt['label']}
                            color={elt['color']}
                            theme={theme}
                            main_value={curr_val}
                            side_value={curr_val[elt['id']]}
                            onChange={keyValue => this.handleValuesChange(keyDisc, keyElt, keyValue.toString())}
                        />
                    })}
                </Grid>
                <NotSureResetBtn
                    theme={theme}
                    value={curr_val}
                    visible={curr_val !== 'WARN'}
                    handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                    handleNotSure={() => this.handleNotSure(keyDisc)}
                    handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                />
            </Grid>
            else return null
        })
    }
}