import {Grid} from "@mui/material";
import React from "react";
import {MainValues} from "../utils";
import {useTranslation} from "react-i18next";
import {valuesNameTrad} from "../../../../../../../utils/traductions";

export const LateralRecessModule = ({side, theme, color, main_value, side_value, onChange}) => {
    const { i18n } = useTranslation()

    return <Grid container justify="center" alignItems="center">
        <Grid item xs={3} style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{color: color, cursor: 'default'}}>{side}</p>
        </Grid>
        <Grid item xs={9}>
            <MainValues
                values={valuesNameTrad['lrs']['values'][i18n.language]}
                theme={theme}
                size={[3,4,5]}
                curr_val={main_value === '100' || main_value === 'WARN' ? '100': side_value}
                handleValuesChange={onChange}
                color={color}
                label={false}
            />
        </Grid>
    </Grid>
}