import {updateAnnotation} from "../../../../../services/AnnotationService";
import errorMgt from "../../../../../sessionExpired";

export const updateDataBase = (patient_id, new_values, disease_id, handleResponse) => {
    const data = {
        "disc": {
            "t8_t9": new_values[0],
            "t9_t10": new_values[1],
            "t10_t11": new_values[2],
            "t11_t12": new_values[3],
            "t12_l1": new_values[4],
            "l1_l2": new_values[5],
            "l2_l3": new_values[6],
            "l3_l4": new_values[7],
            "l4_l5": new_values[8],
            "l5_s1": new_values[9],
        }
    }
    updateAnnotation(patient_id, disease_id + 1, data)
        .then(() => handleResponse())
        .catch(error => {
            if (error.response) {
                if (error.response.status === 401) errorMgt('Your session expired.')
                else errorMgt('An error occured.')
            }
        })
}