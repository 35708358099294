import React from 'react'
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {medTrad} from "./constants";

const needTrad = {
    'label': false,
    'sex': true,
    'age': false,
    'height': false,
    'weight': false,
    'smoke': true,
    'diabete': true,
    'lumbar_pain': false,
    'rad_pain': false,
    'radiculalgia': true,
    'odi': false,
    'work': true,
    'employement': true,
}

/**
 * Displays the grid for each category of data except radiology/surgery ones
 * @param props - dictionary of parameters needed :
 *          - subtitle: red paragraph (only used for treatment) -- Previous treatment
 *          - titles: column on the left -- Height
 *          - names: name of the category stored in state -- height
 *          - state: data stored in state, to be retrieved for each name from names -- 163
 *          - unities: not stored but needed to be displayed -- cm
 * @return {JSX.Element}
 */
export const GridSample = ({titles, subtitle, names, state, unities}) => {
    const { i18n } = useTranslation()
    return <>
        <Grid container>
            <Grid item xs={6}>
                {subtitle && <h5 className='overview-titles'>{subtitle}</h5>}
            </Grid>
            <Grid item xs={6}/>
        </Grid>
        <Grid container>
            <Grid item xs={6}>
                {titles.map((elt, key) => <p key={key} className='overview-subtitles'>{elt}</p>)}
            </Grid>
            <Grid item xs={6}>
                {names
                    ? names.map((elt, key) => {
                        let value = ''
                        if (state[elt] !== -1 && state[elt] !== '' && state[elt] !== 'NA') value = state[elt]+unities[key]
                        else value = 'NA'

                        if (elt === 'diabete') {
                            if (state[elt] === 0) value = 'No'
                            else if (state[elt] === 1) value = 'Type 1'
                            else if (state[elt] === 2) value = 'Type 2'
                            else value = 'NA'
                        }

                        if ((needTrad[elt] && medTrad[value] !== undefined) || value === 'NA') value = medTrad[value][i18n.language]
                        return <p key={key} className='overview-values'>{value}</p>
                    })
                    : state.map((elt, key) => {
                        return <p key={key} className='overview-values'>{elt}</p>
                    })
                }
            </Grid>
        </Grid>
    </>
}