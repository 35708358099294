import React from 'react'
import './Disc.css'
import {createTheme, FormControlLabel, Grid, Radio, ThemeProvider} from "@mui/material";
import {vertebrae} from "./items";
import {MainValues, NotSureResetBtn} from "./utils";
import {valuesNameTrad} from "../../../../../../utils/traductions";

export const SubValuesLine = ({xs, sub_values, theme, title, curr_val, sub_color, handleChange}) => {
    return <Grid item xs={xs} style={{display: "flex", justifyContent: "center"}}>
        <Grid container justify="center" alignItems="center">
            <Grid item xs={6} style={{display: "flex", justifyContent: "center"}}>
                <b style={{color: 'var(--gold)'}}>{title}</b>
            </Grid>
            {sub_values.map(sub_val => {
                return <Grid key={sub_val['key']} item xs={3} style={{display: "flex", justifyContent: "center"}}>
                    <ThemeProvider theme={theme}>
                        <FormControlLabel
                            style={{color: sub_color, margin: 0}}
                            control={
                                <Radio
                                    style={{color: sub_color, margin: 0, padding: 0}}
                                    size="small"
                                    checked={curr_val.slice(0,2) === sub_val['value']}
                                    onChange={() => handleChange(sub_val['value'])}
                                />
                            }
                            label={sub_val['label']}
                        />
                    </ThemeProvider>
                </Grid>
            })}
        </Grid>
    </Grid>
}

export default class CompressionFracture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: 12
        }
    }

    handleValuesChange = (curr_val, disc, value) => {
        const not_sure = curr_val.endsWith('?') ? '?' : ''
        this.props.handleCheck(disc, value + not_sure)
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') this.props.handleCheck(disc, '100')
        else this.props.handleCheck(disc, 'WARN')
    }

    handleNotSure = (curr_val, disc) => {
        this.props.handleCheck(disc, curr_val.endsWith('?') ? curr_val.slice(0,-1) : curr_val + '?')
    }

    render() {
        const theme = createTheme({typography: {fontSize: this.state.fontSize}})
        return vertebrae.map((disc, keyDisc) => {
            const curr_val = this.props.values[keyDisc]
            if (curr_val) {
                const yes = curr_val !== "100" && curr_val[0] === "1"
                const sub_val_color = (curr_val === "1" || curr_val === "1?") ? 'crimson' : 'var(--gold)'
                return <Grid key={keyDisc} container justify="center" alignItems="center" style={{backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                    <Grid item xs={1} style={{margin: "auto 0"}}><p className="disc">{disc}</p></Grid>
                    {!yes && <Grid item xs={2}/>}
                    <Grid item xs={3} className="checkboxes">
                        <MainValues
                            theme={theme}
                            curr_val={curr_val}
                            values={valuesNameTrad['fracture']['values'][this.props.language]}
                            size={6}
                            handleValuesChange={keyValue => this.handleValuesChange(curr_val, keyDisc, keyValue.toString())}
                        />
                    </Grid>
                    {yes
                        ? <SubValuesLine
                            xs={5}
                            theme={theme}
                            title={valuesNameTrad['fracture']['sub_values_title'][this.props.language]}
                            sub_values={valuesNameTrad['fracture']['sub_values'][this.props.language]}
                            curr_val={curr_val}
                            sub_color={sub_val_color}
                            handleChange={new_val => this.handleValuesChange(curr_val, keyDisc, new_val)}
                        />
                        : <Grid item xs={3}/>
                    }
                    <NotSureResetBtn
                        theme={theme}
                        value={curr_val}
                        visible={curr_val !== 'WARN'}
                        handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                        handleNotSure={() => this.handleNotSure(curr_val, keyDisc)}
                        handleReset={() => this.props.handleResetDisc(keyDisc, disc)}
                    />
                </Grid>
            }
            else return null
        })
    }
}