import React from 'react'
import {Route, Switch, useHistory, useLocation} from "react-router-dom"
import Annotate from "./Annotations/Annotate/Annotate"

export const Annotations = (props) => (
    <Switch>
        <Route exact path={`/Annotations`}>
            <Annotate
                surgeon_id = {props.surgeon_id}
                is_surgeon = {props.is_surgeon}
                patient_id={useLocation().state}
                history={useHistory()}
                location={useLocation()}
            />
        </Route>
    </Switch>
)