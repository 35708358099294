import React from 'react'
import './DiscHerniation.css'
import '../Disc.css'
import {Backdrop, Grid} from "@mui/material";
import {discs} from "../items";
import {DiscHerniationModule} from "./DiscHerniationModule";
import {HerniationHelps} from "./items";

class DiscHerniation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backdrop: -1,
            cursor: null,
            lines: null
        }
    }

    updateSingleDiscLine = (disc) => {
        const new_lines = this.state.lines.slice()
        new_lines[disc] = 1
        this.setState({lines: new_lines})
    }

    updateLines() {
        const new_lines = Array(10).fill(1)
        discs.forEach((disc, keyDisc) => {
            if (this.props.values[keyDisc] && this.props.values[keyDisc].split(';').length > 1) new_lines[keyDisc] = 2
        })
        this.setState({lines: new_lines})
    }

    componentDidMount() {
        this.updateLines()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.values !== this.props.values && !this.state.lines) this.updateLines()
    }


    handleDropdown = disc => {
        if (this.state.cursor === disc) this.setState({cursor: null})
        else this.setState({cursor: disc})
    }

    handleAddHerniation = (disc) => {
        const new_lines = this.state.lines.slice()
        if (new_lines[disc] === 1) {
            new_lines[disc] = 2
            this.props.handleCheck(disc, this.props.values[disc]+';100')
        }
        else {
            new_lines[disc] = 1
            const new_values = this.props.values[disc].split(';')
            this.props.handleCheck(disc, new_values[0])
        }
        this.setState({lines: new_lines})
    }

    handleSubmitValues = (disc, line, value) => {
        console.log('HEY')
        this.setState({cursor: disc})
        if (this.state.lines[disc] === 1) this.props.handleCheck(disc, value)
        else {
            const old_values = this.props.values[disc]
            if (old_values === 'WARN') {
                this.updateSingleDiscLine(disc)
                this.props.handleCheck(disc, value)
            }
            else {
                const values = old_values.split(';')
                values[line] = value
                this.props.handleCheck(disc, values[0]+";"+values[1])
            }
        }
    }

    handleResetDisc = (keyDisc, disc) => {
        this.props.handleResetDisc(keyDisc, disc)
        if (this.state.lines[keyDisc] > 1) this.updateLines()
    }

    handleWarning = (curr_val, disc) => {
        if (curr_val === 'WARN') {
            this.props.handleCheck(disc, '100')
            this.updateSingleDiscLine(disc)
        }
        else this.props.handleCheck(disc, 'WARN')
    }

    render() {
        return <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.backdrop !== -1}
                onClick={() => this.setState({backdrop: -1})}
            >
                {this.state.backdrop >= 0 && <HerniationHelps id={this.state.backdrop} language={this.props.language} />}
            </Backdrop>
            {discs.map((disc, keyDisc) => {
                const curr_val = this.props.values[keyDisc]
                if (curr_val && this.state.lines && this.state.lines[keyDisc]) {
                    const sub_vals = curr_val.replace('?', '').split(';')
                    const dropdown = sub_vals.some(element => element !== '100' && element !== 'WARN' && element !== '0' && element !== '1')
                    return <Grid key={keyDisc} container style={{marginBottom: '10px', backgroundColor: curr_val === 'WARN' ? 'crimson': 'transparent'}}>
                        <DiscHerniationModule
                            id={0}
                            disc={disc}
                            disc_id={keyDisc}
                            dropdown={dropdown}
                            cursor={this.state.cursor === keyDisc}
                            lines={this.state.lines[keyDisc]}
                            curr_val={curr_val.split(';')[0]}
                            handleWarning={() => this.handleWarning(curr_val, keyDisc)}
                            handleDropdown={() => this.handleDropdown(keyDisc)}
                            handleAddHerniation={() => this.handleAddHerniation(keyDisc)}
                            handleCheck={value => this.handleSubmitValues(keyDisc, 0, value)}
                            handleBackdrop={value => this.setState({backdrop: value})}
                            handleResetDisc={() => this.handleResetDisc(keyDisc, disc)}
                        />
                        {sub_vals.length >= 2 && this.state.lines[keyDisc] === 2 && <DiscHerniationModule
                            id={1}
                            disc={disc}
                            disc_id={keyDisc}
                            dropdown={dropdown}
                            cursor={this.state.cursor === keyDisc}
                            lines={this.state.lines[keyDisc]}
                            curr_val={curr_val.split(';')[1]}
                            handleDropdown={() => this.handleDropdown(keyDisc)}
                            handleAddHerniation={() => this.handleAddHerniation(keyDisc)}
                            handleCheck={value => this.handleSubmitValues(keyDisc, 1, value)}
                            handleBackdrop={value => this.setState({backdrop: value})}
                            handleResetDisc={() => this.handleResetDisc(keyDisc, disc)}
                        />}
                    </Grid>
                } else return null
            })}
        </>
    }
}

export default DiscHerniation