import React from 'react'
import './items'
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import LinkIcon from '@mui/icons-material/Link';
import {Grid} from "@mui/material";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import {colors, images, mod, SlicesSlider, text_colors} from "./items";
import {NoImageBox, zoomTools} from "./ImageTools";

export default class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            max_slices: props.series && props.series[props.modal] && props.series[props.modal][1]-1,
            ready_to_display: false,
            display: false,
        }
    }

    componentDidMount() {
        const image = document.getElementById(this.props.classname)
        image.addEventListener("wheel", this.handleScroll)
        window.addEventListener('resize', this.setMatching)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.matching !== this.props.matching && this.props.matching !== null && this.props.matching !== undefined) {
            this.setState({ready_to_display: true})
            this.setMatching()
        }
        if (prevProps.matching !== this.props.matching && this.props.matching === null) this.setState({ready_to_display: false, display: false})
    }

    componentWillUnmount() {
        const image = document.getElementById(this.props.classname)
        image.removeEventListener("wheel", this.handleScroll)
        window.removeEventListener('resize', this.setMatching)
    }

    setMatching = () => {
        this.props.matching_side === 'sag' ? this.setAxialBarreSize() : this.setSagittalBarreSize()
    }

    setSagittalBarreSize = () => {
        if (this.props.matching) {
            const slope = this.props.matching[1] //angle in rad
            const img = document.getElementById("image_"+this.props.id)
            if (img !== null) {
                const rotation = Math.PI/2+slope
                const left_margin = this.props.matching[0]*(img.offsetWidth/this.props.matching[2]) - img.offsetWidth/2
                const top_margin = img.offsetHeight/2
                document.documentElement.style.setProperty('--test-line-x', - img.offsetWidth/2)
                document.documentElement.style.setProperty('--line-rotation-'+this.props.id, rotation)
                document.documentElement.style.setProperty('--line-x-translation-'+this.props.id, left_margin)
                document.documentElement.style.setProperty('--line-z-translation-'+this.props.id, top_margin)
            }
        }
    }

    setAxialBarreSize = () => {
        if (this.props.matching) {
            const slope = this.props.matching[1]
            const img = document.getElementById("image_"+this.props.id)
            if (img !== null) {
                const img_size = Math.min(img.offsetWidth, img.offsetHeight)
                const translation = Math.abs((img_size/2)*Math.tan(slope))*(slope<0 ? -1 : 1)
                const top_margin = this.props.matching[0]*(img_size/this.props.matching[2]) + translation - 2
                document.documentElement.style.setProperty('--line-rotation-'+this.props.id, slope)
                document.documentElement.style.setProperty('--line-z-translation-'+this.props.id, top_margin)
            }
        }
    }

    handleNewMod = (direction) => {
        let previous_mod = this.props.modal
        let new_mod = 0
        if (direction === 'back' && previous_mod >= 1) {
            new_mod = previous_mod-1
        } else if (direction === 'next') {
            if (previous_mod < this.props.series.length-1) new_mod = previous_mod+1
            else new_mod = this.props.series.length-1
        }
        const new_max = this.props.series[new_mod][1]-1
        if (this.props.slice > new_max) this.handleNewSlice(new_max)
        this.setState({max_slices: new_max})
        this.props.handleNewMod(new_mod)
    }
    handleScroll = e => {
        if (e.deltaY<0) this.handleNewSlice(this.props.slice+1)
        else this.handleNewSlice(this.props.slice-1)
    }
    handleNewSlice = new_slice => {
        if (new_slice <= this.props.series[this.props.modal][1]-1 && new_slice>=0) this.props.handleNewSlice(new_slice)
        else if (new_slice > this.props.series[this.props.modal][1]-1) this.props.handleNewSlice(this.props.series[this.props.modal][1]-1)
    }

    handleDisplayBarre = () => {
        if (this.state.display) this.setState({display: false})
        else this.setState({display: true})
    }

    render() {
        const btn_font_size = window.innerWidth < 400 ? "inherit" : "small"
        const view_id = this.props.view_id
        const mod_id = this.props.series[this.props.modal][0]
        const color = text_colors[mod_id]
        return <div id={this.props.classname} className={this.props.classname} style={{backgroundColor: colors[mod_id]}}>
            {this.props.img_source
                ? <TransformWrapper wheel={{disabled: true}}>
                    {({ zoomIn, zoomOut, resetTransform}) => (
                        <div style={{height: '100%', width: '100%'}}>
                            <Grid container className='tools'>
                                <Grid item xs='auto' style={{height: '100%'}}>
                                    <button onClick={() => zoomIn()}><ZoomInIcon fontSize={btn_font_size}/></button>
                                    <button onClick={() => zoomOut()}><ZoomOutIcon fontSize={btn_font_size}/></button>
                                    <button onClick={() => resetTransform()}><SettingsBackupRestoreIcon fontSize={btn_font_size}/></button>
                                    <button onClick={() => this.handleNewMod('back')}><ArrowBackIosNewIcon fontSize={btn_font_size}/></button>
                                    <button onClick={() => this.handleNewMod('next')}><ArrowForwardIosIcon fontSize={btn_font_size}/></button>
                                    {view_id > 1 && <button disabled={!this.state.ready_to_display} onClick={this.handleDisplayBarre}>
                                        {this.state.display
                                            ? <WebAssetIcon fontSize={btn_font_size} />
                                            : <WebAssetOffIcon fontSize={btn_font_size} />
                                        }
                                    </button>}
                                    {/*this.props.other_slice !== null && this.props.view_id === 2 && <button disabled={this.props.other_slice === this.props.slice} onClick={() => this.handleNewSlice(this.props.other_slice)}>
                                            <EqualizerIcon fontSize={btn_font_size}/>
                                        </button>/*{mod[this.props.series[this.props.modal][0]]}*/}
                                    {view_id > 1 && this.props.link !== null && this.props.link !== undefined && <button style={{backgroundColor: this.props.link ? 'var(--gold)' : "transparent"}} >
                                        {this.props.link
                                            ? <LinkOffIcon fontSize={btn_font_size} onClick={this.props.handleLink}/>
                                            : <LinkIcon fontSize={btn_font_size} onClick={this.props.handleLink}/>
                                        }
                                    </button>}
                                </Grid>
                                <Grid item xs='auto' style={{display: "flex", justifyContent: "center"}}>
                                    <p id="modslice" style={{color: color}}>
                                        {mod[mod_id]} slice {this.props.slice}/{this.state.max_slices}
                                    </p>
                                </Grid>
                                {this.props.progress && this.props.progress !== 100 && <Grid item xs={1} style={{display: "flex", justifyContent: "center"}}>
                                    <p id="progresstext" style={{color: color}}>
                                        {this.props.progress}%
                                    </p>
                                </Grid>}
                                <Grid item xs style={{display: "flex", justifyContent: "center", padding: '0 5px'}}>
                                    <SlicesSlider
                                        style={{color: color}}
                                        value={this.props.slice}
                                        step={1}
                                        min={0}
                                        max={this.state.max_slices}
                                        onChange={(e, value) => this.handleNewSlice(value)}
                                    />
                                </Grid>
                            </Grid>
                            <div className={images[this.props.view_id-1][1]}>
                                <TransformComponent>
                                    <div className={images[this.props.view_id-1][0]}>
                                        <img id={"image_" + this.props.id} src={this.props.img_source ? this.props.img_source :  '/images/BarSup/logo_caerus.png'} alt='An error occured while loading, you can try to refresh the page' />
                                        {this.state.ready_to_display && this.state.display && <div id={"line_" + this.props.id}/>}

                                    </div>
                                </TransformComponent>
                            </div>
                        </div>
                    )}
                </TransformWrapper>
                : <NoImageBox btn_font_size={btn_font_size} handleNewMod={this.handleNewMod} />
            }
        </div>
    }

}