import React from 'react'
import DiscRangeSelection from "./DiscRangeSelection"
import {DiseaseStructure} from "./Diseases/DiseaseStructure"
import {AnteroRetroHelp, EndplateDefectsHelp, ModicHelp, NFStenosisHelp, PfirrmannHelp} from "./Help/Help"
import Pfirrmann from "./Diseases/PfirrMod/Pfirrmann"
import ModicDiscCheckboxes from "./Diseases/PfirrMod/ModicDiscCheckboxes"
import AnteroRetro from "./Diseases/AnteroRetro"
import CompressionFracture from "./Diseases/CompressionFracture"
import NeuralForaminalStenosis from "./Diseases/Stenosis/NeuralForaminalStenosis"
import TransitionalVertebrae from "./Diseases/TransitionalVertebrae"
import EndplateDefects from "./Diseases/EndplateDefects"
import {defaultTrad, diseasesNameTrad, valuesNameTrad} from "../../../../../utils/traductions"
import {useTranslation} from "react-i18next";

export const SagDiseases = ({sag_values, sag_colors, display, handleClick, handleCheck, handleResetSagDiseases,
                                resetDisease, clearDisease, resetDisc}) => {
    const [inf, setInf] = React.useState(4)
    const [sup, setSup] = React.useState(10)
    const [ready, setReady] = React.useState(false)

    const handleChangeSupInf = (inf, sup) => {
        setInf(inf)
        setSup(sup)
    }

    const { i18n } = useTranslation()

    return <div className='sag-diseases'>
        <DiscRangeSelection
            language={i18n.language}
            values={sag_values}
            view={'SAGITTAL'}
            patho_ref={'pfirrmann'}
            ready_for_annotations={ready}
            handleChangeSupInf={handleChangeSupInf}
            set_ready_for_annotation={() => setReady(true)}
            handleCheck={handleCheck}
        />
        {ready && <>
            <button className='reset-patient-button' onClick={handleResetSagDiseases}>
                {defaultTrad['reset-sag-diseases'][i18n.language]}
            </button>
            <div className="disease-list">
                {sag_values['pfirrmann'] && <>
                    <button className='disease-button' style={{backgroundColor: sag_colors['pfirrmann']}} onClick={() => handleClick('pfirrmann')}>
                        {diseasesNameTrad['pfirrmann'][i18n.language]}
                    </button>
                    {display === 'pfirrmann' && <DiseaseStructure
                        resetTitles={['Pfirrmann', '1']}
                        handleResetDisease={() => resetDisease(0, 'Pfirrmann')}
                        handleClearDisease={() => clearDisease(0)}
                        help={<PfirrmannHelp language={i18n.language}/>}
                        content={<Pfirrmann
                            value_discs={[inf, sup]}
                            values={sag_values['pfirrmann']}
                            handleCheck={(disc, value) => handleCheck(0, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(0, disc, label_disc)}
                        />}
                    />}
                </>}
                {sag_values['modic'] && <>
                    <button className='disease-button' style={{backgroundColor: sag_colors['modic']}} onClick={() => handleClick('modic')}>
                        {diseasesNameTrad['modic'][i18n.language]}
                    </button>
                    {display === 'modic' && <DiseaseStructure
                        resetTitles={['Modic', '0']}
                        handleResetDisease={() => resetDisease(1, 'Modic')}
                        handleClearDisease={() => clearDisease(1)}
                        help={<ModicHelp language={i18n.language}/>}
                        content={<ModicDiscCheckboxes
                            value_discs={[inf, sup]}
                            values={sag_values['modic']}
                            handleCheck={(disc, value) => handleCheck(1, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(1, disc, label_disc)}
                        />}
                    />}
                </>}
                {sag_values['anteroretro'] && <>
                    <button className='disease-button' style={{backgroundColor: sag_colors['anteroretro']}} onClick={() => handleClick('anteroretro')}>
                        {diseasesNameTrad['anteroretro'][i18n.language]}
                    </button>
                    {display === 'anteroretro' && <DiseaseStructure
                        resetTitles={['Antero/Retrolisthesis', '0']}
                        columnNames={['Anterolisthesis', 'Retrolisthesis']}
                        sizeColumnNames={[6,6]}
                        handleResetDisease={() => resetDisease(3, 'Anterolisthesis-Retrolisthesis')}
                        handleClearDisease={() => clearDisease(3)}
                        help={<AnteroRetroHelp language={i18n.language}/>}
                        content={<AnteroRetro
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={sag_values['anteroretro']}
                            handleCheck={(disc, value) => handleCheck(3, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(3, disc, label_disc)}
                        />}
                    />}
                </>}
                {sag_values['fracture'] && <>
                    <button className='disease-button' style={{backgroundColor: sag_colors['fracture']}} onClick={() => handleClick('compression_fracture')}>
                        {diseasesNameTrad['fracture'][i18n.language]}
                    </button>
                    {display === 'compression_fracture' && <DiseaseStructure
                        resetTitles={['Compression Fracture', valuesNameTrad['fracture']['reset-values'][i18n.language]]}
                        handleResetDisease={() => resetDisease(8, 'Compression Fracture')}
                        handleClearDisease={() => clearDisease(8)}
                        content={<CompressionFracture
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={sag_values['fracture']}
                            handleCheck={(disc, value) => handleCheck(8, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(8, disc, label_disc)}
                        />}
                    />}
                </>}
                {sag_values['nfs'] && <>
                    <button className='disease-button' style={{backgroundColor: sag_colors['nfs']}} onClick={() => handleClick('neural_canal')}>
                        {diseasesNameTrad['nfs'][i18n.language]}
                    </button>
                    {display === 'neural_canal' && <DiseaseStructure
                        resetTitles={['Neural Foraminal Stenosis', valuesNameTrad['nfs']['reset-values'][i18n.language]]}
                        leftright={true}
                        columnNames={valuesNameTrad['nfs']['values'][i18n.language]}
                        sizeColumnNames={[2, 3, 3, 4]}
                        handleResetDisease={() => resetDisease(6, 'Neural Foraminal Stenosis')}
                        handleClearDisease={() => clearDisease(6)}
                        help={<NFStenosisHelp language={i18n.language}/>}
                        content={<NeuralForaminalStenosis
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={sag_values['nfs']}
                            handleCheck={(disc, value) => handleCheck(6, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(6, disc, label_disc)}
                        />}
                    />}
                </>}
                {sag_values['trans_vb'] && <>
                    <button className='disease-button' style={{backgroundColor: sag_colors['trans_vb']}} onClick={() => handleClick('lumbosacral_transitional_vertebrae')}>
                        {diseasesNameTrad['trans-vb'][i18n.language]}
                    </button>
                    {display === 'lumbosacral_transitional_vertebrae' && <DiseaseStructure
                        resetTitles={['Lumbosacral transitional vertebrae', valuesNameTrad['trans_vb']['reset-values'][i18n.language]]}
                        handleResetDisease={() => resetDisease(10, 'Lumbosacral transitional vertebrae')}
                        handleClearDisease={() => clearDisease(10)}
                        content={<TransitionalVertebrae
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={sag_values['trans_vb']}
                            handleCheck={(disc, value) => handleCheck(10, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(10, disc, label_disc)}
                        />}
                    />}
                </>}
                {sag_values['endplate'] && <>
                    <button className='disease-button' style={{backgroundColor: sag_colors['endplate']}} onClick={() => handleClick('endplate_defects')}>
                        {diseasesNameTrad['endplate'][i18n.language]}
                    </button>
                    {display === 'endplate_defects' && <DiseaseStructure
                        resetTitles={['Endplate defects', valuesNameTrad['endplate']['reset-values'][i18n.language]]}
                        handleResetDisease={() => resetDisease(11, 'Endplate defects')}
                        handleClearDisease={() => clearDisease(11)}
                        help={<EndplateDefectsHelp language={i18n.language}/>}
                        content={<EndplateDefects
                            language={i18n.language}
                            value_discs={[inf, sup]}
                            values={sag_values['endplate']}
                            handleCheck={(disc, value) => handleCheck(11, disc, value)}
                            handleResetDisc={(disc, label_disc) => resetDisc(11, disc, label_disc)}
                        />}
                    />}
                </>}
            </div>
        </>}
    </div>
}